"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStore = getStore;
exports.getStores = getStores;
exports.setStore = setStore;
exports.removeStore = removeStore;

require("core-js/modules/es6.array.find");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var StoreKey = 'vue_admin_template_store';

function getStore() {
  var me = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

  var storeId = _jsCookie.default.get(StoreKey); // verify the uuid from users


  if (me) {
    if (storeId && me.stores && me.stores.length && me.stores.find(function (x) {
      return x.uuid === storeId;
    })) {
      return storeId;
    } else {
      removeStore();
    }
  }

  return storeId;
}

function getStores(me) {
  // verify the uuid from users
  if (me && me.stores && me.stores.length) {
    return me.stores;
  }

  return [];
}

function setStore(uuid) {
  return _jsCookie.default.set(StoreKey, uuid);
}

function removeStore() {
  return _jsCookie.default.remove(StoreKey);
}