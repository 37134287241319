"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getPurchaseOrder = getPurchaseOrder;
exports.createPurchaseOrder = createPurchaseOrder;
exports.editPurchaseOrder = editPurchaseOrder;
exports.deletePurchaseOrder = deletePurchaseOrder;
exports.confirmPurchaseOrder = confirmPurchaseOrder;
exports.downloadPurchaseOrder = downloadPurchaseOrder;
exports.downloadRelatedPR = downloadRelatedPR;
exports.generateGRFromPurchaseOrder = generateGRFromPurchaseOrder;
exports.generateGCFromPurchaseOrder = generateGCFromPurchaseOrder;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders',
    method: 'get',
    params: params
  });
}

function getPurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id,
    method: 'get'
  });
}

function createPurchaseOrder(data) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders',
    data: data,
    method: 'post'
  });
}

function editPurchaseOrder(data) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deletePurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id,
    method: 'delete'
  });
}

function confirmPurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id + '/confirm',
    method: 'post'
  });
}

function downloadPurchaseOrder(id) {
  return (0, _request.default)({
    url: "/api/v1/export-data/purchase-orders/".concat(id),
    method: 'GET',
    responseType: 'blob'
  });
}

function downloadRelatedPR(id) {
  return (0, _request.default)({
    url: "/api/v1/export-data/purchase-orders/".concat(id, "/related-purchase-requests"),
    method: 'GET',
    responseType: 'blob'
  });
}

function generateGRFromPurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id + '/generateGR',
    method: 'post'
  });
}

function generateGCFromPurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id + '/generateGC',
    method: 'post'
  });
}