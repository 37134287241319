var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.addWarehouseArea }
            },
            [_vm._v(_vm._s(_vm.$t("Warehouse.AddWarehouseArea")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "default" }, on: { click: _vm.back } },
            [_vm._v(_vm._s(_vm.$t("Warehouse.Back")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "default" }, on: { click: _vm.downloadQRs } },
            [_vm._v(_vm._s(_vm.$t("Warehouse.DownloadQR")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.Name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "default" },
                        on: {
                          click: function($event) {
                            return _vm.goWarehouseAreaRawMaterial(
                              scope.row.uuid
                            )
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("WarehouseArea.RawMaterial")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.deleteWarehouseArea(scope.row.uuid)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("WarehouseArea.Delete")))]
                    ),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return [
                        "Operation Manager",
                        "Admin",
                        "Warehouse Manager"
                      ].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.editWarehouseArea(scope.row.uuid)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("WarehouseArea.Edit")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "info" },
                        on: {
                          click: function($event) {
                            return _vm.goHistory(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("WarehouseArea.History")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }