"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getShifts = getShifts;
exports.getShift = getShift;
exports.createShift = createShift;
exports.editShift = editShift;
exports.deleteShift = deleteShift;

var _request = _interopRequireDefault(require("@/utils/request"));

function getShifts(params) {
  return (0, _request.default)({
    url: "/api/v1/shifts",
    method: 'get',
    params: params
  });
}

function getShift(id) {
  return (0, _request.default)({
    url: "/api/v1/shifts/".concat(id),
    method: 'get'
  });
}

function createShift(data) {
  return (0, _request.default)({
    url: "/api/v1/shifts",
    data: data,
    method: 'post'
  });
}

function editShift(data) {
  return (0, _request.default)({
    url: "/api/v1/shifts/".concat(data.id),
    data: data,
    method: 'put'
  });
}

function deleteShift(id) {
  return (0, _request.default)({
    url: "/api/v1/shifts/".concat(id),
    method: 'delete'
  });
}