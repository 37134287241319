var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.shift, rules: _vm.rules, "label-width": "auto" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.shift.id,
                  callback: function($$v) {
                    _vm.$set(_vm.shift, "id", $$v)
                  },
                  expression: "shift.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.shift.name,
                  callback: function($$v) {
                    _vm.$set(_vm.shift, "name", $$v)
                  },
                  expression: "shift.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Role", prop: "roles" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.shift.roleId,
                    callback: function($$v) {
                      _vm.$set(_vm.shift, "roleId", $$v)
                    },
                    expression: "shift.roleId"
                  }
                },
                _vm._l(_vm.roles, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "From", prop: "fromTime" } },
            [
              _c("el-time-select", {
                attrs: {
                  placeholder: "Start time",
                  "picker-options": {
                    start: _vm.minTime,
                    step: _vm.step,
                    end: _vm.maxTime
                  }
                },
                model: {
                  value: _vm.shift.fromTime,
                  callback: function($$v) {
                    _vm.$set(_vm.shift, "fromTime", $$v)
                  },
                  expression: "shift.fromTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "To", prop: "toTime" } },
            [
              _c("el-time-select", {
                attrs: {
                  placeholder: "End time",
                  "picker-options": {
                    start: _vm.minTime,
                    step: _vm.step,
                    end: _vm.maxTime,
                    minTime: _vm.shift.fromTime
                  }
                },
                model: {
                  value: _vm.shift.toTime,
                  callback: function($$v) {
                    _vm.$set(_vm.shift, "toTime", $$v)
                  },
                  expression: "shift.toTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: { to: "/shifts" }
                },
                [_vm._v(_vm._s(_vm.$t("Order.Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }