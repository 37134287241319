var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.item, "label-width": "auto" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.item.name,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "name", $$v)
                  },
                  expression: "item.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Code", prop: "code" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.item.code,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "code", $$v)
                  },
                  expression: "item.code"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Product Type", prop: "productType" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.item.posProductTypeId,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "posProductTypeId", $$v)
                    },
                    expression: "item.posProductTypeId"
                  }
                },
                _vm._l(_vm.productTypes, function(pt) {
                  return _c("el-option", {
                    key: pt.uuid,
                    attrs: { label: pt.name, value: pt.uuid }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              [
                _c(
                  "el-row",
                  {
                    staticClass: "row-bg",
                    attrs: { type: "flex", justify: "space-between" }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onSubmit }
                          },
                          [_vm._v(_vm._s(_vm.buttonText))]
                        ),
                        _vm._v(" "),
                        !!_vm.$route.params.id
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.remove()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "default" },
                            on: { click: _vm.back }
                          },
                          [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Back")))]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }