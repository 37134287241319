var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container hidden-xs-only" }),
      _vm._v(" "),
      _vm.user.roles.indexOf("Admin")
        ? _c(
            "div",
            { staticClass: "right-menu" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "margin-right": "10px"
                  }
                },
                [
                  _vm.user.stores.length > 1
                    ? _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click" },
                          on: { command: _vm.setStore }
                        },
                        [
                          _c("div", { staticClass: "avatar-wrapper" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.store ? _vm.store.name : "Choose Store"
                                ) +
                                "\n          "
                            ),
                            _c("i", { staticClass: "el-icon-caret-bottom" })
                          ]),
                          _vm._v(" "),
                          _vm.user.stores && _vm.user.stores.length > 1
                            ? _c(
                                "el-dropdown-menu",
                                {
                                  staticClass: "user-dropdown",
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown"
                                },
                                _vm._l(_vm.user.stores, function(ustore, i) {
                                  return _c(
                                    "el-dropdown-item",
                                    {
                                      key: "Store" + i,
                                      attrs: { command: "" + ustore.uuid }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(ustore.name) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "margin-right": "10px"
                  }
                },
                [
                  _c(
                    "el-dropdown",
                    {
                      attrs: { trigger: "click" },
                      on: { command: _vm.handleLangChange }
                    },
                    [
                      _c("div", { staticClass: "avatar-wrapper" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$i18n.locale.toUpperCase()) +
                            "\n          "
                        ),
                        _c("i", { staticClass: "el-icon-caret-bottom" })
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "user-dropdown",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown"
                        },
                        _vm._l(_vm.langs, function(lang, i) {
                          return _c(
                            "el-dropdown-item",
                            { key: "Lang" + i, attrs: { command: "" + lang } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(lang.toUpperCase()) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container",
                  attrs: { trigger: "click" }
                },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _c("i", { staticClass: "el-icon-user user-avatar-icon" }),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-caret-bottom" })
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "user-dropdown",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _vm.user.roles.find(function(role) {
                        return ["Admin", "Operation Manager"].includes(
                          role.name
                        )
                      })
                        ? _c(
                            "router-link",
                            { attrs: { to: "/users/edit/" + _vm.user.uuid } },
                            [
                              _c("el-dropdown-item", [
                                _vm._v("\n            My Profile\n          ")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/changePassword" } },
                        [
                          _c("el-dropdown-item", [
                            _vm._v("\n            Change Password\n          ")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.logout($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v("Log Out")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }