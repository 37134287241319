var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c("label", [_vm._v(_vm._s(_vm.$t("Order.Date")) + ": ")]),
          _vm._v(" "),
          _c("el-date-picker", {
            attrs: {
              type: _vm.isIncludePartTime ? "daterange" : "month",
              "range-separator": " - ",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "value-format": "yyyy-MM-dd"
            },
            model: {
              value: _vm.dutyDate,
              callback: function($$v) {
                _vm.dutyDate = $$v
              },
              expression: "dutyDate"
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("label", [_vm._v("User: ")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: {
                placeholder: "Users",
                clearable: "",
                filterable: "",
                "filter-method": _vm.userFilter
              },
              model: {
                value: _vm.listQuery.userId,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "userId", $$v)
                },
                expression: "listQuery.userId"
              }
            },
            _vm._l(_vm.userOptions, function(user) {
              return _c("el-option", {
                key: user.name,
                attrs: { label: user.name, value: user.uuid }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("label", [
            _vm._v(_vm._s(_vm.$t("PurchaseRequests.Store")) + ": ")
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("PurchaseRequests.Stores"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.storeId,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "storeId", $$v)
                },
                expression: "listQuery.storeId"
              }
            },
            _vm._l(_vm.stores, function(store) {
              return _c("el-option", {
                key: store.name,
                attrs: { label: store.name, value: store.uuid }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("label", [_vm._v("Role: ")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "Roles", clearable: "" },
              model: {
                value: _vm.listQuery.roleId,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "roleId", $$v)
                },
                expression: "listQuery.roleId"
              }
            },
            _vm._l(_vm.roles, function(role) {
              return _c("el-option", {
                key: role.name,
                attrs: { label: role.name, value: role.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.query()
                    }
                  }
                },
                [_vm._v("Filter")]
              ),
              _vm._v(" "),
              _c(
                "el-button-group",
                [
                  _vm.user.roles.find(function(role) {
                    return ["Operation Manager", "Admin"].includes(role.name)
                  }) && _vm.result.isIncludeFullTime
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.downloadFullTime()
                            }
                          }
                        },
                        [_vm._v("FullTime Salary Report")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.downloadExcel()
                        }
                      }
                    },
                    [_vm._v("Export")]
                  ),
                  _vm._v(" "),
                  _vm.result.isIncludePartTime
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.downloadBreakdown()
                            }
                          }
                        },
                        [_vm._v("Breakdown")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.downloadSummary()
                        }
                      }
                    },
                    [_vm._v("Summary")]
                  ),
                  _vm._v(" "),
                  _vm.result.isIncludePartTime
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.downloadPersonalSummary()
                            }
                          }
                        },
                        [_vm._v("Personal")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("div", { attrs: { slot: "empty" }, slot: "empty" }, [
            _vm._v(_vm._s(_vm.$t("order.emptyText")) + " "),
            _c("br"),
            _vm._v(
              "This report is based on duty, make sure you have published duty"
            )
          ]),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "User" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.user ? scope.row.user.name : "") +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.Store") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.store ? scope.row.store.name : "") +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "From Time" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.fromTime ? scope.row.fromTime : "") +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "To Time" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.toTime ? scope.row.toTime : "") +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Clock In" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.clockInTime ? scope.row.clockInTime : ""
                        ) +
                        "\n        "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.clockInTime !=
                            scope.row.effectiveClockInTime
                            ? "(Effective: " +
                                scope.row.effectiveClockInTime +
                                ")"
                            : ""
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Clock Out" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.clockOutTime ? scope.row.clockOutTime : ""
                        ) +
                        "\n        "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.clockOutTime !=
                            scope.row.effectiveClockOutTime
                            ? "(Effective: " +
                                scope.row.effectiveClockOutTime +
                                ")"
                            : ""
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Hour" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.effectiveDuration
                            ? scope.row.effectiveDuration.toFixed(1)
                            : ""
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Wage" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.effectiveCost
                            ? "$" + scope.row.effectiveCost.toFixed(2)
                            : "-"
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }