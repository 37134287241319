"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAttendances = getAttendances;
exports.deleteAttendance = deleteAttendance;
exports.createAttendance = createAttendance;
exports.approveAdjustment = approveAdjustment;
exports.rejectAdjustment = rejectAdjustment;
exports.requestAdjustment = requestAdjustment;

var _request = _interopRequireDefault(require("@/utils/request"));

function getAttendances(params) {
  return (0, _request.default)({
    url: "/api/v1/attendances",
    method: 'get',
    params: params
  });
}

function deleteAttendance(id) {
  return (0, _request.default)({
    url: "/api/v1/attendances/".concat(id),
    method: 'delete'
  });
}

function createAttendance(data) {
  return (0, _request.default)({
    url: "/api/v1/attendances",
    data: data,
    method: 'post'
  });
}

function approveAdjustment(id) {
  return (0, _request.default)({
    url: "/api/v1/attendance-adjustments/".concat(id, "/approve"),
    method: 'post'
  });
}

function rejectAdjustment(id) {
  return (0, _request.default)({
    url: "/api/v1/attendance-adjustments/".concat(id, "/reject"),
    method: 'post'
  });
}

function requestAdjustment(data) {
  return (0, _request.default)({
    url: "/api/v1/attendance-adjustments/requestAdjustment",
    data: data,
    method: 'post'
  });
}