"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _user = require("@/api/user");

var _auth = require("@/utils/auth");

var _router = require("@/router");

var getDefaultState = function getDefaultState() {
  return {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    id: '',
    uuid: '',
    roles: [],
    tenant: null
  };
};

var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_TENANT: function SET_TENANT(state, tenant) {
    state.tenant = tenant;
  },
  SET_ID: function SET_ID(state, id) {
    state.id = id;
  },
  SET_UUID: function SET_UUID(state, uuid) {
    state.uuid = uuid;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_STORES: function SET_STORES(state, stores) {
    state.stores = stores;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        username: username.trim(),
        password: password
      }).then(function (response) {
        // const { data } = response
        commit('SET_TOKEN', response.access_token);
        (0, _auth.setToken)(response.access_token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)(state.token).then(function (response) {
        // const { data } = response
        var data = response; // if (!data) {
        //   reject('Verification failed, please Login again.')
        // }
        // const { roles, name, avatar } = data

        var _data$data = data.data,
            roles = _data$data.roles,
            name = _data$data.name,
            uuid = _data$data.uuid,
            id = _data$data.id,
            stores = _data$data.stores,
            tenant = _data$data.tenant; // roles must be a non-empty array
        // if (!roles || roles.length <= 0) {
        //   reject('getInfo: roles must be a non-null array!')
        // }
        // permissions.push('basic')

        commit('SET_ROLES', roles);
        commit('SET_NAME', name);
        commit('SET_UUID', uuid);
        commit('SET_ID', id);
        commit('SET_STORES', stores);
        commit('SET_TENANT', tenant); // commit('SET_AVATAR', avatar)

        data.data.permissions = roles.map(function (role) {
          return role.name;
        });
        data.data.tenant = tenant;
        resolve(data.data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)(state.token).then(function () {
        (0, _auth.removeToken)(); // must remove  token  first

        (0, _router.resetRouter)();
        commit('RESET_STATE');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      (0, _auth.removeToken)(); // must remove  token  first

      commit('RESET_STATE');
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;