"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _goodReceivedNote = require("@/api/goodReceivedNote");

var _elementUi = require("element-ui");

var _supplier = require("@/api/supplier");

var _vueSelect = _interopRequireDefault(require("vue-select"));

require("vue-select/src/scss/vue-select.scss");

var _i18n = _interopRequireDefault(require("@/i18n"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import axios from 'axios'
// import { exportDutiesSalarySummary } from '@/api/duty'
var _default = {
  components: {
    vSelect: _vueSelect.default,
    Pagination: _Pagination.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        perPage: 10,
        listAll: true,
        status: null,
        targetRestockDate: null,
        stores: null
      },
      total: 0,
      suppliers: [],
      statusByOptions: [{
        key: 'drafted',
        name: 'Drafted'
      }, {
        key: 'confirmed',
        name: 'Confirmed'
      }]
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])),
  created: function created() {
    var _this = this;

    this.fetchData();
    (0, _supplier.getSuppliers)({
      perPage: 999
    }).then(function (response) {
      _this.suppliers = response.data;
    });

    if (this.$route.params.pathMatch) {
      this.activeName = this.$route.params.pathMatch;
      this.handleTabClick({
        name: this.$route.params.pathMatch
      });
    } else {
      this.activeName = 'all';
      this.handleTabClick({
        name: 'all'
      });
    }
  },
  methods: {
    fetchData: function fetchData() {
      var _this2 = this;

      this.listLoading = true;
      (0, _goodReceivedNote.getList)(this.listQuery).then(function (response) {
        _this2.list = response.data;
        _this2.listLoading = false;
        _this2.total = response.meta.total;
      });
    },
    editGoodReceivedNote: function editGoodReceivedNote(id) {
      this.$router.push("/good-received-notes/edit/".concat(id));
    },
    addGoodReceivedNote: function addGoodReceivedNote() {
      this.$router.push("/good-received-notes/add");
    },
    downloadGoodReceivedNote: function downloadGoodReceivedNote(id, displayId) {
      (0, _goodReceivedNote.downloadGoodReceivedNote)(id).then(function (response) {
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "".concat(displayId, ".pdf"));
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    downloadGoodReceivedNoteTransitNote: function downloadGoodReceivedNoteTransitNote(id, displayId) {
      (0, _goodReceivedNote.downloadGoodReceivedNoteTransitNote)(id).then(function (response) {
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "".concat(displayId, "_transit_note.pdf"));
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    deleteGoodReceivedNote: function deleteGoodReceivedNote(id) {
      var _this3 = this;

      _elementUi.MessageBox.confirm(_i18n.default.t('GoodReceivedNotes.ConfirmDelete'), 'Confirm', {
        confirmButtonText: _i18n.default.t('GoodReceivedNotes.Delete'),
        cancelButtonText: _i18n.default.t('GoodReceivedNotes.Cancel'),
        type: 'warning'
      }).then(function () {
        (0, _goodReceivedNote.deleteGoodReceivedNote)(id).then(function () {
          (0, _elementUi.Message)({
            message: _i18n.default.t('GoodReceivedNotes.Deleted'),
            type: 'success',
            duration: 5 * 1000
          });

          _this3.fetchData();
        });
      });
    },
    handleTabClick: function handleTabClick(tab) {
      var targetStatus = this.listQuery.status;

      if (tab.name === 'all') {
        targetStatus = null;
      } else if (tab.name === 'drafted') {
        targetStatus = 'Drafted';
      } else if (tab.name === 'confirmed') {
        targetStatus = 'Confirmed';
      } else if (tab.name === 'completed') {
        targetStatus = 'Completed';
      } else {
        targetStatus = null;
      }

      if (targetStatus !== this.listQuery.status) {
        this.listQuery.status = targetStatus;
        this.fetchData();
      }

      this.$router.push("/good-received-notes/" + tab.name);
    }
  }
};
exports.default = _default;