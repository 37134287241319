"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getMessage = getMessage;
exports.createMessage = createMessage;
exports.editMessage = editMessage;
exports.deleteMessage = deleteMessage;
exports.pushMessage = pushMessage;
exports.removeMessageAttachment = removeMessageAttachment;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/messages',
    method: 'get',
    params: params
  });
}

function getMessage(id) {
  return (0, _request.default)({
    url: '/api/v1/messages/' + id + '/edit',
    method: 'get'
  });
}

function createMessage(data) {
  return (0, _request.default)({
    url: '/api/v1/messages',
    data: data,
    method: 'post'
  });
}

function editMessage(data) {
  return (0, _request.default)({
    url: '/api/v1/messages/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteMessage(id) {
  return (0, _request.default)({
    url: '/api/v1/messages/' + id,
    method: 'delete'
  });
}

function pushMessage(id) {
  return (0, _request.default)({
    url: '/api/v1/messages/' + id + '/push',
    method: 'post'
  });
}

function removeMessageAttachment(id) {
  return (0, _request.default)({
    url: 'api/v1/message-attachment/delete/' + id,
    method: 'post'
  });
}