"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSaleVSLavourCost = getSaleVSLavourCost;
exports.getUserLate = getUserLate;
exports.getStoreStaffDuty = getStoreStaffDuty;
exports.getSuppliersPO = getSuppliersPO;
exports.getSupplierPOStore = getSupplierPOStore;
exports.getSupplierByPOStore = getSupplierByPOStore;
exports.getWarehouseReport = getWarehouseReport;
exports.downloadSupplierSummary = downloadSupplierSummary;
exports.downloadSupplierStoreSummary = downloadSupplierStoreSummary;
exports.downloadSupplierStoreByPOSummary = downloadSupplierStoreByPOSummary;
exports.exportStoresSalary = exportStoresSalary;
exports.getSupplierPOSummary = getSupplierPOSummary;
exports.exportFullTimeSalary = exportFullTimeSalary;

var _request = _interopRequireDefault(require("@/utils/request"));

function getSaleVSLavourCost(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/sale-vs-labour-cost",
    method: 'get',
    params: params
  });
}

function getUserLate(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/staff-attendance",
    method: 'get',
    params: params
  });
}

function getStoreStaffDuty(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/store-staff-duty",
    method: 'get',
    params: params
  });
}

function getSuppliersPO(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/supplier-po",
    method: 'get',
    params: params
  });
}

function getSupplierPOStore(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/supplier-po/store",
    method: 'get',
    params: params
  });
}

function getSupplierByPOStore(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/supplier-po/store-bypo",
    method: 'get',
    params: params
  });
}

function getWarehouseReport(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/warehouse-items",
    method: 'get',
    params: params
  });
}

function downloadSupplierSummary(params) {
  return (0, _request.default)({
    url: "/api/v1/export-data/supplier-summary",
    method: 'GET',
    params: params,
    responseType: 'blob'
  });
}

function downloadSupplierStoreSummary(params) {
  // console.log(params)
  return (0, _request.default)({
    url: "/api/v1/export-data/supplier-store-summary",
    method: 'GET',
    params: params,
    responseType: 'blob'
  });
}

function downloadSupplierStoreByPOSummary(params) {
  // console.log(params)
  return (0, _request.default)({
    url: "/api/v1/export-data/supplier-store-bypo",
    method: 'GET',
    params: params,
    responseType: 'blob'
  });
}

function exportStoresSalary(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/stores-salary/pdf",
    method: 'POST',
    responseType: 'blob',
    params: params
  });
}

function getSupplierPOSummary(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/supplier-po/summary",
    method: 'POST',
    params: params
  });
}

function exportFullTimeSalary(params) {
  return (0, _request.default)({
    url: "/api/v1/reports/fulltime-salary/pdf",
    method: 'POST',
    responseType: 'blob',
    params: params
  });
}