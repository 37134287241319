var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.order, rules: _vm.rules, "label-width": "auto" }
        },
        [
          _vm.order.customerOrder !== null
            ? _c("el-form-item", [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.visitCustomer(
                              _vm.order.customerOrder.uuid
                            )
                          }
                        }
                      },
                      [_vm._v("Visit Customer Order")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.order.display_id,
                  callback: function($$v) {
                    _vm.$set(_vm.order, "display_id", $$v)
                  },
                  expression: "order.display_id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          this.$route.params.type !== "customer"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.translation.Store,
                    prop: "store_id",
                    rules: _vm.rules.store_id
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "Type",
                        disabled: _vm.order.uuid !== ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeStore()
                        }
                      },
                      model: {
                        value: _vm.order.store_id,
                        callback: function($$v) {
                          _vm.$set(_vm.order, "store_id", $$v)
                        },
                        expression: "order.store_id"
                      }
                    },
                    [
                      _c("el-option", {
                        key: _vm.order.store_id + "_default",
                        attrs: { label: "Please Select", value: "" }
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.stores, function(store) {
                        return _c("el-option", {
                          key: store.name,
                          attrs: { label: store.name, value: store.uuid }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.translation.Type,
                prop: "type",
                rules: _vm.rules.type
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "Type",
                    disabled:
                      _vm.load.status !== "pending" && _vm.load.status !== ""
                  },
                  model: {
                    value: _vm.order.type,
                    callback: function($$v) {
                      _vm.$set(_vm.order, "type", $$v)
                    },
                    expression: "order.type"
                  }
                },
                [
                  _c("el-option", {
                    key: _vm.order.type + "_default",
                    attrs: { label: "Please Select", value: "" }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.types, function(type) {
                    return _c("el-option", {
                      key: type,
                      attrs: { label: type, value: type }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.translation.ContactTel, prop: "contactTel" }
            },
            [
              _c("el-input", {
                attrs: {
                  readonly:
                    _vm.load.status !== "pending" && _vm.load.status !== ""
                },
                model: {
                  value: _vm.order.contactTel,
                  callback: function($$v) {
                    _vm.$set(_vm.order, "contactTel", $$v)
                  },
                  expression: "order.contactTel"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.translation.ContactEmail } },
            [
              _c("el-input", {
                attrs: {
                  readonly:
                    _vm.load.status !== "pending" && _vm.load.status !== ""
                },
                model: {
                  value: _vm.order.contactEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.order, "contactEmail", $$v)
                  },
                  expression: "order.contactEmail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.translation.Remark } },
            [
              _c("el-input", {
                attrs: {
                  readonly:
                    _vm.load.status !== "pending" && _vm.load.status !== ""
                },
                model: {
                  value: _vm.order.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.order, "remark", $$v)
                  },
                  expression: "order.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.translation.Total } },
            [
              _c("el-input", {
                attrs: { readonly: true },
                model: {
                  value: _vm.orderTotal,
                  callback: function($$v) {
                    _vm.orderTotal = $$v
                  },
                  expression: "orderTotal"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "border" },
            [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 6, sm: 6, md: 6, lg: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "item-product item-header" },
                            [
                              _c(
                                "label",
                                { staticClass: "el-form-item__label" },
                                [_vm._v(_vm._s(_vm.translation.Product))]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 6, sm: 6, md: 6, lg: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "item-product item-header" },
                            [
                              _c(
                                "label",
                                { staticClass: "el-form-item__label" },
                                [_vm._v("Qty")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 6, sm: 6, md: 6, lg: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "item-product item-header" },
                            [
                              _c(
                                "label",
                                { staticClass: "el-form-item__label" },
                                [_vm._v("Price")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.order.items, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "item" },
                  [
                    item.delete !== 1
                      ? _c(
                          "el-row",
                          { staticClass: "item-row" },
                          [
                            _c(
                              "el-col",
                              { attrs: { xs: 6, sm: 6, md: 6, lg: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: item.product.uuid + "-" + index,
                                    staticClass: "item-product",
                                    attrs: {
                                      prop: "items." + index + ".product.uuid",
                                      rules: _vm.rules.uuid
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled:
                                            (_vm.load.status !== "pending" &&
                                              _vm.load.status !== "") ||
                                            _vm.$route.params.type ==
                                              "customer" ||
                                            _vm.order.customerOrder !== null,
                                          placeholder: "Item"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.itemChanged(item, true)
                                          }
                                        },
                                        model: {
                                          value: item.product.uuid,
                                          callback: function($$v) {
                                            _vm.$set(item.product, "uuid", $$v)
                                          },
                                          expression: "item.product.uuid"
                                        }
                                      },
                                      [
                                        _c("el-option", {
                                          key: item.product.uuid + "_default",
                                          attrs: {
                                            label: "Please Select",
                                            value: ""
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm._l(_vm.products, function(
                                          product_item
                                        ) {
                                          return _c("el-option", {
                                            key:
                                              product_item.name +
                                              "-" +
                                              product_item.uuid,
                                            attrs: {
                                              label:
                                                product_item.name +
                                                " ( $" +
                                                product_item.price +
                                                " )",
                                              value: product_item.uuid
                                            }
                                          })
                                        })
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { xs: 6, sm: 6, md: 6, lg: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "item-qty",
                                    attrs: {
                                      prop: "items." + index + ".qty",
                                      rules: _vm.rules.qty
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        readonly:
                                          _vm.load.status !== "pending" &&
                                          _vm.load.status !== "",
                                        disabled:
                                          _vm.$route.params.type ==
                                            "customer" ||
                                          _vm.order.customerOrder !== null,
                                        min: 1
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.qtyChanged(item)
                                        }
                                      },
                                      model: {
                                        value: item.qty,
                                        callback: function($$v) {
                                          _vm.$set(item, "qty", $$v)
                                        },
                                        expression: "item.qty"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { xs: 6, sm: 6, md: 6, lg: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "item-price",
                                    attrs: {
                                      prop: "items." + index + ".price",
                                      rules: _vm.rules.price
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "white-space": "nowrap" }
                                      },
                                      [
                                        _vm._v("$ \n                "),
                                        _c("el-input-number", {
                                          attrs: {
                                            readonly:
                                              _vm.load.status !== "pending" &&
                                              _vm.load.status !== "",
                                            disabled:
                                              _vm.$route.params.type ==
                                                "customer" ||
                                              _vm.order.customerOrder !== null,
                                            min: 0
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.priceChanged(item)
                                            }
                                          },
                                          model: {
                                            value: item.price,
                                            callback: function($$v) {
                                              _vm.$set(item, "price", $$v)
                                            },
                                            expression: "item.price"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 2 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          (_vm.load.status === "pending" ||
                                            _vm.load.status === "") &&
                                          _vm.$route.params.type !=
                                            "customer" &&
                                            _vm.order.customerOrder === null,
                                        expression:
                                          "(load.status === 'pending' || load.status === '') && ($route.params.type!='customer' && order.customerOrder === null)"
                                      }
                                    ],
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteProduct(index)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.translation.Delete))]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 24 } }, [
                              item.product.modifiers !== undefined &&
                              item.product.modifiers.length > 0
                                ? _c(
                                    "div",
                                    { key: "item.product.modifiers-" + index },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "item.product.modifiers-title-" +
                                            index,
                                          staticClass:
                                            "item-modifiers item-header",
                                          staticStyle: {
                                            "margin-bottom": "0px"
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label"
                                              },
                                              [_vm._v("Modifiers")]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        _vm._l(item.product.modifiers, function(
                                          modifier,
                                          m_index
                                        ) {
                                          return _c(
                                            "div",
                                            { key: m_index },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  key:
                                                    "item.product.modifier-" +
                                                    index +
                                                    "-" +
                                                    m_index,
                                                  staticClass: "modifier-title"
                                                },
                                                [_vm._v(_vm._s(modifier.name))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                { attrs: { gutter: 20 } },
                                                _vm._l(
                                                  modifier.options,
                                                  function(option, o_index) {
                                                    return _c(
                                                      "el-col",
                                                      {
                                                        key: o_index,
                                                        attrs: { span: 8 }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            key:
                                                              "item.product.modifier-" +
                                                              index +
                                                              "-" +
                                                              m_index +
                                                              "-" +
                                                              o_index,
                                                            class:
                                                              "grid-content " +
                                                              modifier.uuid +
                                                              " noselect " +
                                                              (_vm.filterOption(
                                                                item.extra_data ==
                                                                  null ||
                                                                  item
                                                                    .extra_data
                                                                    .options ==
                                                                    null
                                                                  ? []
                                                                  : item
                                                                      .extra_data
                                                                      .options,
                                                                option.uuid
                                                              ).length > 0
                                                                ? "selected"
                                                                : ""),
                                                            attrs: {
                                                              "data-price":
                                                                option.add_on_price
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectOption(
                                                                  item,
                                                                  modifier,
                                                                  option
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  option.name
                                                                ) + " "
                                                              ),
                                                              parseFloat(
                                                                option.add_on_price
                                                              ) > 0
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "( " +
                                                                        _vm._s(
                                                                          (option.add_on_price >
                                                                          0
                                                                            ? "+"
                                                                            : "") +
                                                                            " $" +
                                                                            option.add_on_price
                                                                        ) +
                                                                        " )"
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 3, sm: 3, md: 3, lg: 3 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.load.status === "pending" ||
                                      _vm.load.status === "") &&
                                    _vm.$route.params.type != "customer" &&
                                      _vm.order.customerOrder === null,
                                  expression:
                                    "(load.status === 'pending' || load.status === '') && ($route.params.type!='customer' && order.customerOrder === null)"
                                }
                              ],
                              attrs: { type: "primary" },
                              on: { click: _vm.addProduct }
                            },
                            [_vm._v(_vm._s(_vm.translation.AddProduct))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.load.status === "pending" || _vm.load.status === ""
                ? _c(
                    "el-button",
                    { attrs: { type: "success" }, on: { click: _vm.approved } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.translation.Approve) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.load.status === "pending"
                ? _c(
                    "el-button",
                    { attrs: { type: "danger" }, on: { click: _vm.rejected } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.translation.Reject) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.load.status === "pending" || _vm.load.status === ""
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v(_vm._s(_vm.translation.Cancel))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }