"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _deliveryType = require("@/api/deliveryType");

var _elementUi = require("element-ui");

var _vueQrReader = _interopRequireDefault(require("vue-qr-reader/dist/lib/vue-qr-reader.umd"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { parseError } from '@/utils/error-parse'
var _default = {
  components: {
    Pagination: _Pagination.default,
    VueQrReader: _vueQrReader.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0,
      showQRScan: false,
      QRScannerAbility: true
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'user'])),
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _deliveryType.getDeliveryTypes)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.meta.total;
      });
    },

    /* getDeliveryType(id) {
      this.$router.push(`/deliverytype/show/${id}`)
    }, */
    deleteDeliveryType: function deleteDeliveryType(id) {
      var _this2 = this;

      _elementUi.MessageBox.confirm('Confirm to delete Delivery Type?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _deliveryType.deleteDeliveryType)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'DeliveryType deleted',
            type: 'success',
            duration: 5 * 1000
          });

          _this2.fetchData();
        });
      });
    },
    errorCaptured: function errorCaptured(error) {
      this.QRScannerAbility = false;
      console.error(error);
    }
  }
};
exports.default = _default;