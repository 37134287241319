var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: { label: _vm.$t("PurchaseRequests.All"), name: "all" }
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("PurchaseRequests.Drafted"),
              name: "drafted"
            }
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("PurchaseRequests.PendingApproval"),
              name: "submitted"
            }
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("PurchaseRequests.Processing"),
              name: "confirmed"
            }
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("PurchaseRequests.Completed"),
              name: "completed"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.summary
        ? _c(
            "el-dialog",
            {
              attrs: { title: "Summary", visible: _vm.summaryVisible },
              on: {
                "update:visible": function($event) {
                  _vm.summaryVisible = $event
                }
              }
            },
            [
              _vm._l(_vm.summary, function(supplier) {
                return _c(
                  "div",
                  { key: supplier.uuid },
                  [
                    _c("h3", [
                      _vm._v(
                        _vm._s(supplier.name) +
                          " (" +
                          _vm._s(_vm.$t("Order.Total")) +
                          ": $" +
                          _vm._s(supplier.total.toFixed(2)) +
                          ")"
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: supplier.items }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("PurchaseRequests.Item") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(scope.row.name) +
                                        "\n          "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("PurchaseRequests.Qty") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(scope.row.qty) +
                                        " " +
                                        _vm._s(scope.row.unit) +
                                        "\n          "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("PurchaseRequests.UnitPrice")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      "\n            $" +
                                        _vm._s(scope.row.unitPrice.toFixed(2)) +
                                        "\n          "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("PurchaseRequests.SubTotal") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      "\n            $" +
                                        _vm._s(
                                          (
                                            scope.row.unitPrice.toFixed(2) *
                                            scope.row.qty
                                          ).toFixed(2)
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.summaryVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.generatePO }
                    },
                    [_vm._v(_vm._s(_vm.$t("PurchaseRequests.GeneratePO")))]
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("label", [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Stores")) + ": ")]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          attrs: { clearable: "" },
          model: {
            value: _vm.listQuery.storeId,
            callback: function($$v) {
              _vm.$set(_vm.listQuery, "storeId", $$v)
            },
            expression: "listQuery.storeId"
          }
        },
        _vm._l(_vm.stores, function(store) {
          return _c("el-option", {
            key: store.name,
            attrs: { label: store.name, value: store.uuid }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("label", [
        _vm._v(_vm._s(_vm.$t("PurchaseRequests.TargetRestockDate")) + ": ")
      ]),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: { type: "date", placeholder: "Pick Date", clearable: true },
        model: {
          value: _vm.listQuery.targetRestockDate,
          callback: function($$v) {
            _vm.$set(_vm.listQuery, "targetRestockDate", $$v)
          },
          expression: "listQuery.targetRestockDate"
        }
      }),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
        [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Filter")))]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-edit" },
                      on: { click: _vm.addPurchaseRequest }
                    },
                    [_vm._v("Add Purchase Request")]
                  ),
                  _vm._v(" "),
                  _vm.activeName === "all" || _vm.activeName === "confirmed"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "warning",
                            icon: "el-icon-copy-document"
                          },
                          on: { click: _vm.previewData }
                        },
                        [_vm._v("Preview Purchase")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.fetchData($event)
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.search,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "search", $$v)
                          },
                          expression: "listQuery.search"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default", icon: "el-icon-search" },
                          on: { click: _vm.fetchData }
                        },
                        [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Search")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _vm.activeName === "all" || _vm.activeName === "confirmed"
            ? _c(
                "el-table-column",
                {
                  attrs: { align: "center", width: "50" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.status === "Confirmed" &&
                            !(
                              scope.row.purchaseOrders &&
                              scope.row.purchaseOrders.length
                            )
                              ? _c("el-checkbox", {
                                  model: {
                                    value: scope.row.isEnabled,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "isEnabled", $$v)
                                    },
                                    expression: "scope.row.isEnabled"
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1571827972
                  )
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("el-checkbox", { on: { change: _vm.toggleAllEnable } })
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.displayId) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.Store") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.store.name) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("PurchaseRequests.TargetRestockDate"),
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.targetRestockDate) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.Status") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.status) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.UpdatedAt") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.updatedAt) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.editPurchaseRequest(scope.row.uuid)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Show")))]
                    ),
                    _vm._v(" "),
                    scope.row === "drafted" ||
                    _vm.user.roles.find(function(role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deletePurchaseRequest(scope.row.uuid)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Delete")))]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }