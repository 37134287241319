"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  route: {
    ChangePassword: 'Change Password',
    Dashboard: 'Dashboard',
    Report: '報告',
    Reports: 'Reports',
    LabourCost: '人力成本報表',
    StaffAttendance: '員工出勤報表',
    Productivity: '生產效率報表',
    UserManagement: 'User Management',
    POS: 'POS',
    COGS: '銷貨成本報表',
    Modifier: 'Modifier',
    Item: 'Item',
    ProductType: 'Product Type',
    SubProductType: 'Sub Product Type',
    Category: 'Category',
    Kitchen: 'Kitchen Station',
    Users: '用戶',
    EditUser: 'Edit User',
    AddUser: 'Add User',
    MOOMOOMemberManagement: 'MOOMOO Member Management',
    MOOMOOMembers: 'MOOMOO會員',
    ShowMOOMOOMember: 'Show MOOMOO Member',
    EditMOOMOOMember: 'Edit MOOMOO Member',
    AddMOOMOOMember: 'Add MOOMOO Member',
    DeliveryTypeManagement: 'Delivery Type Management',
    DeliveryTypes: '外賣方式',
    ShowDeliveryTypes: 'Show Delivery Type',
    AddDeliveryTypes: 'Add Delivery Type',
    EditDeliveryTypes: 'Edit Delivery Type',
    StoreManagement: 'Store Management',
    Stores: '店舖',
    EditStore: '編輯店舖',
    Sale: 'Sale',
    SaleUpload: 'Sale Upload',
    SalaryReport: 'Salary Report',
    AddStore: '加店舖',
    Roster: 'Roster',
    AttendanceManagement: 'Attendance Management',
    Attendances: '出勤',
    AddAttendance: 'Add Attendance',
    LeaveApplication: '請假申請',
    LeaveApproval: '批核請假',
    DutyManagement: 'Duty Management',
    Payroll: '薪金',
    ShiftManagement: 'Shift Management',
    Shifts: '輪班時間',
    EditShift: 'Edit Shift',
    AddShift: 'Add Shift',
    ExpenseClaims: 'Expense Claims',
    ExpenseClaimList: '洗費報銷',
    AddExpenseClaim: 'Add Expense Claim',
    EditExpenseClaim: 'Edit Expense Claim',
    Message: '訊息',
    EditMessage: '編輯訊息',
    AddMessage: '加訊息',
    MaterialPlanning: '採購',
    Supplier: '供應商',
    EditSupplier: '更新供應商',
    AddSupplier: '加供應商',
    RawMaterial: '原材料',
    EditRawMaterial: '編輯原材料',
    AddRawMaterial: '新增原材料',
    RawMaterialScheduledPrice: '預定原材料價錢',
    AddRawMaterialScheduledPrice: '新增預定原材料價錢',
    PurchaseRequests: '採購PR',
    EditPurchaseRequest: '編輯採購PR',
    AddPurchaseRequest: '新增採購PR',
    PurchaseOrders: '採購訂單',
    PurchaseOrdersRequest: '採購申請',
    EditPurchaseOrder: '編輯採購訂單',
    AddPurchaseOrder: '新增採購訂單',
    GoodReceived: '收貨',
    EditGoodReceived: '編輯收貨',
    AddGoodReceived: '新增收貨',
    WarehouseManagement: '倉庫管理',
    Warehouses: '倉庫',
    AddWarehouse: '新增倉庫',
    EditWarehouseArea: '編輯倉庫區域',
    WarehouseArea: '倉庫區域',
    WarehouseAreas: '倉庫區域',
    WarehouseAreasRawMaterial: '倉庫區域原材料',
    StockIn: '存入',
    StockOut: '出倉',
    StockTake: 'Stock Take',
    // StockTake: '出倉',
    StockTransit: '移倉',
    WarehouseRecords: '倉庫記錄',
    WarehouseInventoryLv: '庫存水平',
    List: 'List',
    Order: '外賣系統',
    Orders: '訂單',
    EditOrder: '編輯訂單',
    SummaryOrder: '概括',
    Labour: '人力資源',
    CRM: '客戶管理',
    Schedule: '更表',
    CashExpense: '現金支出',
    CashExpenseEdit: '編輯現金支出',
    CashExpenseAdd: '新增現金支出',
    CashExpenseList: '現金支出列表',
    CashExpenseReport: '現金支出報告'
  },
  Supplier: {
    AddSupplier: 'Add Supplier',
    Name: '名稱',
    Actions: '動作',
    Edit: '編輯',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Recorddeleted: '記錄已刪除',
    ContactName: 'Contact Name',
    Tel: '電話',
    Email: 'Email',
    Address: 'Address',
    Remark: '備往',
    RequiredNameMessage: 'Name is required',
    IncorrectEmailMessage: 'Please input correct email address',
    UpdateFailedMessage: '更新失敗, 請重試一次',
    Add: '新增',
    Supplierupdated: 'Supplier updated',
    Suppliercreated: 'Supplier created'
  },
  Warehouse: {
    AddWarehouse: '新增倉庫',
    Name: '名稱',
    Action: '動作',
    Actions: '動作',
    Delete: '刪除',
    ConfirmDeleteMessage: '確認刪除',
    Confirm: '確認',
    Cancel: '取消',
    Recorddeleted: '記錄已刪除',
    Area: '區域',
    Areas: '區域',
    AddWarehouseArea: '新增倉庫區域',
    AddWarehouseAreaRawMaterial: '新增原材料',
    SKU: 'SKU',
    QTY: 'QTY',
    Back: '返回',
    StockIn: '存入',
    StockOut: '出倉',
    History: '記錄',
    InventoryLv: 'Inventory Lv',
    DownloadQR: '下載QR'
  },
  WarehouseArea: {
    Name: '名稱',
    Add: '新增',
    Edit: '編輯',
    Submit: '提交',
    Recorddeleted: '記錄已刪除',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Warehouse: '倉庫',
    WarehouseAreacreated: 'Warehouse Area Created',
    WarehouseAreaupdated: 'Warehouse Area Updated',
    UpdateFailedMessage: 'Update Failed',
    RawMaterial: '原材料',
    History: '歷史'
  },
  RawMaterial: {
    Supplier: 'Supplier',
    Category: 'Category'
  },
  RawMaterialScheduledPrice: {
    AddRawMaterialScheduledPrice: '新增預定原材料價錢',
    StartDate: '開始日期',
    Price: '價格',
    Actions: '動作',
    Action: '動作',
    Add: '新增',
    Edit: '編輯',
    Submit: '提交',
    Recorddeleted: '記錄已刪除',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Back: '返回',
    UpdateFailedMessage: 'Update failed, please retry.',
    RequiredMessage: 'Required',
    UpdatedAt: '更新時間',
    CreatedBy: '新增者'
  },
  WarehouseAreaRawMaterial: {
    Name: '名稱',
    Add: '新增',
    Edit: '編輯',
    Submit: '提交',
    Recorddeleted: '記錄已刪除',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Warehouse: 'Warehouse',
    WarehouseArea: 'Warehouse Area',
    WarehouseAreaRawMaterialcreated: 'Warehouse Area Created',
    WarehouseAreaRawMaterialupdated: 'Warehouse Area Updated',
    UpdateFailedMessage: 'Update Failed',
    RawMaterial: '原材料',
    StockIn: 'Stock In',
    StockTake: 'Stock Take',
    StockTransit: 'Stock Transit',
    History: 'History'
  },
  WarehouseRecord: {
    Warehouse: 'Warehouse',
    WarehouseArea: 'Warehouse Area',
    ToWarehouse: 'To Warehouse',
    ToWarehouseArea: 'To Warehouse Area',
    RawMaterial: '原材料',
    Name: '名稱',
    Actions: '動作',
    QTY: 'QTY',
    Remark: '備往',
    Action: '動作',
    Add: '新增',
    Edit: '編輯',
    Submit: '提交',
    Recorddeleted: '記錄已刪除',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Back: '返回',
    WarehouseRecordcreated: 'Warehouse Record Created',
    UpdateFailedMessage: '更新失敗, 請重試一次',
    RequiredMessage: 'Required',
    UpdatedBy: 'Updated By'
  },
  GoodReceivedNotes: {
    All: '全部',
    WarehouseArea: 'Warehouse Area',
    AddGoodReceivedNotes: 'Add Good Received Note',
    Confirm: '確認',
    POOutstanding: 'Outstanding',
    Completed: 'Completed',
    Drafted: 'Drafted',
    Date: '日期',
    Filter: 'Filter',
    Processing: 'Processing',
    Items: 'Items',
    ItemAdd: 'Add Item',
    Warehouse: 'Warehouse',
    RequestQty: '數量要求',
    Result: 'Result',
    RawMaterial: '原材料',
    OutstandingQty: 'Outstanding Qty',
    Qty: '數量',
    ReceivedQty: 'Received Qty',
    Operations: '動作',
    Remark: '備往',
    Name: '名稱',
    Supplier: 'Supplier',
    Keyword: 'Keyword',
    Action: '動作',
    Search: '授索',
    Select: 'Select',
    DownloadTransitNote: '下載 Transit Note',
    POQty: 'PO數量 ',
    POReceived: 'PO Received',
    POOutstaing: 'PO Outstanding',
    Received: 'Received',
    Allocated: 'Allocated',
    PendingAllocation: 'Pending Allocation',
    BackToList: 'Back to List',
    Copy: 'Copy',
    Status: '進度',
    UpdatedAt: 'Updated At',
    PONumber: 'PO Number',
    GoodReceivedDate: 'Good Received Date',
    Save: '儲存',
    Submit: '提交',
    Delete: '刪除',
    Unsubmit: 'Unsubmit',
    Reject: '拒絕',
    Complete: 'Complete',
    DownloadGRN: '下載 Good Received Note',
    DownloadTN: '下載 Transit Note',
    RequiredStatus: '進度需要先',
    RequiredSupplier: 'Supplier is required',
    PurchaseRequestUpdated: 'Purchase Request Updated',
    PurchaseRequestCreated: 'Purchase Request Created',
    UpdateFailed: '更新失敗, 請重試一次',
    CreateFailed: 'Create Failed, please retry',
    DeleteFailed: '刪除失敗, 請重試一次',
    RequestedQty: '數量要求',
    BackToPO: '返回PO',
    ConfirmRequest: 'Confirm to Confirm Request?',
    ConfirmRequestFailed: 'Confirm Request Failed, please retry',
    CompleteRequestFailed: 'Complete Request Failed, please Retry',
    ConfirmDelete: 'Confirm to Delete Record ?',
    ConfirmRequrest: 'Confirm to Confirm Request ?',
    Deleted: 'Record Deleted',
    RequestConfirmed: 'Request Confirmed',
    RequestCompleted: 'Request Completed',
    InvalidInput: 'Invalid Input',
    Type: 'Type'
  },
  PurchaseOrders: {
    PurchaeOrdersText: 'Purchase Orders Text',
    PurchaseOrdersText: 'Purchase Orders Text',
    Search: '授索',
    Supplier: 'Supplier',
    RawMaterial: '原材料',
    Action: '動作',
    ShareWhatsApp: 'Share on Whatsapp',
    Select: 'Select',
    KeyWord: 'KeyWord',
    Status: '進度',
    Result: 'Result',
    PODate: 'PO Date',
    DelivDate: 'Delivery Date',
    DelivAddress: 'Delivery Address',
    ContactName: 'Contact Name',
    ContactTel: '電話',
    ContactEmail: 'Contact Email',
    ContactAddress: 'Contact Address',
    SuppContactName: 'Supplier Contact Name',
    SuppContactTel: 'Supplier 電話',
    SuppContactEmail: 'Supplier Contact Email',
    PurchaseRequestItem: 'Purchase Request Item',
    AddItem: 'Add Item',
    WareHouse: 'WareHouse',
    ReqQty: '數量要求',
    Name: '名稱',
    Qty: '數量',
    UnitPrice: 'Unit Price',
    Subtotal: 'Subtotal',
    Remark: '備往',
    Operations: '動作',
    ReceiveRemark: 'ReceiveRemark',
    RelatedPR: 'Related PR',
    RelatedGR: 'Related Good Receive',
    DownloadPO: '下載 PO',
    Copy: 'Copy',
    BackToList: 'Back to List',
    RequiredStatus: '進度需要先',
    RequiredSupp: 'Supplier is required',
    PRUpdated: 'Purchase Request Updated',
    PRCreated: 'Purchase Request Created',
    POUpdated: 'Purchase Order Updated',
    POCreated: 'Purchase Order Created',
    UpdateFailed: 'Update Failed, please retry',
    CreateFailed: 'Create Failed, please retry',
    DeleteFailed: 'Delete Failed, please retry',
    ConfirmFailed: 'Confirm Failed, please retry',
    ConfirmDelete: 'Confirm to Delete Record?',
    ConfirmRequest: 'Confirm to Confirm Request?',
    ConfirmGenerateGR: 'Confirm to generate Good Received Note?',
    GRFailed: 'Generate Good Received Note Failed, please retry',
    DownloadFailed: 'Download Failed, please retry',
    ReqConfirmed: 'Request Confirmed',
    Delete: '刪除',
    Cancel: '取消',
    Deleted: 'Record Deleted',
    Save: '儲存',
    Confirm: '確認',
    buttonSubmit: '提交',
    buttonDelete: '刪除',
    buttonUnsubmit: 'Unsubmit',
    buttonConfirm: '確認',
    buttonReject: '拒絕',
    buttonComplete: 'Complete',
    buttonDownload: '下載',
    buttonGenerateGR: 'Generate Good Received',
    GRGenerated: 'Good Received Note Generated'
  },
  PurchaseRequests: {
    Items: 'Items',
    TargetRestockDate: 'Target Restock',
    Status: '進度',
    UpdatedAt: 'Updated At',
    PurchaseOrders: 'PurchaseOrders',
    Store: '店舖',
    Search: '授索',
    NoItems: 'No Items',
    ConfirmToGeneratePO: 'Confirm to Generate PO?',
    Name: '名稱',
    Actions: '動作',
    QTY: 'QTY',
    Action: '動作',
    Add: '新增',
    Edit: '編輯',
    Show: '顯示',
    Cancel: '取消',
    POGenerated: 'PO Generated ',
    CantGeneratePOGenerated: 'Cannot generate PO: ',
    RecordDeleted: 'Record Deleted',
    ConfirmDeleteMessage: 'Confirm to delete record?',
    Confirm: '確認',
    Delete: '拒絕',
    Filter: 'Filter',
    WarehouseRecordcreated: 'Warehouse Record Created',
    UpdateFailedMessage: 'Update failed, please retry.',
    RequiredMessage: 'Required',
    SubTotal: 'Sub Total',
    UnitPrice: '基本價格',
    Qty: '數量',
    Item: 'Item',
    Stores: '店舖',
    PickStores: '先店舖店舖',
    All: '全部',
    Drafter: 'Drafted',
    PendingApproval: 'Pending Approval',
    Processing: 'Processing',
    Completed: 'Completed',
    PurchaseRequestItem: 'Purchase Request Item',
    Supplier: 'Supplier',
    Operations: '動作',
    Remark: '備往',
    RelatedPO: 'Related PO',
    AddItem: 'Add Item',
    Fullfilled: 'Fullfilled',
    Back: '返回',
    Save: '儲存',
    Submit: '提交',
    UnSubmit: 'Unsubmit',
    Reject: '拒絕',
    Complete: 'Complete',
    Download: '下載',
    Generate: 'Generate',
    GeeneratePO: 'Generate PO',
    RequiredStatus: '進度需要先',
    RequiredStore: '店舖需要先',
    UpdatedPurchaseRequests: 'Purchase Request Updated',
    UpdateFailed: 'Update Failed, please retry',
    CreatedPurchaseRequests: 'Purchase Request created',
    CreateFailed: 'Create Failed, please retry',
    ConfirmDelete: 'COnfirm to delete record?',
    DeleteFailed: 'Delete Request Failed, please retry. ',
    ConfirmSubmitRequest: 'Confirm to submit request?',
    ConfirmUnsubmitRequest: 'Confirm to unsubmit request? ',
    ConfirmPurchaseRequest: 'Confirm to confirm Request?',
    ConfirmRequestFailed: 'Confirm Request Failed, please retry',
    ConfirmRejectRequest: 'Confirm to reject request?',
    RequestSubmitted: 'Request Submitted',
    RequestRejected: 'Request Rejected',
    SubmitRequestFailed: 'Submit Request Failed, please retry.',
    RequestConfirmed: 'Request Confirmed',
    RejectRequestFailed: '拒絕要求失敗, 請重試一次',
    ConfirmCompleteRequest: 'Confirm change to complete?',
    RequestCompleted: 'Request Completed',
    CompleteRequestFailed: 'Compelete Request Failed, please retry',
    ConfirmGeneratePO: 'Confirm to Generate PO?',
    GeneratePOFailed: 'Failed Generate PO, 請重試一次',
    DownloadFailed: 'Download Failed, 請重試一次',
    ChooseRawMaterial: '選原材料',
    KeyWord: 'KeyWord',
    Result: 'Result',
    RawMaterial: '原材料'
  },
  Category: {
    LoadFail: 'Load Fail',
    SubmitFail: 'Submit Fail',
    Created: 'Category created',
    Updated: 'Category updated',
    Deleted: 'Category deleted'
  },
  ProductType: {
    Add: 'Add Product Type',
    Edit: 'Edit Product Type',
    Created: 'Product Type created',
    Updated: 'Product Type updated',
    Deleted: 'Product Type deleted'
  },
  SubProductType: {
    Add: 'Add Sub Product Type',
    Edit: 'Edit Sub Product Type',
    Created: 'Sub Product Type created',
    Updated: 'Sub Product Type updated',
    Deleted: 'Sub Product Type deleted'
  },
  Modifier: {
    Add: 'Add Modifier',
    Edit: 'Edit Modifier',
    Created: 'Modifier created',
    Updated: 'Modifier updated',
    Deleted: 'Modifier deleted'
  },
  Item: {
    Add: 'Add Item',
    Edit: 'Edit Item',
    Created: 'Item created',
    Updated: 'Item updated',
    Deleted: 'Item deleted',
    PresetSaved: 'Preset Saved',
    LoadSuccess: 'Load Success',
    ImportSuccess: 'Import Success'
  },
  Order: {
    emptyText: '暫無資料',
    Name: '名稱',
    Actions: '動作',
    Add: '新增',
    Confirm: '確認',
    Search: '授索',
    All: '全部',
    Action: '動作',
    Show: '顯示',
    Edit: '編輯',
    Summary: '概括',
    AddOrder: 'Add Order',
    Store: '店舖',
    Type: 'Type',
    Status: '進度',
    Price: '價格',
    Tel: '電話',
    ContactTel: '電話',
    Email: 'Email',
    ContactEmail: 'Contact Email',
    Remark: '備往',
    Product: 'Product',
    Delete: '刪除',
    AddProduct: 'Add Product',
    Create: '新增',
    Update: '更新',
    Cancel: '取消',
    Date: '日期',
    Save: '儲存',
    Submit: '提交',
    Reject: '拒絕',
    Approve: '批准',
    Download: '下載',
    Leave: '請假',
    UpdatedAt: '更新時間',
    Total: '總共'
  },
  passwords: {
    'throttled': 'Too Many Request, Please wait a while'
  },
  CashExpense: {}
};
exports.default = _default;