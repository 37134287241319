var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.rawMaterial,
            rules: _vm.rules,
            "label-width": "auto"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.rawMaterial.id,
                  callback: function($$v) {
                    _vm.$set(_vm.rawMaterial, "id", $$v)
                  },
                  expression: "rawMaterial.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "SKU", prop: "sku" } },
            [
              _c("el-autocomplete", {
                attrs: {
                  "popper-class": "wide-dropdown",
                  "fetch-suggestions": _vm.querySearch
                },
                on: { select: _vm.handleSelect },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.sku))
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.rawMaterial.sku,
                  callback: function($$v) {
                    _vm.$set(_vm.rawMaterial, "sku", $$v)
                  },
                  expression: "rawMaterial.sku"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "nameEng" } },
            [
              _c("el-autocomplete", {
                attrs: {
                  "popper-class": "wide-dropdown",
                  "fetch-suggestions": _vm.querySearch
                },
                on: { select: _vm.handleSelect },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.nameEng))
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.rawMaterial.nameEng,
                  callback: function($$v) {
                    _vm.$set(_vm.rawMaterial, "nameEng", $$v)
                  },
                  expression: "rawMaterial.nameEng"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Unit", prop: "unit" } },
            [
              _c("el-input", {
                attrs: { maxlength: "8" },
                model: {
                  value: _vm.rawMaterial.unit,
                  callback: function($$v) {
                    _vm.$set(_vm.rawMaterial, "unit", $$v)
                  },
                  expression: "rawMaterial.unit"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Supplier", prop: "supplierId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Supplier" },
                  model: {
                    value: _vm.rawMaterial.supplierId,
                    callback: function($$v) {
                      _vm.$set(_vm.rawMaterial, "supplierId", $$v)
                    },
                    expression: "rawMaterial.supplierId"
                  }
                },
                _vm._l(_vm.suppliers, function(supplier) {
                  return _c("el-option", {
                    key: supplier.name,
                    attrs: { label: supplier.name, value: supplier.uuid }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Unit Price" } },
            [
              _c("el-input-number", {
                attrs: { precision: 2, step: 0.1, min: 0 },
                model: {
                  value: _vm.rawMaterial.defaultPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.rawMaterial, "defaultPrice", $$v)
                  },
                  expression: "rawMaterial.defaultPrice"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogPriceFormVisible = true
                    }
                  }
                },
                [_vm._v("Historical")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Packing" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.rawMaterial.packing,
                  callback: function($$v) {
                    _vm.$set(_vm.rawMaterial, "packing", $$v)
                  },
                  expression: "rawMaterial.packing"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("PurchaseRequests.Store"), prop: "stores" }
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.allStoreChecked,
                    callback: function($$v) {
                      _vm.allStoreChecked = $$v
                    },
                    expression: "allStoreChecked"
                  }
                },
                [_vm._v("All")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.rawMaterial.stores,
                    callback: function($$v) {
                      _vm.$set(_vm.rawMaterial, "stores", $$v)
                    },
                    expression: "rawMaterial.stores"
                  }
                },
                _vm._l(_vm.stores, function(item) {
                  return _c(
                    "el-checkbox",
                    { key: item.id, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Warehouse", prop: "warehouses" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.allWarehouseChecked,
                    callback: function($$v) {
                      _vm.allWarehouseChecked = $$v
                    },
                    expression: "allWarehouseChecked"
                  }
                },
                [_vm._v("All")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.rawMaterial.warehouses,
                    callback: function($$v) {
                      _vm.$set(_vm.rawMaterial, "warehouses", $$v)
                    },
                    expression: "rawMaterial.warehouses"
                  }
                },
                _vm._l(_vm.warehouses, function(item) {
                  return _c(
                    "el-checkbox",
                    { key: item.id, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Category", prop: "categories" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.rawMaterial.categories,
                    callback: function($$v) {
                      _vm.$set(_vm.rawMaterial, "categories", $$v)
                    },
                    expression: "rawMaterial.categories"
                  }
                },
                _vm._l(_vm.categories, function(item) {
                  return _c(
                    "el-checkbox",
                    { key: item.id, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: { to: "/raw-materials" }
                },
                [_vm._v(_vm._s(_vm.$t("Order.Cancel")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("price-dialog", {
        attrs: {
          "raw-material-id": _vm.$route.params.id,
          "dialog-form-visible": _vm.dialogPriceFormVisible
        },
        on: {
          "update:dialogFormVisible": function($event) {
            _vm.dialogPriceFormVisible = $event
          },
          "update:dialog-form-visible": function($event) {
            _vm.dialogPriceFormVisible = $event
          },
          complete: _vm.onPriceChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }