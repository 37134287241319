"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMOOMOOMembers = getMOOMOOMembers;
exports.getMOOMOOMember = getMOOMOOMember;
exports.createMOOMOOMember = createMOOMOOMember;
exports.editMOOMOOMember = editMOOMOOMember;
exports.getPasskitPoint = getPasskitPoint;
exports.editPasskitPoint = editPasskitPoint;
exports.addActivity = addActivity;
exports.changePasskitPoint = changePasskitPoint;
exports.changeMOOMOOMemberPassword = changeMOOMOOMemberPassword;
exports.deleteMOOMOOMember = deleteMOOMOOMember;
exports.activateMOOMOOMember = activateMOOMOOMember;
exports.deactivateMOOMOOMember = deactivateMOOMOOMember;

var _request = _interopRequireDefault(require("@/utils/request"));

function getMOOMOOMembers(params) {
  return (0, _request.default)({
    url: '/api/v1/moomoomembers',
    method: 'get',
    params: params
  });
}

function getMOOMOOMember(id) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/".concat(id),
    method: 'get'
  });
}

function createMOOMOOMember(data) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers",
    method: 'post',
    data: data
  });
}

function editMOOMOOMember(data) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/".concat(data.id),
    method: 'put',
    data: data
  });
}

function getPasskitPoint(id) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/passkitpoint/".concat(id),
    method: 'get'
  });
}

function editPasskitPoint(data) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/passkitpoint/".concat(data.id),
    method: 'put',
    data: data
  });
}

function addActivity(data) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/passkitactivity/".concat(data.id),
    method: 'post',
    data: data
  });
}

function changePasskitPoint(data) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/passkitpoint",
    method: 'put',
    data: data
  });
}

function changeMOOMOOMemberPassword(data) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/password",
    method: 'put',
    data: data
  });
}

function deleteMOOMOOMember(id) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/".concat(id),
    method: 'delete'
  });
}

function activateMOOMOOMember(uuid) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/activate",
    method: 'post',
    data: {
      id: uuid
    }
  });
}

function deactivateMOOMOOMember(uuid) {
  return (0, _request.default)({
    url: "/api/v1/moomoomembers/deactivate",
    method: 'post',
    data: {
      id: uuid
    }
  });
}