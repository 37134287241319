"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getCashExpense = getCashExpense;
exports.createCashExpense = createCashExpense;
exports.editCashExpense = editCashExpense;
exports.deleteCashExpense = deleteCashExpense;
exports.removeExpenseAttachment = removeExpenseAttachment;
exports.getReport = getReport;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses',
    method: 'get',
    params: params
  });
}

function getCashExpense(id) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses/' + id,
    method: 'get'
  });
}

function createCashExpense(data) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses',
    data: data,
    method: 'post'
  });
}

function editCashExpense(data) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteCashExpense(id) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses/' + id,
    method: 'delete'
  });
}

function removeExpenseAttachment(id) {
  return (0, _request.default)({
    url: 'api/v1/cash-expense-attachment/delete/' + id,
    method: 'post'
  });
}

function getReport(params) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses/report',
    method: 'get',
    params: params
  });
}