"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _moment = _interopRequireDefault(require("moment"));

var _vuex = require("vuex");

var _duty = require("@/api/duty");

var _report = require("@/api/report");

var _user = require("@/api/user");

var _store = require("@/api/store");

var _elementUi = require("element-ui");

var _errorParse = require("@/utils/error-parse");

var _i18n = _interopRequireDefault(require("@/i18n"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      users: [],
      userOptions: [],
      stores: [],
      roles: [],
      listQuery: {
        isPublic: 'yes',
        fromTime: null,
        toTime: null,
        userId: null,
        storeId: null,
        roleId: null,
        page: 1,
        perPage: 10
      },
      result: {
        isIncludeFullTime: true,
        isIncludePartTime: true
      },
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'user'])), {}, {
    page: {
      get: function get() {
        return this.listQuery.page;
      }
    },
    perPage: {
      get: function get() {
        return this.listQuery.perPage;
      }
    },
    dutyDate: {
      get: function get() {
        if (this.isIncludePartTime) {
          return [this.listQuery.fromTime, this.listQuery.toTime];
        } else {
          return this.listQuery.fromTime;
        }
      },
      set: function set(secondDateOfWeek) {
        if (this.isIncludePartTime) {
          if (secondDateOfWeek != null) {
            this.listQuery.fromTime = new Date(secondDateOfWeek[0]);
            this.listQuery.toTime = new Date(secondDateOfWeek[1]);
          } else {
            this.listQuery.fromTime = '';
            this.listQuery.toTime = '';
          }
        } else {
          if (secondDateOfWeek != null) {
            this.listQuery.fromTime = new Date(secondDateOfWeek);
            this.listQuery.toTime = (0, _moment.default)(new Date(secondDateOfWeek)).endOf('month').toDate();
          } else {
            this.listQuery.fromTime = '';
            this.listQuery.toTime = '';
          }
        }
      }
    },
    isIncludeFullTime: {
      get: function get() {
        var _this = this;

        return !this.users.length || !this.listQuery.roleId && !this.listQuery.userId || [4, 5].indexOf(this.listQuery.roleId) !== -1 || this.listQuery.userId && this.users.filter(function (user) {
          return user.uuid === _this.listQuery.userId;
        })[0].roles.find(function (role) {
          return ['Staff (Full time)'].includes(role.name);
        });
      }
    },
    isIncludePartTime: {
      get: function get() {
        var _this2 = this;

        return !this.users.length || !this.listQuery.roleId && !this.listQuery.userId || this.listQuery.roleId === 9 || this.listQuery.userId && this.users.filter(function (user) {
          return user.uuid === _this2.listQuery.userId;
        })[0].roles.find(function (role) {
          return ['Staff (Part time)'].includes(role.name);
        });
      }
    }
  }),
  watch: {
    page: function page(val) {
      this.$router.push({
        query: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.$route.query), {}, {
          page: this.listQuery.page
        })
      });
    },
    perPage: function perPage(val) {
      this.$router.push({
        query: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.$route.query), {}, {
          perPage: this.listQuery.perPage
        })
      });
    }
  },
  created: function created() {
    var currentDate = new Date();

    if ((0, _moment.default)(currentDate).isoWeekday() >= 5) {
      this.listQuery['fromTime'] = new Date((0, _moment.default)(currentDate).isoWeekday(5));
      this.listQuery['toTime'] = new Date((0, _moment.default)(currentDate).isoWeekday(11));
    } else {
      this.listQuery['fromTime'] = new Date((0, _moment.default)(currentDate).isoWeekday(-2));
      this.listQuery['toTime'] = new Date((0, _moment.default)(currentDate).isoWeekday(3));
    }

    for (var key in this.listQuery) {
      if (this.$route.query[key]) {
        if (typeof this.listQuery[key] === 'number') {
          this.listQuery[key] = Number(this.$route.query[key]);
        } else if (key === 'roleId') {
          this.listQuery[key] = Number(this.$route.query[key]);
        } else if (key === 'fromTime' || key === 'toTime') {
          this.listQuery[key] = new Date(this.$route.query[key]);
        } else {
          this.listQuery[key] = this.$route.query[key];
        }
      }
    }

    this.fetchData();
    this.fetchRoles();
    this.fetchStores();
    this.fetchUsers();
  },
  methods: {
    fetchRoles: function fetchRoles() {
      var _this3 = this;

      (0, _user.getRoles)({
        limit: 999
      }).then(function (response) {
        _this3.roles = response.data ? response.data.filter(function (row) {
          return ['Staff (Full time)', 'Staff (Part time)'].includes(row.name);
        }) : [];
      });
    },
    fetchUsers: function fetchUsers() {
      var _this4 = this;

      (0, _user.getUsers)({
        limit: 999,
        roles: ['staff.parttime', 'staff']
      }).then(function (response) {
        _this4.users = response.data;
        _this4.userOptions = response.data;
        _this4.result.isIncludePartTime = _this4.isIncludePartTime;
        _this4.result.isIncludeFullTime = _this4.isIncludeFullTime;
      });
    },
    fetchStores: function fetchStores() {
      var _this5 = this;

      (0, _store.getStores)({
        limit: 999
      }).then(function (response) {
        _this5.stores = response.data;
      });
    },
    userFilter: function userFilter(val) {
      this.value = val;

      if (val) {
        this.userOptions = this.users.filter(function (item) {
          if (!!~item.name.indexOf(val) || !!~item.name.toUpperCase().indexOf(val.toUpperCase())) {
            return true;
          }
        });
      } else {
        this.userOptions = this.users;
      }
    },
    query: function query() {
      var param = {};

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          var current_datetime = this.listQuery[key];
          var formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00');
          param[key] = formatted_date;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      this.$router.push({
        query: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), param)
      });
      this.fetchData();
    },
    downloadExcel: function downloadExcel() {
      (0, _elementUi.Message)({
        message: 'Preparing download file, please wait...',
        type: 'success',
        duration: 5 * 1000
      });
      this.fetchData();
      var param = {};

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          var current_datetime = this.listQuery[key];
          var formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00');
          param[key] = formatted_date;
        } else if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      (0, _duty.exportDutiesExcel)(param).then(function (response) {
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'duties.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    downloadFullTime: function downloadFullTime() {
      (0, _report.exportFullTimeSalary)({
        dateMonth: this.listQuery.fromTime,
        userId: this.listQuery.userId ? this.listQuery.userId : null
      }).then(function (response) {
        // console.log(response)
        // return
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "salary.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    downloadBreakdown: function downloadBreakdown() {
      (0, _elementUi.Message)({
        message: 'Preparing download file, please wait...',
        type: 'success',
        duration: 5 * 1000
      });
      this.fetchData();
      var param = {};

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          var current_datetime = this.listQuery[key];
          var formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00');
          param[key] = formatted_date;
        } else if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      (0, _duty.exportDutiesSalaryBreakdown)(param).then(function (response) {
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'salary_breakdown.zip');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || 'Cannot Download the Report',
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    downloadSummary: function downloadSummary() {
      (0, _elementUi.Message)({
        message: 'Preparing download file, please wait...',
        type: 'success',
        duration: 5 * 1000
      });
      this.fetchData();
      var param = {};

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          var current_datetime = this.listQuery[key];
          var formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00');
          param[key] = formatted_date;
        } else if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      (0, _duty.exportDutiesSalarySummary)(param).then(function (response) {
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'salary_overview.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    downloadPersonalSummary: function downloadPersonalSummary() {
      var param = this.listQuery;
      (0, _duty.personalSummary)(param).then(function (response) {
        // console.log(response);
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'personalSummary.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    fetchData: function fetchData() {
      var _this6 = this;

      this.listLoading = true;
      var param = {
        filter: 'Y'
      };

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          var current_datetime = this.listQuery[key];
          var formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00');
          param[key] = formatted_date;
        } else if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      (0, _duty.getDuties)(param).then(function (response) {
        _this6.list = response.data;
        _this6.listLoading = false;
        _this6.total = response.meta.total;

        if (_this6.users.length) {
          _this6.result.isIncludePartTime = _this6.isIncludePartTime;
          _this6.result.isIncludeFullTime = _this6.isIncludeFullTime;
        }
      });
    },
    disabledDate: function disabledDate(time) {
      var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      return time.getTime() > yesterday;
    }
  }
};
exports.default = _default;