"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.asyncRoutes = exports.constantRoutes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _empty = _interopRequireDefault(require("@/layout/empty"));

var _i18n = _interopRequireDefault(require("@/i18n"));

_vue.default.use(_vueRouter.default);
/* Layout */


/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/forget-password',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/forgetPassword/index'));
    });
  },
  hidden: true
}, {
  path: '/reset-password',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/resetPassword/index'));
    });
  },
  hidden: true
}, {
  path: '/changePassword',
  component: _layout.default,
  children: [{
    path: '',
    name: _i18n.default.t('route.ChangePassword'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/changePassword/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.ChangePassword'),
      icon: 'dashboard'
    }
  }],
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [{
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  name: 'route.Dashboards',
  meta: {// roles: ['Admin', 'Warehouse Manager', 'Operation Manager']
  },
  children: [{
    path: '/dashboard',
    name: _i18n.default.t('route.Dashboard'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Dashboard'),
      icon: 'dashboard'
    }
  }]
}, // labour
{
  path: '/labour',
  component: _layout.default,
  name: 'Labour',
  redirect: 'noRedirect',
  meta: {
    title: _i18n.default.t('route.Labour'),
    icon: 'user',
    roles: ['Admin', 'Operation Manager', 'Store Manager']
  },
  children: [// user management
  {
    path: '/users',
    name: _i18n.default.t('route.Users'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/users/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Users'),
      icon: 'user',
      roles: ['Admin', 'Operation Manager', 'Store Manager']
    },
    children: [{
      path: 'edit/:id',
      name: _i18n.default.t('route.EditUser'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/users/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.EditUser'),
        icon: 'education'
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddUser'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/users/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.AddUser'),
        icon: 'education'
      },
      hidden: true
    }, {
      path: ':id/leaves',
      name: '假期管理',
      // i18n.t('route.AddUser'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/users/leaves'));
        });
      },
      meta: {
        title: '假期管理',
        // i18n.t('route.AddUser'),
        icon: 'education'
      },
      hidden: true
    }, {
      path: ':user_id/leaves/summary',
      name: 'User Leaves Type',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/leaves/summary'));
        });
      },
      meta: {
        title: 'Leave Summary',
        icon: 'chart',
        roles: ['Admin', 'Operation Manager', 'Store Manager']
      },
      hidden: true,
      children: [{
        path: '/users/:user_id/leaves/apply/add',
        name: 'Leave Add',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/leaves/apply'));
          });
        },
        meta: {
          title: 'Leave Add',
          // i18n.t('route.LabourCost'),
          icon: 'chart'
        },
        hidden: true
      }, {
        path: ':id',
        name: 'User Leaves Detail',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/leaves/history'));
          });
        },
        meta: {
          title: 'Leave Apply Record',
          icon: 'chart'
        },
        hidden: true,
        children: [{
          path: '/users/:user_id/leaves/apply/edit/:id',
          name: 'Leave Edit',
          component: function component() {
            return Promise.resolve().then(function () {
              return (0, _interopRequireWildcard2.default)(require('@/views/leaves/apply'));
            });
          },
          meta: {
            title: 'Leave Edit',
            // i18n.t('route.LabourCost'),
            icon: 'chart'
          },
          hidden: true
        }]
      }]
    }]
  }, // stores
  {
    path: '/stores',
    name: _i18n.default.t('route.Stores'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stores/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Schedule'),
      icon: 'shop'
    },
    children: [{
      path: 'edit/:id',
      name: _i18n.default.t('route.EditStore'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/stores/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.EditStore'),
        icon: 'shopping'
      },
      hidden: true
    }, {
      path: ':id/sales',
      name: _i18n.default.t('route.Sale'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/stores/sales'));
        });
      },
      meta: {
        title: _i18n.default.t('route.Sale'),
        icon: 'shopping',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true,
      children: [{
        path: 'upload',
        name: _i18n.default.t('route.SaleUpload'),
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/stores/sale-upload'));
          });
        },
        meta: {
          title: _i18n.default.t('route.SaleUpload'),
          icon: 'shopping',
          roles: ['Admin', 'Operation Manager']
        },
        hidden: true
      }]
    }, {
      path: 'salaryreport',
      name: _i18n.default.t('route.SalaryReport'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/stores/salaryreport'));
        });
      },
      meta: {
        title: _i18n.default.t('route.SalaryReport'),
        icon: 'shopping'
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddStore'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/stores/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.AddStore'),
        icon: 'shopping',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true
    }, {
      path: 'roster/:id',
      name: _i18n.default.t('route.Roster'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/duties/roster'));
        });
      },
      meta: {
        title: _i18n.default.t('route.Roster'),
        icon: 'peoples'
      },
      hidden: true
    }]
  }, // duties
  {
    path: '/duties',
    name: _i18n.default.t('route.Payroll'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/duties/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Payroll'),
      icon: 'money'
    }
  }, // attendance
  {
    path: '/attendances',
    name: _i18n.default.t('route.Attendances'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/attendances/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Attendances'),
      icon: 'skill'
    },
    children: [{
      path: 'add',
      name: _i18n.default.t('route.AddAttendance'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/attendances/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.AddAttendance'),
        icon: 'skill'
      },
      hidden: true
    }]
  }, // leave
  {
    path: '/leaves',
    name: '假期設定',
    // i18n.t('route.Users'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/leaves/index'));
      });
    },
    meta: {
      title: '假期設定',
      // i18n.t('route.Users'),
      icon: 'leave-type',
      roles: ['Admin', 'Operation Manager']
    },
    children: [{
      path: 'edit/:id',
      name: 'Leaves',
      // i18n.t('route.EditShift'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/leaves/edit'));
        });
      },
      meta: {
        title: 'Leaves',
        // i18n.t('route.EditShift'),
        icon: 'skill',
        roles: ['Admin']
      },
      hidden: true
    }, {
      path: 'add',
      name: 'Leaves Add',
      // i18n.t('route.AddShift'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/leaves/edit'));
        });
      },
      meta: {
        title: 'Leaves',
        // i18n.t('route.AddShift'),
        icon: 'skill',
        roles: ['Admin']
      },
      hidden: true
    }, {
      path: 'staff/:id',
      name: '適用員工',
      // i18n.t('route.EditShift'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/leaves/staff'));
        });
      },
      meta: {
        title: '適用員工',
        // i18n.t('route.EditShift'),
        icon: 'skill',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true
    }, {
      path: 'approval',
      name: _i18n.default.t('route.LeaveApproval'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/leaves/approval'));
        });
      },
      meta: {
        title: _i18n.default.t('route.LeaveApproval'),
        icon: 'skill'
      },
      hidden: true
    }]
  }, // Approve
  {
    path: '/leaves/application',
    name: _i18n.default.t('route.LeaveApplication'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/leaves/application'));
      });
    },
    meta: {
      title: _i18n.default.t('route.LeaveApplication'),
      icon: 'leave-submission',
      roles: ['Admin', 'Operation Manager']
    }
  }, // shifts
  {
    path: '/shifts',
    name: _i18n.default.t('route.Shifts'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shifts/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Shifts'),
      icon: 'chart',
      roles: ['Admin', 'Operation Manager']
    },
    children: [{
      path: 'edit/:id',
      name: _i18n.default.t('route.EditShift'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/shifts/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.EditShift'),
        icon: 'education',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddShift'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/shifts/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.AddShift'),
        icon: 'education',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true
    }]
  }]
}, // Procurement
{
  path: '/meterial-planning',
  component: _layout.default,
  redirect: 'noRedirect',
  name: _i18n.default.t('route.MaterialPlanning'),
  meta: {
    title: _i18n.default.t('route.MaterialPlanning'),
    icon: 'table',
    roles: ['Admin', 'Procurement Manager', 'Warehouse Manager']
  },
  children: [{
    path: '/suppliers',
    name: _i18n.default.t('route.Supplier'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/suppliers/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Supplier'),
      icon: 'tree',
      roles: ['Admin', 'Procurement Manager']
    },
    children: [{
      path: 'edit/:id',
      name: _i18n.default.t('route.EditSupplier'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/suppliers/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.EditSupplier'),
        icon: 'tree',
        roles: ['Admin', 'Procurement Manager']
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddSupplier'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/suppliers/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.AddSupplier'),
        icon: 'tree',
        roles: ['Admin', 'Procurement Manager']
      },
      hidden: true
    }]
  }, {
    path: '/raw-materials',
    name: _i18n.default.t('route.RawMaterial'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/rawMaterials/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.RawMaterial'),
      icon: 'table',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    },
    children: [{
      path: ':id/purchase-orders',
      name: 'route-purchase-orders-history',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/rawMaterials/PurchaseOrders'));
        });
      },
      meta: {
        title: 'Purchase Order History',
        icon: 'table',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }, {
      path: 'edit/:id',
      name: _i18n.default.t('route.EditRawMaterial'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/rawMaterials/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.EditRawMaterial'),
        icon: 'table',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddRawMaterial'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/rawMaterials/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.AddRawMaterial'),
        icon: 'table',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }]
  }, {
    path: '/raw-materials/:rawMaterialId/scheduled-price',
    name: _i18n.default.t('route.RawMaterialScheduledPrice'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/rawMaterials/scheduled-price-index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.RawMaterialScheduledPrice'),
      icon: 'table',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    },
    hidden: true
  }, {
    path: '/raw-materials/:rawMaterialId/scheduled-price/add',
    name: _i18n.default.t('route.AddRawMaterialScheduledPrice'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/rawMaterials/scheduled-price-edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.AddRawMaterialScheduledPrice'),
      icon: 'table',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    },
    hidden: true
  },
  /* {
    path: '/purchase-requests',
    redirect: '/purchase-requests/confirmed',
    name: i18n.t('route.PurchaseRequests'),
    component: () => import('@/views/purchaseRequests/index'),
    meta: {
      title: i18n.t('route.PurchaseRequests'),
      icon: 'message',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    }
  }, */
  {
    path: '/purchase-requests/(all|drafted|submitted|confirmed|completed)',
    name: _i18n.default.t('route.PurchaseRequests'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/purchaseRequests/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.PurchaseRequests'),
      icon: 'message',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    },
    hidden: true,
    children: [{
      path: '/purchase-requests/add',
      name: _i18n.default.t('route.AddMessage'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/purchaseRequests/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.AddPurchaseRequest'),
        icon: 'message',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }]
  }, {
    // Warehouse PO Index
    path: '/purchase-orders/Kconfirmed',
    redirect: '/purchase-orders/confirmed',
    name: 'route.PurchaseOrderConfirmed',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/purchaseOrders/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.PurchaseOrders'),
      icon: 'message',
      roles: ['Admin', 'Warehouse Manager', 'Procurement Manager']
    },
    children: [{
      path: '/purchase-requests/edit/:id',
      name: _i18n.default.t('route.EditMessage'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/purchaseRequests/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.EditPurchaseRequest'),
        icon: 'message',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }, {
      path: '/purchase-orders/edit/:id',
      name: _i18n.default.t('route.EditPurchaseOrder'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/purchaseOrders/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.EditPurchaseOrder'),
        icon: 'message'
      },
      hidden: true
    }, {
      path: '/purchase-orders/add',
      name: _i18n.default.t('route.AddPurchaseOrder'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/purchaseOrders/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.AddPurchaseOrder'),
        icon: 'message'
      },
      hidden: true
    }]
  },
  /* { // Other PO Index
    path: '/purchase-orders/Ksubmitted',
    redirect: '/purchase-orders/submitted',
    name: 'route.PurchaseOrderSubmitted',
    component: () => import('@/views/purchaseOrders/index'),
    meta: {
      title: i18n.t('route.PurchaseOrdersRequest'),
      icon: 'message',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    }
  }, */
  {
    path: '/purchase-orders/(submitted|drafted|confirmed|outstanding|completed|all)',
    name: _i18n.default.t('route.PurchaseOrders'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/purchaseOrders/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.PurchaseOrders'),
      icon: 'message'
    },
    hidden: true
  }, {
    path: '/good-received-notes',
    redirect: '/good-received-notes/confirmed',
    name: _i18n.default.t('route.GoodReceived'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goodReceivedNotes/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.GoodReceived'),
      icon: 'message'
    },
    children: [{
      path: '/good-received-notes/edit/:id',
      name: _i18n.default.t('route.EditGoodReceived'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/goodReceivedNotes/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.EditGoodReceived'),
        icon: 'message'
      },
      hidden: true
    }, {
      path: '/good-received-notes/add',
      name: _i18n.default.t('route.AddGoodReceived'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/goodReceivedNotes/edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.AddGoodReceived'),
        icon: 'message'
      },
      hidden: true
    }]
  }, {
    path: '/good-received-notes/(all|drafted|confirmed|completed)',
    name: 'route.GoodsReceived',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goodReceivedNotes/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.GoodReceived'),
      icon: 'message'
    },
    hidden: true
  }]
}, // inventory
{
  path: '/warehouses',
  redirect: 'noRedirect',
  component: _layout.default,
  name: _i18n.default.t('route.WarehouseManagement'),
  meta: {
    title: _i18n.default.t('route.WarehouseManagement'),
    icon: 'peoples',
    roles: ['Admin', 'Operation Manager', 'Procurement Manager', 'Warehouse Manager']
  },
  children: [{
    path: '/warehouses',
    name: _i18n.default.t('route.Warehouses'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Warehouses'),
      icon: 'warehouse'
    },
    children: [{
      path: ':id/areas',
      name: _i18n.default.t('route.WarehouseAreas'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/area-index'));
        });
      },
      meta: {
        title: _i18n.default.t('route.WarehouseAreas'),
        icon: 'shopping'
      },
      hidden: true,
      children: [{
        path: '/warehouses-areas/edit/:id',
        name: _i18n.default.t('route.EditWarehouseArea'),
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/area-edit'));
          });
        },
        meta: {
          title: _i18n.default.t('route.EditWarehouseArea'),
          icon: 'shopping'
        },
        hidden: true
      }, {
        path: '/warehouses-areas/add',
        name: 'WarehouseAreaAdd',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/area-edit'));
          });
        },
        meta: {
          title: _i18n.default.t('route.WarehouseArea'),
          icon: 'shopping'
        },
        hidden: true
      }, {
        path: '/warehouse-areas/:id/raw-materials',
        name: _i18n.default.t('route.WarehouseAreasRawMaterial'),
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/area-raw-material-index'));
          });
        },
        meta: {
          title: _i18n.default.t('route.WarehouseAreasRawMaterial'),
          icon: 'shopping'
        },
        hidden: true
      }, {
        path: '/warehouses-areas-raw-materials/add',
        name: 'WarehouseAreasRawMaterialAdd',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/area-raw-material-edit'));
          });
        },
        meta: {
          title: _i18n.default.t('route.WarehouseAreasRawMaterial'),
          icon: 'shopping'
        },
        hidden: true
      }]
    }, {
      path: ':id/(stock-in)',
      name: _i18n.default.t('route.StockIn'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/stock-in'));
        });
      },
      meta: {
        title: _i18n.default.t('route.StockIn'),
        icon: 'shopping'
      },
      hidden: true
    }, {
      path: ':id/(stock-out)',
      name: _i18n.default.t('route.StockOut'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/stock-in'));
        });
      },
      meta: {
        title: _i18n.default.t('route.StockOut'),
        icon: 'shopping'
      },
      hidden: true
    }, {
      path: ':id/(stock-take)',
      name: _i18n.default.t('route.StockTake'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/stock-in'));
        });
      },
      meta: {
        title: _i18n.default.t('route.StockOut'),
        icon: 'shopping'
      },
      hidden: true
    }, {
      path: ':id/(stock-transit)',
      name: _i18n.default.t('route.StockTransit'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/stock-in'));
        });
      },
      meta: {
        title: _i18n.default.t('route.StockTransit'),
        icon: 'shopping'
      },
      hidden: true
    }, {
      path: ':id/records',
      name: _i18n.default.t('route.WarehouseRecords'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/record-index'));
        });
      },
      meta: {
        title: _i18n.default.t('route.WarehouseRecords'),
        icon: 'shopping'
      },
      hidden: true
    }, {
      path: ':id/inventory-lv',
      name: _i18n.default.t('route.WarehouseInventoryLv'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouses/inventory-lv'));
        });
      },
      meta: {
        title: _i18n.default.t('route.WarehouseInventoryLv'),
        icon: 'shopping'
      },
      hidden: true
    }]
  }]
}, // customer order
{
  path: '/order',
  redirect: 'noRedirect',
  component: _layout.default,
  name: _i18n.default.t('route.Order'),
  meta: {
    title: _i18n.default.t('route.Order'),
    icon: 'shopping',
    roles: ['Admin', 'Store Manager', 'Floor Manager'],
    tenants: ['tastelab']
  },
  children: [{
    path: '/orders',
    name: _i18n.default.t('route.Orders'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orders/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Orders'),
      icon: 'shopping',
      tenants: ['tastelab']
    }
  }, {
    path: '/orders/edit/:uuid',
    name: _i18n.default.t('route.EditOrder'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orders/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.EditOrder'),
      icon: 'shopping',
      tenants: ['tastelab']
    },
    hidden: true
  }, {
    path: '/orders/edit/:uuid/:type',
    name: _i18n.default.t('route.EditOrder') + ' Type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orders/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.EditOrder'),
      icon: 'shopping',
      tenants: ['tastelab']
    },
    hidden: true
  }, {
    path: '/orders/summary/:uuid',
    name: _i18n.default.t('route.EditOrder') + ' Summary',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orders/summary'));
      });
    },
    meta: {
      title: _i18n.default.t('route.SummaryOrder'),
      icon: 'shopping',
      tenants: ['tastelab']
    },
    hidden: true
  }, {
    path: '/orders/summary/:uuid/:type',
    name: _i18n.default.t('route.EditOrder') + ' Summary Type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orders/summary'));
      });
    },
    meta: {
      title: _i18n.default.t('route.SummaryOrder'),
      icon: 'shopping',
      tenants: ['tastelab']
    },
    hidden: true
  }, {
    path: '/orders/add',
    name: _i18n.default.t('route.AddStore') + ' Order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orders/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.EditOrder'),
      icon: 'shopping'
    },
    hidden: true
  }, {
    path: '/orders/add/:type',
    name: _i18n.default.t('route.AddStore') + ' Order Type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orders/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.EditOrder'),
      icon: 'shopping',
      tenants: ['tastelab']
    },
    hidden: true
  }]
}, // delivery type
{
  path: '/delivery',
  redirect: 'noRedirect',
  component: _layout.default,
  name: _i18n.default.t('route.DeliveryTypes'),
  meta: {
    title: _i18n.default.t('route.DeliveryTypes'),
    icon: 'meal',
    roles: ['Admin', 'Operation Manager'],
    tenants: ['tastelab']
  },
  children: [{
    path: '/deliverytype',
    name: _i18n.default.t('route.DeliveryTypes') + ' List',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deliverytype/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.DeliveryTypes'),
      icon: 'delivery',
      tenants: ['tastelab']
    }
  }, {
    path: '/deliverytype/edit/:id',
    name: _i18n.default.t('route.DeliveryTypes') + ' Edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deliverytype/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.EditDeliveryTypes'),
      icon: 'table',
      tenants: ['tastelab']
    },
    hidden: true
  }, {
    path: '/deliverytype/add',
    name: _i18n.default.t('route.DeliveryTypes') + ' Add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deliverytype/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.AddDeliveryTypes'),
      icon: 'table',
      tenants: ['tastelab']
    },
    hidden: true
  }]
}, // pos
{
  path: '/pos',
  redirect: 'noRedirect',
  component: _layout.default,
  name: _i18n.default.t('route.POS'),
  meta: {
    title: _i18n.default.t('route.POS'),
    icon: 'meal',
    roles: ['Admin', 'Operation Manager', 'Store manager'],
    tenants: ['tastelab']
  },
  withStore: true,
  children: [{
    path: '/pos/modifier/home',
    name: _i18n.default.t('route.Modifier'),
    component: _empty.default,
    redirect: '/pos/modifier',
    hidden: true,
    meta: {
      title: _i18n.default.t('route.Modifier'),
      icon: 'admin',
      tenants: ['tastelab']
    },
    children: [{
      path: '/pos/modifier',
      name: _i18n.default.t('route.List') + ' List',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/modifier'));
        });
      },
      meta: {
        title: _i18n.default.t('route.List'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/modifier/add',
      name: _i18n.default.t('Modifier.Add') + ' Add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/modifier-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('Modifier.Add'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/modifier/edit/:id',
      name: _i18n.default.t('Modifier.Edit'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/modifier-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('Modifier.Edit'),
        tenants: ['tastelab']
      },
      hidden: true
    }]
  }, {
    path: '/pos/item/home',
    name: _i18n.default.t('route.Item'),
    component: _empty.default,
    meta: {
      title: _i18n.default.t('route.Item'),
      icon: 'admin',
      tenants: ['tastelab']
    },
    redirect: '/pos/item',
    children: [{
      path: '/pos/item',
      name: _i18n.default.t('route.List'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/item'));
        });
      },
      meta: {
        title: _i18n.default.t('route.List'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/item/add',
      name: _i18n.default.t('Item.Add'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/item-edit'));
        });
      },
      hidden: true,
      meta: {
        title: _i18n.default.t('Item.Add'),
        tenants: ['tastelab']
      }
    }, {
      path: '/pos/item/edit/:id',
      name: _i18n.default.t('Item.Edit'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/item-edit'));
        });
      },
      hidden: true,
      meta: {
        title: _i18n.default.t('Item.Edit'),
        tenants: ['tastelab']
      }
    }]
  }, {
    path: '/pos/product-type/home',
    name: _i18n.default.t('route.ProductType'),
    component: _empty.default,
    redirect: '/pos/product-type',
    meta: {
      title: _i18n.default.t('route.ProductType'),
      icon: 'admin',
      tenants: ['tastelab']
    },
    children: [{
      path: '/pos/product-type',
      name: _i18n.default.t('route.List') + ' Home',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/product-type'));
        });
      },
      meta: {
        title: _i18n.default.t('route.List'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/product-type/add',
      name: _i18n.default.t('ProductType.Add'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/product-type-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('ProductType.Add'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/product-type/edit/:id',
      name: _i18n.default.t('ProductType.Edit'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/product-type-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('ProductType.Edit'),
        tenants: ['tastelab']
      },
      hidden: true
    }]
  }, {
    path: '/pos/sub-product-type/home',
    name: _i18n.default.t('route.SubProductType'),
    component: _empty.default,
    redirect: '/pos/sub-product-type',
    meta: {
      title: _i18n.default.t('route.SubProductType'),
      icon: 'admin',
      tenants: ['tastelab']
    },
    children: [{
      path: '/pos/sub-product-type',
      name: _i18n.default.t('route.List') + ' Sub Product Type',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/sub-product-type'));
        });
      },
      meta: {
        title: _i18n.default.t('route.List'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/sub-product-type/add',
      name: _i18n.default.t('SubProductType.Add'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/sub-product-type-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('SubProductType.Add'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/sub-product-type/edit/:id',
      name: _i18n.default.t('SubProductType.Edit'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/sub-product-type-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('SubProductType.Edit'),
        tenants: ['tastelab']
      },
      hidden: true
    }]
  }, {
    path: '/pos/categories/home',
    name: _i18n.default.t('route.Category') + ' Home',
    component: _empty.default,
    redirect: '/pos/categories',
    meta: {
      title: _i18n.default.t('route.Category'),
      icon: 'admin',
      tenants: ['tastelab']
    },
    children: [{
      path: '/pos/categories',
      name: _i18n.default.t('route.List') + ' Category List',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/category'));
        });
      },
      meta: {
        title: _i18n.default.t('route.List'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/category/add',
      name: _i18n.default.t('route.Category'),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/category-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.Category'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/category/edit/:id',
      name: _i18n.default.t('route.Category') + ' Edit',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/category-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('route.Category'),
        tenants: ['tastelab']
      },
      hidden: true
    }]
  }, {
    path: '/pos/kitchen/home',
    name: 'POSKitchen',
    component: _empty.default,
    redirect: '/pos/kitchen',
    meta: {
      title: _i18n.default.t('route.Kitchen'),
      icon: 'admin',
      tenants: ['tastelab']
    },
    children: [{
      path: '/pos/kitchen',
      name: 'KitchenList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/kitchen'));
        });
      },
      meta: {
        title: _i18n.default.t('route.List'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/kitchen/add',
      name: 'KitchenAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/kitchen-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('Supplier.Add'),
        tenants: ['tastelab']
      },
      hidden: true
    }, {
      path: '/pos/kitchen/edit/:id',
      name: 'KitchenEdit',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/pos/kitchen-edit'));
        });
      },
      meta: {
        title: _i18n.default.t('Supplier.Edit'),
        tenants: ['tastelab']
      },
      hidden: true
    }]
  }]
}, // CRM
{
  path: '/crm',
  component: _layout.default,
  name: 'CRM',
  meta: {
    title: _i18n.default.t('route.CRM'),
    icon: 'peoples',
    roles: ['Admin']
  },
  redirect: 'noRedirect',
  children: [// moomoo members
  {
    path: '/moomoomembers',
    name: _i18n.default.t('route.MOOMOOMembers'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/moomoomembers/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.MOOMOOMembers'),
      icon: 'peoples',
      tenants: ['tastelab']
    }
  }, {
    path: '/moomoomembers/show/:id',
    name: _i18n.default.t('route.ShowMOOMOOMember'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/moomoomembers/show'));
      });
    },
    meta: {
      title: _i18n.default.t('route.ShowMOOMOOMember'),
      icon: 'education',
      tenants: ['tastelab']
    },
    hidden: true
  }, {
    path: '/moomoomembers/point/:id',
    name: 'ShowMOOMOOMemberPoint',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/moomoomembers/point'));
      });
    },
    meta: {
      title: _i18n.default.t('route.ShowMOOMOOMember'),
      icon: 'education',
      tenants: ['tastelab']
    },
    hidden: true
  },
  /*
  {
    path: '/moomoomembers/edit/:id',
    name: i18n.t('route.EditMOOMOOMember'),
    component: () => import('@/views/moomoomembers/edit'),
    meta: {
      title: i18n.t('route.EditMOOMOOMember'),
      icon: 'education'
    },
    hidden: true
  },
  {
    path: '/moomoomembers/add',
    name: i18n.t('route.AddMOOMOOMember'),
    component: () => import('@/views/moomoomembers/edit'),
    meta: {
      title: i18n.t('route.AddMOOMOOMember'),
      icon: 'education'
    },
    hidden: true
  }
  */
  // message
  {
    path: '/message',
    name: _i18n.default.t('route.Message'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/messages/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Message'),
      icon: 'message'
    }
  }, {
    path: '/messages/edit/:id',
    name: _i18n.default.t('route.EditMessage') + ' Edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/messages/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.EditMessage'),
      icon: 'message'
    },
    hidden: true
  }, {
    path: '/messages/add',
    name: _i18n.default.t('route.AddMessage') + ' Add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/messages/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.AddMessage'),
      icon: 'message'
    },
    hidden: true
  }]
}, // cash expense
{
  path: '/cashExpense',
  redirect: 'noRedirect',
  component: _layout.default,
  name: 'Cash Expense',
  meta: {
    title: _i18n.default.t('route.CashExpense'),
    icon: 'money',
    roles: ['Admin', 'Operation Manager', 'Store Manager', 'Floor Manager']
  },
  children: [{
    path: '/cash-expense',
    name: _i18n.default.t('route.CashExpense'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cashExpense/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.CashExpenseList'),
      icon: 'claim'
    }
  }, {
    path: '/cash-expense/edit/:id',
    name: _i18n.default.t('route.CashExpenseEdit'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cashExpense/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.CashExpenseEdit'),
      icon: 'education'
    },
    hidden: true
  }, {
    path: '/cash-expense/add',
    name: _i18n.default.t('route.CashExpenseAdd'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cashExpense/edit'));
      });
    },
    meta: {
      title: _i18n.default.t('route.CashExpenseAdd'),
      icon: 'education'
    },
    hidden: true
  }, {
    path: '/cash-expense-list',
    name: 'Cash Expense List',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cashExpense/list'));
      });
    },
    meta: {
      title: _i18n.default.t('route.CashExpenseReport'),
      icon: 'claim'
    }
  }, {
    path: '/cash-expense/:category/:date_range',
    name: 'Cash Expense Category',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cashExpense/index'));
      });
    },
    meta: {
      title: _i18n.default.t('route.CashExpenseList'),
      icon: 'claim'
    },
    hidden: true
  }]
}, // Leaves
{
  path: '/leaves',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'Leave Type',
  meta: {
    title: 'Leave',
    icon: 'chart',
    roles: ['Admin', 'Operation Manager']
  },
  hidden: true,
  children: [{
    path: '/leaves/summary',
    name: 'Leaves Summary',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/leaves/summary'));
      });
    },
    meta: {
      title: 'Leave Summary',
      icon: 'chart',
      roles: ['Admin', 'Operation Manager']
    }
  }, {
    path: '/leaves/summary/:id',
    name: 'Leaves History',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/leaves/history'));
      });
    },
    meta: {
      title: 'Leave Apply Record',
      icon: 'chart'
    },
    hidden: true
  }, {
    path: '/leaves/apply/add',
    name: 'Leave App',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/leaves/apply'));
      });
    },
    meta: {
      title: 'Leave App',
      // i18n.t('route.LabourCost'),
      icon: 'chart'
    },
    hidden: true
  }, {
    path: '/leaves/apply/edit/:id',
    name: 'Edit Leave App',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/leaves/apply'));
      });
    },
    meta: {
      title: 'Edit Leave App',
      // i18n.t('route.LabourCost'),
      icon: 'chart'
    },
    hidden: true
  }]
}, // Reports
{
  path: '/reports',
  component: _layout.default,
  redirect: 'noRedirect',
  name: _i18n.default.t('route.Report'),
  meta: {
    title: _i18n.default.t('route.Report'),
    icon: 'chart',
    roles: ['Admin', 'Operation Manager']
  },
  children: [{
    path: '/reports/sales-vs-labour-cost',
    name: 'sales-vs-labour-cost',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reports/sales-vs-labour-cost'));
      });
    },
    meta: {
      title: _i18n.default.t('route.LabourCost'),
      icon: 'chart'
    }
  }, {
    path: '/reports/staff-attendance',
    name: 'staff-attendance',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reports/staff-attendance'));
      });
    },
    meta: {
      title: _i18n.default.t('route.StaffAttendance'),
      icon: 'chart'
    }
  }, {
    path: '/reports/productivity',
    name: 'productivity',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reports/productivity'));
      });
    },
    meta: {
      title: _i18n.default.t('route.Productivity'),
      icon: 'chart'
    }
  }, {
    path: '/reports/cogs',
    name: 'cogs',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reports/cogs'));
      });
    },
    meta: {
      title: _i18n.default.t('route.COGS'),
      icon: 'chart'
    }
  }]
},
/* {
  path: '/',
  redirect: '/dashboard', // this will be replaced in permission.js
  component: Layout,
  name: 'Default Route'
}, */
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;