"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _message = require("@/api/message");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true
      },
      total: 0
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _message.getList)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.meta.total;
      });
    },
    editMessage: function editMessage(id) {
      this.$router.push("/messages/edit/".concat(id));
    },
    addMessage: function addMessage() {
      this.$router.push("/messages/add");
    },
    pushMessage: function pushMessage(id) {
      var _this2 = this;

      _elementUi.MessageBox.confirm('Confirm to push message to devices?', 'Confirm', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _message.pushMessage)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Push is sent',
            type: 'success',
            duration: 5 * 1000
          });

          _this2.fetchData();
        });
      });
    },
    deleteMessage: function deleteMessage(id) {
      var _this3 = this;

      _elementUi.MessageBox.confirm('Confirm to delete record?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _message.deleteMessage)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Record deleted',
            type: 'success',
            duration: 5 * 1000
          });

          _this3.fetchData();
        });
      });
    }
  }
};
exports.default = _default;