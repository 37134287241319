var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c("el-form", { attrs: { inline: true } }, [
                _c(
                  "div",
                  [
                    _vm.user.roles.find(function(role) {
                      return ["Admin", "Store Manager"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          { attrs: { type: "primary", icon: "el-icon-edit" } },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/orders/add" } },
                              [_vm._v(_vm._s(_vm.translation.AddOrder))]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.fetchData($event)
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.fetchData },
                          model: {
                            value: _vm.order_status,
                            callback: function($$v) {
                              _vm.order_status = $$v
                            },
                            expression: "order_status"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "- All Status -", value: "" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Pending", value: "pending" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Confirmed", value: "confirmed" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Completed", value: "completed" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Picked Up", value: "picked_up" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Rejected", value: "rejected" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Store" },
                          on: { change: _vm.fetchData },
                          model: {
                            value: _vm.store_id,
                            callback: function($$v) {
                              _vm.store_id = $$v
                            },
                            expression: "store_id"
                          }
                        },
                        [
                          _c("el-option", {
                            key: _vm.store_id + "_default",
                            attrs: { label: "- Select a Store -", value: "" }
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.stores, function(store) {
                            return _c("el-option", {
                              key: store.name,
                              attrs: { label: store.name, value: store.uuid }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": " - ",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.defaultDateRange,
                          callback: function($$v) {
                            _vm.defaultDateRange = $$v
                          },
                          expression: "defaultDateRange"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "ID / Tel" },
                        model: {
                          value: _vm.listQuery.search,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "search", $$v)
                          },
                          expression: "listQuery.search"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default", icon: "el-icon-search" },
                          on: { click: _vm.fetchData }
                        },
                        [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: { disabled: scope.row.deletedAt != "" } },
                      [_vm._v(_vm._s(scope.row.display_id))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.translation.Store },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: { disabled: scope.row.deletedAt != "" } },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.store != null ? scope.row.store.name : ""
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.translation.Type },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: { disabled: scope.row.deletedAt != "" } },
                      [_vm._v(_vm._s(scope.row.type))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.translation.Status },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: { disabled: scope.row.deletedAt != "" } },
                      [_vm._v(_vm._s(scope.row.status))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.translation.Price },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: { disabled: scope.row.deletedAt != "" } },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.price != "" ? "$" + scope.row.price : ""
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.translation.Date, width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: { disabled: scope.row.deletedAt != "" } },
                      [_vm._v(_vm._s(scope.row.createdAt))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.translation.Tel },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: { disabled: scope.row.deletedAt != "" } },
                      [_vm._v(_vm._s(scope.row.contactTel))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.translation.Action, width: "330" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-start",
                          "flex-wrap": "wrap"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            scope.row.status == "pending" &&
                            scope.row.deletedAt == ""
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "90px",
                                      margin: "5px"
                                    },
                                    attrs: { type: "primary", size: "small" }
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/orders/edit/" + scope.row.uuid
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.translation.Edit))]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status != "pending" &&
                            scope.row.deletedAt == ""
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "90px",
                                      margin: "5px"
                                    },
                                    attrs: { type: "success", size: "small" }
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to:
                                            "/orders/summary/" + scope.row.uuid
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.translation.Summary))]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.deletedAt == ""
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "90px",
                                      margin: "5px"
                                    },
                                    attrs: { size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.downloadOrder(
                                          scope.row.uuid,
                                          scope.row.uuid
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.translation.Download) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status == "pending" &&
                            scope.row.deletedAt == ""
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "90px",
                                      margin: "5px"
                                    },
                                    attrs: { type: "danger", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onRejectOrder(scope.row.uuid)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.translation.Reject) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status != "pending" &&
                            scope.row.deletedAt == "" &&
                            _vm.user.roles.find(function(role) {
                              return ["Admin"].includes(role.name)
                            })
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "90px",
                                      margin: "5px"
                                    },
                                    attrs: { type: "danger", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteOrder(scope.row.uuid)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.translation.Delete))]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }