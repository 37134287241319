"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("/home/node/app/node_modules/core-js/modules/es6.array.iterator.js");

require("/home/node/app/node_modules/core-js/modules/es6.promise.js");

require("/home/node/app/node_modules/core-js/modules/es6.object.assign.js");

require("/home/node/app/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

var _i18n = require("@/i18n");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _i18n2 = _interopRequireDefault(require("./i18n"));

require("@/icons");

require("@/permission");

// A modern alternative to CSS resets
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n
// global css
// icon
// permission control

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
      mockXHR = _require.mockXHR;

  mockXHR();
} // set ElementUI lang to EN


_vue.default.use(_elementUi.default, {
  locale: _i18n.locale
}); // 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)


_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _i18n2.default,
  render: function render(h) {
    return h(_App.default);
  }
});