"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDuties = getDuties;
exports.getDuty = getDuty;
exports.createDuty = createDuty;
exports.editDuty = editDuty;
exports.deleteDuty = deleteDuty;
exports.exportDutiesExcel = exportDutiesExcel;
exports.exportDutiesPDF = exportDutiesPDF;
exports.exportDutiesSalaryBreakdown = exportDutiesSalaryBreakdown;
exports.exportDutiesSalarySummary = exportDutiesSalarySummary;
exports.bulkUpdateRosterConfirmation = bulkUpdateRosterConfirmation;
exports.bulkDuplicateDuty = bulkDuplicateDuty;
exports.bulkDeleteDuty = bulkDeleteDuty;
exports.possibleDuties = possibleDuties;
exports.personalSummary = personalSummary;

var _request = _interopRequireDefault(require("@/utils/request"));

function getDuties(params) {
  return (0, _request.default)({
    url: "/api/v1/duties",
    method: 'get',
    params: params
  });
}

function getDuty(id) {
  return (0, _request.default)({
    url: "/api/v1/duties/".concat(id),
    method: 'get'
  });
}

function createDuty(data) {
  return (0, _request.default)({
    url: "/api/v1/duties",
    data: data,
    method: 'post'
  });
}

function editDuty(data) {
  return (0, _request.default)({
    url: "/api/v1/duties/".concat(data.id),
    data: data,
    method: 'put'
  });
}

function deleteDuty(id) {
  return (0, _request.default)({
    url: "/api/v1/duties/".concat(id),
    method: 'delete'
  });
}

function exportDutiesExcel(params) {
  return (0, _request.default)({
    url: "/api/v1/duties",
    method: 'get',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob',
    params: params
  });
}

function exportDutiesPDF(params) {
  return (0, _request.default)({
    url: "/api/v1/duties",
    method: 'get',
    headers: {
      'Accept': 'application/pdf'
    },
    responseType: 'blob',
    params: params
  });
}

function exportDutiesSalaryBreakdown(params) {
  return (0, _request.default)({
    url: "/api/v1/duties/salary-breakdown",
    method: 'get',
    responseType: 'blob',
    params: params
  });
}

function exportDutiesSalarySummary(params) {
  return (0, _request.default)({
    url: "/api/v1/duties/salary-summary",
    method: 'get',
    responseType: 'blob',
    params: params
  });
}

function bulkUpdateRosterConfirmation(data) {
  return (0, _request.default)({
    url: "/api/v1/roster-confirmations/toggle",
    method: 'post',
    data: data
  });
}

function bulkDuplicateDuty(data) {
  return (0, _request.default)({
    url: "/api/v1/duties/duplicate",
    method: 'post',
    data: data
  });
}

function bulkDeleteDuty(data) {
  return (0, _request.default)({
    url: "/api/v1/duties/clearAll",
    method: 'post',
    data: data
  });
}

function possibleDuties(params) {
  return (0, _request.default)({
    url: "/api/v1/duties/current",
    method: 'get',
    params: params
  });
}

function personalSummary(params) {
  return (0, _request.default)({
    url: "/api/v1/duties/personal-summary",
    method: 'get',
    responseType: 'blob',
    params: params
  });
}