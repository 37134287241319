"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _moomoomember = require("@/api/moomoomember");

var _elementUi = require("element-ui");

var _vueQrReader = _interopRequireDefault(require("vue-qr-reader/dist/lib/vue-qr-reader.umd"));

var _errorParse = require("@/utils/error-parse");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    VueQrReader: _vueQrReader.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0,
      showQRScan: false,
      QRScannerAbility: true
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'user'])),
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _moomoomember.getMOOMOOMembers)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.meta.total;
      });
    },
    getMOOMOOMember: function getMOOMOOMember(id) {
      this.$router.push("/moomoomembers/show/".concat(id));
    },
    editPasskitPoint: function editPasskitPoint(id) {
      this.$router.push("/moomoomembers/point/".concat(id));
    },
    setActive: function setActive(id) {
      var _this2 = this;

      _elementUi.MessageBox.confirm('Confirm to Activate Moo Moo Member?', 'Confirm', {
        confirmButtonText: 'Activate',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _moomoomember.activateMOOMOOMember)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Moo Moo Member Activated',
            type: 'success',
            duration: 5 * 1000
          });

          _this2.fetchData();
        });
      });
    },
    setNotActive: function setNotActive(id) {
      var _this3 = this;

      _elementUi.MessageBox.confirm('Confirm to Deactivate Moo Moo Member?', 'Confirm', {
        confirmButtonText: 'Deactivate',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _moomoomember.deactivateMOOMOOMember)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Moo Moo Member Deactivated',
            type: 'success',
            duration: 5 * 1000
          });

          _this3.fetchData();
        });
      });
    },
    deleteMOOMOOMember: function deleteMOOMOOMember(id) {
      var _this4 = this;

      _elementUi.MessageBox.confirm('Confirm to delete Moo Moo Member?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _moomoomember.deleteMOOMOOMember)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Moo Moo Member deleted',
            type: 'success',
            duration: 5 * 1000
          });

          _this4.fetchData();
        });
      });
    },
    ShowQRScanner: function ShowQRScanner() {
      this.showQRScan = true;
    },
    codeArrived: function codeArrived(decodedString) {
      var _this5 = this;

      console.log(decodedString);
      var url = decodedString.split('/', 4).pop();

      if (url) {
        this.showQRScan = false;
        this.listLoading = true;
        (0, _moomoomember.getMOOMOOMembers)({
          passkit: url,
          limit: 1
        }).then(function (response) {
          _this5.list = response.data;

          if (Array.isArray(response.data) && response.data.length) {
            _this5.$router.push("/moomoomembers/point/".concat(response.data[0].id));
          } else {
            (0, _elementUi.Message)({
              message: 'Scan fail',
              type: 'error',
              duration: 3 * 1000
            });
          }
        }).catch(function (err) {
          (0, _elementUi.Message)({
            message: (0, _errorParse.parseError)(err) || 'Scan fail, code not found',
            type: 'error',
            duration: 5 * 1000
          });
          console.log(err);
        }).finally(function () {
          _this5.listLoading = false;
        });
      }
    },
    errorCaptured: function errorCaptured(error) {
      this.QRScannerAbility = false;
      console.error(error);
    }
  }
};
exports.default = _default;