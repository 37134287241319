"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getGoodReceivedNote = getGoodReceivedNote;
exports.getSummary = getSummary;
exports.createGoodReceivedNote = createGoodReceivedNote;
exports.editGoodReceivedNote = editGoodReceivedNote;
exports.deleteGoodReceivedNote = deleteGoodReceivedNote;
exports.confirmGoodReceivedNote = confirmGoodReceivedNote;
exports.completeGoodReceivedNote = completeGoodReceivedNote;
exports.downloadGoodReceivedNote = downloadGoodReceivedNote;
exports.downloadGoodReceivedNoteTransitNote = downloadGoodReceivedNoteTransitNote;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes',
    method: 'get',
    params: params
  });
}

function getGoodReceivedNote(id) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + id,
    method: 'get'
  });
}

function getSummary(params) {
  return (0, _request.default)({
    url: '/api/v1/reports/good-received-note',
    method: 'get',
    params: params
  });
}

function createGoodReceivedNote(data) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes',
    data: data,
    method: 'post'
  });
}

function editGoodReceivedNote(data) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteGoodReceivedNote(id) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + id,
    method: 'delete'
  });
}

function confirmGoodReceivedNote(id) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + id + '/confirm',
    method: 'post'
  });
}

function completeGoodReceivedNote(id) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + id + '/complete',
    method: 'post'
  });
}

function downloadGoodReceivedNote(id) {
  return (0, _request.default)({
    url: "/api/v1/export-data/good-received-notes/".concat(id),
    method: 'GET',
    responseType: 'blob'
  });
}

function downloadGoodReceivedNoteTransitNote(id) {
  return (0, _request.default)({
    url: "/api/v1/export-data/good-received-notes/".concat(id, "/transit-note"),
    method: 'GET',
    responseType: 'blob'
  });
}