"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDeliveryTypes = getDeliveryTypes;
exports.getDeliveryType = getDeliveryType;
exports.createDeliveryType = createDeliveryType;
exports.editDeliveryType = editDeliveryType;
exports.deleteDeliveryType = deleteDeliveryType;

var _request = _interopRequireDefault(require("@/utils/request"));

function getDeliveryTypes(params) {
  return (0, _request.default)({
    url: '/api/v1/delivery-types',
    method: 'get',
    params: params
  });
}

function getDeliveryType(id) {
  return (0, _request.default)({
    url: '/api/v1/delivery-types/' + id,
    method: 'get'
  });
}

function createDeliveryType(data) {
  return (0, _request.default)({
    url: '/api/v1/delivery-types',
    data: data,
    method: 'post'
  });
}

function editDeliveryType(data) {
  return (0, _request.default)({
    url: '/api/v1/delivery-types/' + data.id,
    data: data,
    method: 'put'
  });
}

function deleteDeliveryType(id) {
  return (0, _request.default)({
    url: '/api/v1/delivery-types/' + id,
    method: 'delete'
  });
}