var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "d-flex" },
      _vm._l(_vm.leaves, function(leave, index) {
        return _c(
          "div",
          { key: leave.uuid, class: "leave noselect style-" + (index % 4) },
          [
            _c(
              "div",
              {
                class: "grid " + (leave.selected ? "selected" : ""),
                on: {
                  click: function($event) {
                    return _vm.selectLeave(leave)
                  }
                }
              },
              [
                _c("div", { staticClass: "text-center text-md" }, [
                  _vm._v(_vm._s(leave.name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-center text-sm" }, [
                  _vm._v(
                    _vm._s(
                      leave.description != ""
                        ? "(" + leave.description + ")"
                        : ""
                    )
                  )
                ])
              ]
            )
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-md d-flex align-items-center",
        staticStyle: { "margin-top": "10px", color: "#555" }
      },
      [
        _vm._v("\n    請"),
        _c("span", { staticClass: "style-hint grid noselect" }, [
          _vm._v("選擇")
        ]),
        _vm._v("可以申請的假期"),
        _c(
          "span",
          { staticClass: "grid submit noselect", on: { click: _vm.submit } },
          [_vm._v("提交")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }