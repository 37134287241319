"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _leave = require("@/api/leave");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd'
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  filters: {},
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)([// 'name',
  'user'])),
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _leave.getLeaves)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.meta.total;
        console.log(response);
      });
    },
    addLeave: function addLeave() {
      this.$router.push("/leaves/add");
    },
    editLeave: function editLeave(id) {
      this.$router.push("/leaves/edit/".concat(id));
    },
    leaveForStaff: function leaveForStaff(id) {
      this.$router.push("/leaves/staff/".concat(id));
    },
    deleteleave: function deleteleave(id) {
      var _this2 = this;

      _elementUi.MessageBox.confirm('Confirm to delete Leave?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _leave.deleteLeave)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Leave deleted',
            type: 'success',
            duration: 5 * 1000
          });

          _this2.fetchData();
        });
      });
    }
  }
};
exports.default = _default;