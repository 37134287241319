var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("label", [_vm._v("From Date: ")]),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: {
          type: "datetime",
          placeholder: "Pick a day",
          clearable: false
        },
        model: {
          value: _vm.listQuery.fromTime,
          callback: function($$v) {
            _vm.$set(_vm.listQuery, "fromTime", $$v)
          },
          expression: "listQuery.fromTime"
        }
      }),
      _vm._v(" "),
      _c("label", [_vm._v("To Date: ")]),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: {
          type: "datetime",
          placeholder: "Pick a day",
          clearable: false
        },
        model: {
          value: _vm.listQuery.toTime,
          callback: function($$v) {
            _vm.$set(_vm.listQuery, "toTime", $$v)
          },
          expression: "listQuery.toTime"
        }
      }),
      _vm._v(" "),
      _c("label", [_vm._v("User: ")]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          attrs: { placeholder: "Users", clearable: "" },
          model: {
            value: _vm.listQuery.userId,
            callback: function($$v) {
              _vm.$set(_vm.listQuery, "userId", $$v)
            },
            expression: "listQuery.userId"
          }
        },
        _vm._l(_vm.users, function(user) {
          return _c("el-option", {
            key: user.name,
            attrs: { label: user.name, value: user.uuid }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("label", [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Store")) + ": ")]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          attrs: {
            placeholder: _vm.$t("PurchaseRequests.Stores"),
            clearable: ""
          },
          model: {
            value: _vm.listQuery.storeId,
            callback: function($$v) {
              _vm.$set(_vm.listQuery, "storeId", $$v)
            },
            expression: "listQuery.storeId"
          }
        },
        _vm._l(_vm.stores, function(store) {
          return _c("el-option", {
            key: store.name,
            attrs: { label: store.name, value: store.uuid }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("label", [_vm._v("Group By: ")]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          model: {
            value: _vm.listQuery.groupBy,
            callback: function($$v) {
              _vm.$set(_vm.listQuery, "groupBy", $$v)
            },
            expression: "listQuery.groupBy"
          }
        },
        _vm._l(_vm.groupByOptions, function(option) {
          return _c("el-option", {
            key: option.name,
            attrs: { label: option.name, value: option.key }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
        [_vm._v("Filter")]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "summary-method": _vm.getSummaries,
            "show-summary": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "Time" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.time) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "User" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.user ? scope.row.user.name : null) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.Store") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.store ? scope.row.store.name : null) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Late Time" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.lateTime
                            ? _vm.humanizeDuration(scope.row.lateTime)
                            : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Late Count" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.lateCount
                            ? scope.row.lateCount.toFixed(0)
                            : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "OT Time" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.oTTime
                            ? _vm.humanizeDuration(scope.row.oTTime)
                            : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "OT Count" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.oTCount
                            ? scope.row.oTCount.toFixed(0)
                            : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }