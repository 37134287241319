var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.warehouseArea, "label-width": "auto" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.warehouseArea.id,
                  callback: function($$v) {
                    _vm.$set(_vm.warehouseArea, "id", $$v)
                  },
                  expression: "warehouseArea.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseArea.Warehouse"),
                prop: "warehouseId",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("WarehouseArea.RequiredWarehouseMessage")
                  }
                ]
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Warehouse", disabled: true },
                  model: {
                    value: _vm.warehouseArea.warehouseId,
                    callback: function($$v) {
                      _vm.$set(_vm.warehouseArea, "warehouseId", $$v)
                    },
                    expression: "warehouseArea.warehouseId"
                  }
                },
                _vm._l(_vm.warehouses, function(warehouse) {
                  return _c("el-option", {
                    key: warehouse.name,
                    attrs: { label: warehouse.name, value: warehouse.uuid }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseArea.Name"),
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("WarehouseArea.RequiredNameMessage")
                  }
                ]
              }
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.warehouseArea.name,
                  callback: function($$v) {
                    _vm.$set(_vm.warehouseArea, "name", $$v)
                  },
                  expression: "warehouseArea.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v(_vm._s(_vm.$t("Order.Cancel")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }