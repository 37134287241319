"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSuppliers = getSuppliers;
exports.getSupplier = getSupplier;
exports.createSupplier = createSupplier;
exports.editSupplier = editSupplier;
exports.deleteSupplier = deleteSupplier;

var _request = _interopRequireDefault(require("@/utils/request"));

function getSuppliers(params) {
  return (0, _request.default)({
    url: '/api/v1/suppliers',
    method: 'get',
    params: params
  });
}

function getSupplier(id) {
  return (0, _request.default)({
    url: '/api/v1/suppliers/' + id,
    method: 'get'
  });
}

function createSupplier(data) {
  return (0, _request.default)({
    url: '/api/v1/suppliers',
    data: data,
    method: 'post'
  });
}

function editSupplier(data) {
  return (0, _request.default)({
    url: '/api/v1/suppliers/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteSupplier(id) {
  return (0, _request.default)({
    url: '/api/v1/suppliers/' + id,
    method: 'delete'
  });
}