var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c("label", [_vm._v("Filter Date: ")]),
          _vm._v(" "),
          _c("el-date-picker", {
            staticStyle: { "margin-bottom": "10px" },
            attrs: {
              type: "daterange",
              "range-separator": " - ",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "value-format": "yyyy-MM-dd"
            },
            model: {
              value: _vm.dateRange,
              callback: function($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange"
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("label", [_vm._v("User: ")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: {
                placeholder: "Users",
                clearable: "",
                filterable: "",
                "filter-method": _vm.userFilter
              },
              model: {
                value: _vm.listQuery.userId,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "userId", $$v)
                },
                expression: "listQuery.userId"
              }
            },
            _vm._l(_vm.userOptions, function(user) {
              return _c("el-option", {
                key: user.name,
                attrs: { label: user.name, value: user.uuid }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("label", [
            _vm._v(_vm._s(_vm.$t("PurchaseRequests.Store")) + ": ")
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("PurchaseRequests.Stores"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.storeId,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "storeId", $$v)
                },
                expression: "listQuery.storeId"
              }
            },
            _vm._l(_vm.stores, function(store) {
              return _c("el-option", {
                key: store.name,
                attrs: { label: store.name, value: store.uuid }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "el-form-item__content" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { "true-label": "true" },
                  model: {
                    value: _vm.listQuery.pendingAdjustment,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "pendingAdjustment", $$v)
                    },
                    expression: "listQuery.pendingAdjustment"
                  }
                },
                [_vm._v("Pending Approval")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.query()
                    }
                  }
                },
                [_vm._v("Filter")]
              ),
              _vm._v(" "),
              _vm.user.roles.find(function(role) {
                return ["Admin"].includes(role.name)
              })
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-edit" },
                      on: { click: _vm.addAttendance }
                    },
                    [_vm._v("Add Attendance")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "User" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.user ? scope.row.user.name : "") +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.Store") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.store ? scope.row.store.name : "") +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.Action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.action ? scope.row.action : "") +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Duty" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.duty
                            ? "From: " + scope.row.duty.fromTime
                            : ""
                        ) +
                        "\n        "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.duty ? "To: " + scope.row.duty.toTime : ""
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Attendance Time" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.createdAt ? scope.row.createdAt : "") +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Adjustment" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: { "pending-adjustment": scope.row.adjustment } },
                      [
                        _vm._v(
                          "\n          Default: " +
                            _vm._s(
                              scope.row.defaultEffectiveTime
                                ? scope.row.defaultEffectiveTime
                                : ""
                            ) +
                            "\n          "
                        ),
                        _c("br")
                      ]
                    ),
                    _vm._v(" "),
                    scope.row.adjustment
                      ? _c("span", [
                          _vm._v(
                            "\n          Adjusted:\n          " +
                              _vm._s(
                                scope.row.adjustment
                                  ? scope.row.adjustment.adjustedTime
                                  : ""
                              ) +
                              "\n          " +
                              _vm._s(
                                scope.row.adjustment &&
                                  scope.row.adjustment.remark
                                  ? " ( " + scope.row.adjustment.remark + " ) "
                                  : ""
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                scope.row.adjustment &&
                                  scope.row.adjustment.confirmedBy
                                  ? " Confirmed By: " +
                                      scope.row.adjustment.confirmedBy.name +
                                      (scope.row.adjustment.confirmRemark
                                        ? " ( " +
                                          scope.row.adjustment.confirmRemark +
                                          " )"
                                        : "")
                                  : ""
                              ) +
                              "\n          "
                          ),
                          _c("br")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.pendingAdjustment
                      ? _c("span", { staticClass: "pending-adjustment" }, [
                          _vm._v(
                            "\n          Pending:\n          " +
                              _vm._s(
                                scope.row.pendingAdjustment
                                  ? scope.row.pendingAdjustment.adjustedTime
                                  : ""
                              ) +
                              "\n          " +
                              _vm._s(
                                scope.row.pendingAdjustment &&
                                  scope.row.pendingAdjustment.remark
                                  ? " ( " +
                                      scope.row.pendingAdjustment.remark +
                                      " ) "
                                  : ""
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.user.roles.find(function(role) {
                      return ["Admin"].includes(role.name)
                    }) || scope.row.canDelete
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: scope.row.name === _vm.name
                            },
                            on: {
                              click: function($event) {
                                return _vm.deleteAttendance(scope.row.uuid)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return ["Admin"].includes(role.name)
                    }) || _vm.user.uuid == scope.row.user.uuid
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !!scope.row.possibleAdjustment,
                                expression: "!!scope.row.possibleAdjustment"
                              }
                            ],
                            attrs: { type: "default" },
                            on: {
                              click: function($event) {
                                return _vm.applyAdjustment(
                                  scope.row,
                                  scope.row.possibleAdjustment
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.action === "clock_in" ||
                                  !scope.row.adjustment ||
                                  scope.row.adjustment.status !== "approved"
                                  ? "Apply Adjustment"
                                  : "Request OT Approval"
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return [
                        "Admin",
                        "Operation Manager",
                        "Store Manager"
                      ].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.pendingAdjustment &&
                                  scope.row.pendingAdjustment.canConfirm,
                                expression:
                                  "scope.row.pendingAdjustment && scope.row.pendingAdjustment.canConfirm"
                              }
                            ],
                            attrs: { type: "default" },
                            on: {
                              click: function($event) {
                                return _vm.approveAdjustment(
                                  scope.row.pendingAdjustment.uuid
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.action === "clock_in" ||
                                  !scope.row.adjustment ||
                                  scope.row.adjustment.status !== "approved"
                                  ? "Approve"
                                  : "Approve OT"
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return [
                        "Admin",
                        "Operation Manager",
                        "Store Manager"
                      ].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.pendingAdjustment &&
                                  scope.row.pendingAdjustment.canConfirm,
                                expression:
                                  "scope.row.pendingAdjustment && scope.row.pendingAdjustment.canConfirm"
                              }
                            ],
                            attrs: { type: "default" },
                            on: {
                              click: function($event) {
                                return _vm.rejectAdjustment(
                                  scope.row.pendingAdjustment.uuid
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.action === "clock_in" ||
                                  !scope.row.adjustment ||
                                  scope.row.adjustment.status !== "approved"
                                  ? "Reject"
                                  : "Reject OT"
                              )
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }