"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStores = getStores;
exports.getStore = getStore;
exports.createStore = createStore;
exports.editStore = editStore;
exports.deleteStore = deleteStore;
exports.getStoreSales = getStoreSales;
exports.onlineStore = onlineStore;
exports.offlineStore = offlineStore;

var _request = _interopRequireDefault(require("@/utils/request"));

function getStores(params) {
  return (0, _request.default)({
    url: "/api/v1/stores",
    method: 'get',
    params: params
  });
}

function getStore(id) {
  return (0, _request.default)({
    url: "/api/v1/stores/".concat(id),
    method: 'get'
  });
}

function createStore(data) {
  return (0, _request.default)({
    url: "/api/v1/stores",
    data: data,
    method: 'post'
  });
}

function editStore(data) {
  return (0, _request.default)({
    url: "/api/v1/stores/".concat(data.id),
    data: data,
    method: 'put'
  });
}

function deleteStore(id) {
  return (0, _request.default)({
    url: "/api/v1/stores/".concat(id),
    method: 'delete'
  });
}

function getStoreSales(id, params) {
  return (0, _request.default)({
    url: "/api/v1/stores/".concat(id, "/sales"),
    method: 'get',
    params: params
  });
}

function onlineStore(id) {
  return (0, _request.default)({
    url: "/api/v1/stores/".concat(id, "/online"),
    method: 'post'
  });
}

function offlineStore(id) {
  return (0, _request.default)({
    url: "/api/v1/stores/".concat(id, "/offline"),
    method: 'post'
  });
}