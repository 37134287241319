var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("label", [_vm._v("Initial Week: ")]),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: {
          type: "week",
          "picker-options": { firstDayOfWeek: 7 },
          "range-separator": " - ",
          format: "yyyy 第 WW 周",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "value-format": "yyyy-MM-dd",
          clearable: false
        },
        model: {
          value: _vm.firstWeek,
          callback: function($$v) {
            _vm.firstWeek = $$v
          },
          expression: "firstWeek"
        }
      }),
      _vm._v(" "),
      _c("label", [_vm._v("Compare Week: ")]),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: {
          type: "week",
          "picker-options": { firstDayOfWeek: 7 },
          "range-separator": " - ",
          format: "yyyy 第 WW 周",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "value-format": "yyyy-MM-dd"
        },
        model: {
          value: _vm.secondWeek,
          callback: function($$v) {
            _vm.secondWeek = $$v
          },
          expression: "secondWeek"
        }
      }),
      _vm._v(" "),
      _c("label", [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Store")) + ": ")]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          attrs: { placeholder: "Store" },
          model: {
            value: _vm.listQuery.store_id,
            callback: function($$v) {
              _vm.$set(_vm.listQuery, "store_id", $$v)
            },
            expression: "listQuery.store_id"
          }
        },
        [
          _c("el-option", {
            key: "store_default",
            attrs: { label: "Please Select", value: "" }
          }),
          _vm._v(" "),
          _vm._l(_vm.stores, function(store) {
            return _c("el-option", {
              key: store.name,
              attrs: { label: store.name, value: store.uuid }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
        [_vm._v("Filter")]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            border: "",
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "Outlet", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.store ? scope.row.store.name : null) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Period", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.time) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Sales", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        $" +
                        _vm._s(
                          scope.row.actualSale
                            ? scope.row.actualSale.toFixed(0)
                            : 0
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Man Hour", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.dutyManHr
                            ? parseFloat(
                                scope.row.dutyManHr.toString()
                              ).toFixed(0)
                            : 0
                        ) +
                        " hr(s)\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Payroll" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.dutyCost
                            ? "$" + scope.row.dutyCost.toFixed(2)
                            : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Labour Cost" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.labourCostPercentage
                            ? (scope.row.labourCostPercentage * 100).toFixed(
                                2
                              ) + "%"
                            : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Productivity " },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.actualSale && scope.row.dutyManHr
                            ? "$" +
                                (
                                  scope.row.actualSale / scope.row.dutyManHr
                                ).toFixed(2)
                            : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Week Comparison - Labour Cost" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: scope.row.week_comparison_labour_cost_status },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.week_comparison_labour_cost != ""
                                ? scope.row
                                    .week_comparison_labour_cost_status ==
                                  "green"
                                  ? "(" +
                                    scope.row.week_comparison_labour_cost +
                                    "%)"
                                  : scope.row.week_comparison_labour_cost + "%"
                                : ""
                            ) +
                            "\n          " +
                            _vm._s(
                              scope.row.week_comparison_labour_cost_status ==
                                "red"
                                ? "↑"
                                : scope.row
                                    .week_comparison_labour_cost_status ==
                                  "green"
                                ? "↓"
                                : ""
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Week Comparison - Produtivity" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: scope.row.week_comparison_productivity_status },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.week_comparison_productivity != ""
                                ? scope.row
                                    .week_comparison_productivity_status ==
                                  "red"
                                  ? "(-$" +
                                    Math.abs(
                                      scope.row.week_comparison_productivity
                                    ) +
                                    ")"
                                  : "$" + scope.row.week_comparison_productivity
                                : ""
                            ) +
                            "\n          " +
                            _vm._s(
                              scope.row.week_comparison_productivity_status ==
                                "green"
                                ? "↑"
                                : scope.row
                                    .week_comparison_productivity_status ==
                                  "red"
                                ? "↓"
                                : ""
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }