var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-container" },
    [
      _c(
        "el-form",
        {
          ref: "resetForm",
          staticClass: "reset-form",
          attrs: {
            model: _vm.resetForm,
            rules: _vm.resetRules,
            "auto-complete": "on",
            "label-position": "left"
          }
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title" }, [_vm._v("Reset Password")])
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "token-container", attrs: { prop: "token" } },
            [
              _c("el-input", {
                attrs: {
                  type: "hidden",
                  autocomplete: "off",
                  name: "token",
                  tabindex: "1"
                },
                model: {
                  value: _vm.resetForm.token,
                  callback: function($$v) {
                    _vm.$set(_vm.resetForm, "token", $$v)
                  },
                  expression: "resetForm.token"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "email" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                ref: "email",
                attrs: {
                  placeholder: "Email",
                  name: "email",
                  type: "email",
                  tabindex: "1",
                  "auto-complete": "on"
                },
                model: {
                  value: _vm.resetForm.email,
                  callback: function($$v) {
                    _vm.$set(_vm.resetForm, "email", $$v)
                  },
                  expression: "resetForm.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  autocomplete: "off",
                  name: "password",
                  placeholder: "password",
                  tabindex: "1"
                },
                model: {
                  value: _vm.resetForm.password,
                  callback: function($$v) {
                    _vm.$set(_vm.resetForm, "password", $$v)
                  },
                  expression: "resetForm.password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password_confirmation" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  autocomplete: "off",
                  name: "confirm_password",
                  placeholder: "confirm password",
                  tabindex: "1"
                },
                model: {
                  value: _vm.resetForm.password_confirmation,
                  callback: function($$v) {
                    _vm.$set(_vm.resetForm, "password_confirmation", $$v)
                  },
                  expression: "resetForm.password_confirmation"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "forget-link" },
            [
              _c("router-link", { attrs: { to: "/login" } }, [
                _vm._v("back to Login")
              ]),
              _vm._v(" "),
              _c("br")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "30px" },
              attrs: { loading: _vm.loading, type: "primary" },
              nativeOn: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [_vm._v("Submit")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }