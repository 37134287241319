"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _report = require("@/api/report");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
var _default = {
  /* components: {
    Pagination
  }, */
  data: function data() {
    return {
      uuid: this.$route.params.id,
      list: null,
      listLoading: true,
      listQuery: {
        fromTime: null,
        toTime: null,
        groupBy: 'week',
        page: 1,
        limit: 9999
      },
      groupByOptions: [{
        key: 'daily_session',
        name: 'Session'
      }, {
        key: 'hour',
        name: 'Hour'
      }, {
        key: 'date',
        name: 'Day'
      }, {
        key: 'week',
        name: 'Week'
      }, {
        key: 'month',
        name: 'Month'
      }, {
        key: 'year',
        name: 'Year'
      }],
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'user'])),
  created: function created() {
    var today = new Date();
    this.listQuery['fromTime'] = new Date(today.getFullYear(), today.getMonth(), 1);
    this.listQuery['toTime'] = new Date(today.getFullYear(), today.getMonth() + 1, -1);
    this.listQuery['toTime'].setDate(this.listQuery.toTime.getDate() + 1);
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      var param = {};

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          var current_datetime = this.listQuery[key];
          var formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + ' ' + current_datetime.getHours() + ':' + current_datetime.getMinutes() + ':' + current_datetime.getSeconds();
          param[key] = formatted_date;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      (0, _report.getSaleVSLavourCost)((0, _objectSpread2.default)({}, param)).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.data ? response.data.length : 0;
      });
    },
    getSummaries: function getSummaries() {
      var summary = {};
      var totalActualSale = 0;
      var totalEstimatedSale = 0;
      var totalDutyCost = 0;
      var totalDutyManHr = 0;
      var actualSaleCount = 0;
      var estimatedSaleCount = 0;
      var dutyCostCount = 0;
      var dutyManHrCount = 0;

      if (this.list) {
        this.list.forEach(function (row) {
          if (row.actualSale) {
            totalActualSale += row.actualSale;
            actualSaleCount++;
          }

          if (row.estimatedSale) {
            totalEstimatedSale += row.estimatedSale;
            estimatedSaleCount++;
          }
          /* if (row.dutyCost) {
            totalEstimatedSale += Math.ceil(row.dutyCost / 0.3)
            estimatedSaleCount++
          }*/


          if (row.dutyCost) {
            totalDutyCost += row.dutyCost;
            dutyCostCount++;
          }

          if (row.dutyManHr) {
            totalDutyManHr += row.dutyManHr ? parseFloat(row.dutyManHr.toString()) : 0;
            dutyManHrCount++;
          }
        });
      } // console.log(totalDutyManHr)


      summary[2] = _i18n.default.t('Order.Total') + ': $' + totalEstimatedSale.toFixed(0) + ' | Avg: $' + (estimatedSaleCount ? totalEstimatedSale / estimatedSaleCount : 0).toFixed(0);
      summary[3] = totalActualSale > 0 ? _i18n.default.t('Order.Total') + ': $' + totalActualSale.toFixed(0) + ' | Avg: $' + (actualSaleCount ? totalActualSale / actualSaleCount : 0).toFixed(0) : null; // summary[4] = 'Total: $' + totalEstimatedSale.toFixed(0) + ' | Avg: $' + (estimatedSaleCount ? totalEstimatedSale / estimatedSaleCount : 0).toFixed(0)

      summary[5] = _i18n.default.t('Order.Total') + ': $' + totalDutyCost.toFixed(0) + ' | Avg: $' + (dutyCostCount ? totalDutyCost / dutyCostCount : 0).toFixed(0);
      summary[6] = _i18n.default.t('Order.Total') + ': ' + (totalDutyManHr ? totalDutyManHr.toFixed(0) : 0) + 'hr' + ' | Avg: ' + (dutyManHrCount ? totalDutyManHr / dutyManHrCount : 0).toFixed(0) + 'hr';
      return summary;
    }
  }
};
exports.default = _default;