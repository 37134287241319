var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          attrs: { type: "flex", justify: "space-between" }
        },
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--primary",
                  attrs: { to: "/suppliers/add" }
                },
                [
                  _c("i", { staticClass: "el-icon-edit" }),
                  _vm._v(_vm._s(_vm.$t("Supplier.AddSupplier")))
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              attrs: { inline: true },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.fetchData($event)
                }
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    model: {
                      value: _vm.listQuery.search,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "search", $$v)
                      },
                      expression: "listQuery.search"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", icon: "el-icon-search" },
                      on: { click: _vm.fetchData }
                    },
                    [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Supplier.Name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Supplier.ShortName") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.shortname) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Supplier.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--primary",
                        attrs: { to: "/suppliers/edit/" + scope.row.uuid }
                      },
                      [_vm._v(_vm._s(_vm.$t("Supplier.Edit")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.deleteSupplier(scope.row.uuid)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Supplier.Delete")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }