"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/typeof"));

require("core-js/modules/es6.regexp.match");

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _i18n = _interopRequireDefault(require("@/i18n"));

var _auth = require("@/utils/auth");

// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout

}); // request interceptor


service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // config.headers['X-Token'] = getToken()
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)();
  }

  if (_i18n.default.locale) {
    config.headers['X-localization'] = _i18n.default.locale;
  }

  if (config.responseType === 'blob') {
    config.timeout = 0;
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;

  if (response.headers['warning']) {
    var warning = response.headers['warning'].match(/(?:[^\s"]+|"[^"]*")+/g);

    if (!warning[2]) {
      warning = response.headers['warning'];
    } else {
      warning = JSON.parse(warning[2]);
    }

    if ((0, _typeof2.default)(warning) !== 'object') {
      (0, _elementUi.Message)({
        message: warning,
        type: 'warning',
        duration: 5 * 1000
      });
    }
  } // if the custom code is not 20000, it is judged as an error.
  // if (res.code !== 20000) {
  // Check with status code instead


  if (response.status !== 200 && response.status !== 201) {
    (0, _elementUi.Message)({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    }); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {

    if (response.status === 401) {
      // to re-login
      _elementUi.MessageBox.alert('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-login',
        callback: function callback(action) {
          _store.default.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        }
      });
    }

    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  // Message({
  //   message: message,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  var ret = {};

  if (error.response.data) {
    ret = error.response.data;
  }

  if (error.response.status === 401 && !ret.message) {
    if (error.response.config.responseType === 'blob' && error.response.headers['content-type'] === 'application/json') {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();

        reader.onload = function () {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error.response.data));
        };

        reader.onerror = function () {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    ret.message = 'Please Login';
  }

  if (error.response.status === 403 && !ret.message) {
    if (error.response.config.responseType === 'blob' && error.response.headers['content-type'] === 'application/json') {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();

        reader.onload = function () {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error.response.data));
        };

        reader.onerror = function () {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    ret.message = 'Unauthorized';
  }

  if (error.response.status === 404 && !ret.message) {
    if (error.response.config.responseType === 'blob' && error.response.headers['content-type'] === 'application/json') {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();

        reader.onload = function () {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error.response.data));
        };

        reader.onerror = function () {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    ret.message = 'Not Found';
  }

  if (error.response.headers && error.response.headers['content-type'] === 'application/json') {
    ret.headers = error.response.headers;
    ret.status = error.response.status;
  }

  return Promise.reject(ret);
});
var _default = service;
exports.default = _default;