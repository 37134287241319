"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _warehouse = require("@/api/warehouse");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { MessageBox, Message } from 'element-ui'
// import i18n from '@/i18n'
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true
      },
      total: 0
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _warehouse.getWarehouses)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.meta.total;
      });
    }
    /* ,
    addWarehouse() {
     this.$router.push(`/warehouses/add`)
    },
    deleteWarehouse(id) {
     MessageBox.confirm(i18n.t('Warehouse.ConfirmDeleteMessage'), i18n.t('Warehouse.Confirm'), {
       confirmButtonText: i18n.t('Warehouse.Delete'),
       cancelButtonText: i18n.t('Warehouse.Cancel'),
       type: 'warning'
     }).then(() => {
       deleteWarehouse(id).then(() => {
         Message({
           message: i18n.t('Warehouse.Recorddeleted'),
           type: 'success',
           duration: 5 * 1000
         })
         this.fetchData()
       })
     })
    } */

  }
};
exports.default = _default;