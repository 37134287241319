"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _leave = require("@/api/leave");

var _elementUi = require("element-ui");

var _errorParse = require("@/utils/error-parse");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd'
var _default = {
  filters: {},
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        uuid: this.$route.params.id
      },
      total: 0,
      selectedUser: [],
      selectAll: false,
      leaveData: null,
      leaveName: null
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)([// 'name',
    // 'user'
  ])),
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _leave.getLeave)(this.$route.params.id).then(function (response) {
        _this.leaveData = response.data;
        _this.leaveName = _this.leaveData.name;
        (0, _leave.getLeaveUsers)(_this.listQuery).then(function (response) {
          _this.list = response.data;
          _this.listLoading = false;

          for (var i in _this.list) {
            if (_this.list[i].leave) {
              _this.selectedUser.push(_this.list[i].id);
            }
          } // this.total = response.meta.total

        });
      });
    },
    select: function select() {
      this.selectedUser = [];

      if (!this.selectAll) {
        for (var i in this.list) {
          this.selectedUser.push(this.list[i].id);
        }

        console.log(this.selectedUser);
      }
    },
    onCancel: function onCancel() {
      this.$router.push("/leaves");
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      this.loading = true;
      (0, _leave.editLeaveUsers)(this.leaveData, this.selectedUser).then(function (response) {
        (0, _elementUi.Message)({
          message: 'Record updated.',
          type: 'success',
          duration: 5 * 1000
        });

        _this2.$router.push("/leaves");
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        console.log(err);
      }).finally(function () {
        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;