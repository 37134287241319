"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWarehouseAreaRawMaterials = getWarehouseAreaRawMaterials;
exports.getWarehouseAreaRawMaterial = getWarehouseAreaRawMaterial;
exports.createWarehouseAreaRawMaterial = createWarehouseAreaRawMaterial;
exports.editWarehouseAreaRawMaterial = editWarehouseAreaRawMaterial;
exports.deleteWarehouseAreaRawMaterial = deleteWarehouseAreaRawMaterial;

var _request = _interopRequireDefault(require("@/utils/request"));

function getWarehouseAreaRawMaterials(params) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials',
    method: 'get',
    params: params
  });
}

function getWarehouseAreaRawMaterial(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials/' + id,
    method: 'get'
  });
}

function createWarehouseAreaRawMaterial(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials',
    data: data,
    method: 'post'
  });
}

function editWarehouseAreaRawMaterial(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteWarehouseAreaRawMaterial(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials/' + id,
    method: 'delete'
  });
}