"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWarehouseRecords = getWarehouseRecords;
exports.getInventoryLv = getInventoryLv;
exports.getWarehouseRecord = getWarehouseRecord;
exports.createWarehouseRecord = createWarehouseRecord;
exports.editWarehouseRecord = editWarehouseRecord;
exports.deleteWarehouseRecord = deleteWarehouseRecord;

var _request = _interopRequireDefault(require("@/utils/request"));

function getWarehouseRecords(params) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records',
    method: 'get',
    params: params
  });
}

function getInventoryLv(params) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-inventory-lv',
    method: 'get',
    params: params
  });
}

function getWarehouseRecord(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records/' + id,
    method: 'get'
  });
}

function createWarehouseRecord(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records',
    data: data,
    method: 'post'
  });
}

function editWarehouseRecord(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteWarehouseRecord(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records/' + id,
    method: 'delete'
  });
}