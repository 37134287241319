var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _vm.user.roles.find(function(role) {
            return ["Procurement Manager", "Admin"].includes(role.name)
          })
            ? _c("el-tab-pane", {
                attrs: { label: "Requested", name: "submitted" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: { label: "Processing", name: "confirmed" }
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: { label: "Outstanding", name: "outstanding" }
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: { label: "Completed", name: "completed" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.summary
        ? _c(
            "el-dialog",
            {
              attrs: { title: "Summary", visible: _vm.summaryVisible },
              on: {
                "update:visible": function($event) {
                  _vm.summaryVisible = $event
                }
              }
            },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.options,
                  multiple: true,
                  "custom-label": _vm.customLabel,
                  "track-by": "uuid"
                },
                model: {
                  value: _vm.supplierFilter,
                  callback: function($$v) {
                    _vm.supplierFilter = $$v
                  },
                  expression: "supplierFilter"
                }
              }),
              _vm._v(" "),
              _vm._l(_vm.summary, function(supplier, index) {
                return _c("div", { key: index }, [
                  _vm.supplierFilter.length == 0 ||
                  _vm.filterSupplier(_vm.supplierFilter, supplier.uuid).length >
                    0
                    ? _c(
                        "div",
                        [
                          _c("h3", [
                            _vm._v(
                              _vm._s(supplier.name) +
                                " (" +
                                _vm._s(_vm.$t("Order.Total")) +
                                ": $" +
                                _vm._s(supplier.total.toFixed(2)) +
                                ")"
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: supplier.items }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("PurchaseRequests.Item")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(scope.row.sku) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("PurchaseRequests.Qty")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(scope.row.qty) +
                                              " " +
                                              _vm._s(scope.row.unit) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("PurchaseRequests.UnitPrice")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            "\n              $" +
                                              _vm._s(
                                                scope.row.unitPrice
                                                  ? scope.row.unitPrice.toFixed(
                                                      2
                                                    )
                                                  : "null"
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("PurchaseRequests.SubTotal")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            "\n              $" +
                                              _vm._s(
                                                (
                                                  (scope.row.unitPrice
                                                    ? scope.row.unitPrice.toFixed(
                                                        2
                                                      )
                                                    : 0) * scope.row.qty
                                                ).toFixed(2)
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.summaryVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.generatePO }
                    },
                    [_vm._v(_vm._s(_vm.$t("PurchaseRequests.GeneratePO")))]
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName === "submitted"
        ? _c(
            "div",
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("PurchaseRequests.Stores")) + ": ")
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.listQuery.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "storeId", $$v)
                    },
                    expression: "listQuery.storeId"
                  }
                },
                _vm._l(_vm.stores, function(store) {
                  return _c("el-option", {
                    key: store.name,
                    attrs: { label: store.name, value: store.uuid }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("label", [
                _vm._v(
                  _vm._s(_vm.$t("PurchaseRequests.TargetRestockDate")) + ": "
                )
              ]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "Pick Date",
                  clearable: ""
                },
                model: {
                  value: _vm.listQuery.targetRestockDate,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "targetRestockDate", $$v)
                  },
                  expression: "listQuery.targetRestockDate"
                }
              }),
              _vm._v(" "),
              _c("label", [_vm._v("Supplier")]),
              _vm._v(" "),
              _c("el-autocomplete", {
                staticClass: "inline-input",
                attrs: {
                  "fetch-suggestions": _vm.fetchSupplier,
                  placeholder: "请输入内容",
                  "value-key": "label",
                  clearable: ""
                },
                on: {
                  clear: _vm.clearSupplier,
                  select: _vm.handleSelectSupplier,
                  blur: _vm.checkSupplier
                },
                model: {
                  value: _vm.supplierName,
                  callback: function($$v) {
                    _vm.supplierName = $$v
                  },
                  expression: "supplierName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.query } },
                [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Filter")))]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br")
            ],
            1
          )
        : _c(
            "div",
            [
              _c("label", [_vm._v("Supplier: ")]),
              _vm._v(" "),
              _c("el-autocomplete", {
                staticClass: "inline-input",
                attrs: {
                  "fetch-suggestions": _vm.fetchSupplier,
                  placeholder: "请输入内容",
                  "value-key": "label",
                  clearable: ""
                },
                on: {
                  clear: _vm.clearSupplier,
                  select: _vm.handleSelectSupplier,
                  blur: _vm.checkSupplier
                },
                model: {
                  value: _vm.supplierName,
                  callback: function($$v) {
                    _vm.supplierName = $$v
                  },
                  expression: "supplierName"
                }
              }),
              _vm._v(" "),
              _c("label", [_vm._v("Target Restock: ")]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "Pick Date",
                  clearable: true
                },
                model: {
                  value: _vm.listQuery.targetRestockDate,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "targetRestockDate", $$v)
                  },
                  expression: "listQuery.targetRestockDate"
                }
              }),
              _vm._v(" "),
              _c("label", [_vm._v("PO Date: ")]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": " - ",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.poDateRange,
                  callback: function($$v) {
                    _vm.poDateRange = $$v
                  },
                  expression: "poDateRange"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
                [_vm._v("Filter")]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br")
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _vm.activeName === "drafted"
                    ? _c(
                        "router-link",
                        {
                          staticClass: "el-button el-button--primary",
                          attrs: { to: "/purchase-orders/add" }
                        },
                        [
                          _c("i", { staticClass: "el-icon-edit" }),
                          _vm._v("Add Purchase Order")
                        ]
                      )
                    : _vm.activeName === "submitted"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "warning",
                            icon: "el-icon-copy-document"
                          },
                          on: { click: _vm.previewData }
                        },
                        [_vm._v("Preview Purchase")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.fetchData($event)
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.search,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "search", $$v)
                          },
                          expression: "listQuery.search"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _vm.activeName === "submitted"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "default",
                                icon: "el-icon-search"
                              },
                              on: { click: _vm.query }
                            },
                            [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: {
                                type: "default",
                                icon: "el-icon-search"
                              },
                              on: { click: _vm.fetchData }
                            },
                            [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _vm.activeName === "submitted"
            ? _c(
                "el-table-column",
                {
                  attrs: { align: "center", width: "50" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            -1 !==
                              ["Submitted", "Confirmed"].indexOf(
                                scope.row.status
                              ) &&
                            _vm.filterStatus(scope.row.items, "pending")
                              .length > 0
                              ? _c("el-checkbox", {
                                  model: {
                                    value: scope.row.isEnabled,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "isEnabled", $$v)
                                    },
                                    expression: "scope.row.isEnabled"
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2842665113
                  )
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("el-checkbox", { on: { change: _vm.toggleAllEnable } })
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ID", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.displayId) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.activeName === "submitted"
            ? _c("el-table-column", {
                attrs: { label: _vm.$t("PurchaseRequests.Store") },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(scope.row.store.name) +
                              "\n      "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2107886315
                )
              })
            : _c("el-table-column", {
                attrs: { label: "Supplier" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              scope.row.supplier
                                ? scope.row.supplier.name
                                : "DELETED"
                            ) +
                            "\n      "
                        )
                      ]
                    }
                  }
                ])
              }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Target Restock", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.targetRestockDate) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.activeName !== "submitted"
            ? _c("el-table-column", {
                attrs: { label: "PO Date", width: "130" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(scope.row.poDate) + "\n      "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2278394006
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Status"), width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.status) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.UpdatedAt") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.updatedAt) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions"), "class-name": "action" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      [
                        scope.row.status == "Drafted" ||
                        scope.row.status == "Confirmed" ||
                        scope.row.status == "Outstanding"
                          ? _c(
                              "router-link",
                              {
                                staticClass: "el-button el-button--primary",
                                attrs: {
                                  to: "/purchase-orders/edit/" + scope.row.uuid
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status === "Drafted"
                                      ? _vm.$t("Order.Edit")
                                      : _vm.$t("Order.Show")
                                  )
                                )
                              ]
                            )
                          : _vm.activeName === "submitted"
                          ? _c(
                              "router-link",
                              {
                                staticClass: "el-button el-button--primary",
                                attrs: {
                                  to:
                                    "/purchase-requests/edit/" + scope.row.uuid
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Show")))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "Confirmed" ||
                        scope.row.status == "Outstanding" ||
                        scope.row.status == "Completed"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "default" },
                                on: {
                                  click: function($event) {
                                    return _vm.downloadPurchaseOrder(
                                      scope.row.uuid,
                                      scope.row.displayId
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PurchaseRequests.Download"))
                                )
                              ]
                            )
                          : _vm.activeName === "submitted" &&
                            (scope.row === "drafted" ||
                              _vm.user.roles.find(function(role) {
                                return ["Operation Manager", "Admin"].includes(
                                  role.name
                                )
                              }))
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.rejectPurchaseRequest(
                                      scope.row.uuid
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PurchaseRequests.Reject"))
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeName !== "submitted" &&
                        (_vm.activeName === "drafted" ||
                          (_vm.activeName === "confirmed" &&
                            (!scope.row.goodReceivedNotes ||
                              scope.row.goodReceivedNotes.length == 0)) ||
                          _vm.user.roles.find(function(role) {
                            return ["Operation Manager", "Admin"].includes(
                              role.name
                            )
                          }))
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.deletePurchaseOrder(
                                      scope.row.uuid
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    ["Completed", "Confirmed", "Outstanding"].indexOf(
                      scope.row.status
                    ) !== -1 &&
                    scope.row.goodReceivedNotes &&
                    scope.row.goodReceivedNotes.length
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-dropdown",
                              { attrs: { trigger: "click" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _c(
                                      "el-button",
                                      { attrs: { type: "success" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.goodReceivedNotes[
                                              scope.row.goodReceivedNotes
                                                .length - 1
                                            ].status == "Drafted"
                                              ? "Good Received"
                                              : scope.row.goodReceivedNotes[
                                                  scope.row.goodReceivedNotes
                                                    .length - 1
                                                ].status == "Confirmed"
                                              ? "Good Allocation"
                                              : "Allocation Record"
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    staticClass: "allocation-dropdown",
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown"
                                  },
                                  _vm._l(
                                    scope.row.goodReceivedNotes.filter(function(
                                      goodReceivedNote
                                    ) {
                                      return (
                                        goodReceivedNote.type === "received"
                                      )
                                    }),
                                    function(goodReceivedNote, i) {
                                      return _c(
                                        "el-dropdown-item",
                                        {
                                          key: "GoodReceivedNotes" + i,
                                          attrs: {
                                            command: "" + goodReceivedNote.uuid
                                          }
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to:
                                                  "/good-received-notes/edit/" +
                                                  goodReceivedNote.uuid
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  goodReceivedNote.displayId
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.activeName === "submitted"
        ? _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.fetchPR
            }
          })
        : _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.perPage
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "perPage", $event)
              },
              pagination: _vm.fetchData
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }