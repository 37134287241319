var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.user.roles.find(function(role) {
        return ["Operation Manager", "Admin"].includes(role.name)
      })
        ? _c(
            "div",
            { staticClass: "action-container" },
            [
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--primary",
                  attrs: { to: "/stores/add" }
                },
                [_c("i", { staticClass: "el-icon-edit" }), _vm._v("Add Store")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--primary",
                  attrs: { to: "/stores/salaryreport" }
                },
                [_vm._v("Advance Salary Report")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.user.roles.find(function(role) {
            return ["Store Manager", "Operation Manager", "Admin"].includes(
              role.name
            )
          })
            ? _c("el-table-column", {
                attrs: { label: "Online", width: "70" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                              "active-value": true,
                              "inactive-value": false
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeOnline(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.isOnline,
                              callback: function($$v) {
                                _vm.$set(scope.row, "isOnline", $$v)
                              },
                              expression: "scope.row.isOnline"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4170510855
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--primary",
                        attrs: { to: "/stores/roster/" + scope.row.uuid }
                      },
                      [_vm._v("Roster")]
                    ),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return [
                        "Store Manager",
                        "Operation Manager",
                        "Admin"
                      ].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.showLocationCode(scope.row.authCode)
                              }
                            }
                          },
                          [_vm._v("Location Code")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "router-link",
                          {
                            staticClass: "el-button el-button--primary",
                            attrs: { to: "/stores/edit/" + scope.row.uuid }
                          },
                          [_vm._v(_vm._s(_vm.$t("Order.Edit")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return [
                        "Upload Sale",
                        "Operation Manager",
                        "Admin"
                      ].includes(role.name)
                    })
                      ? _c(
                          "router-link",
                          {
                            staticClass: "el-button el-button--primary",
                            attrs: {
                              to: "/stores/" + scope.row.uuid + "/sales"
                            }
                          },
                          [_vm._v("Sale")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: scope.row.name === _vm.name
                            },
                            on: {
                              click: function($event) {
                                return _vm.deleteStore(scope.row.uuid)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }