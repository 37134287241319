"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper"));

require("core-js/modules/es7.object.values");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _purchaseRequest = require("@/api/purchaseRequest");

var _elementUi = require("element-ui");

var _store = require("@/api/store");

var _i18n = _interopRequireDefault(require("@/i18n"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      activeName: null,
      allEnable: false,
      summaryVisible: false,
      summary: null,
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true,
        status: null,
        targetRestockDate: null,
        stores: null
      },
      total: 0,
      stores: '',
      statusByOptions: [{
        key: 'drafted',
        name: 'Drafted'
      }, {
        key: 'submitted',
        name: 'Submitted'
      }, {
        key: 'rejected',
        name: 'Rejected'
      }, {
        key: 'confirmed',
        name: 'Confirmed'
      }, {
        key: 'completed',
        name: 'Completed'
      }]
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])),
  created: function created() {
    var _this = this;

    this.fetchData();
    (0, _store.getStores)({
      limit: 999
    }).then(function (response) {
      _this.stores = response.data;
    });

    if (this.$route.params.pathMatch) {
      this.activeName = this.$route.params.pathMatch;
      this.handleTabClick({
        name: this.$route.params.pathMatch
      });
    } else {
      this.activeName = 'all';
      this.handleTabClick({
        name: 'all'
      });
    }
  },
  methods: {
    toggleAllEnable: function toggleAllEnable() {
      if (this.allEnable) {
        this.list.forEach(function (row) {
          if (row.status === 'Confirmed' && !(row.purchaseOrders && row.purchaseOrders.length)) {
            row.isEnabled = false;
          } else {
            row.isEnabled = false;
          }
        });
        this.allEnable = false;
      } else {
        this.list.forEach(function (row) {
          if (row.status === 'Confirmed' && !(row.purchaseOrders && row.purchaseOrders.length)) {
            row.isEnabled = true;
          } else {
            row.isEnabled = false;
          }
        });
        this.allEnable = true;
      }
    },
    previewData: function previewData() {
      var _this2 = this;

      var purchaseData = this.list; // const ids = ''

      var selectedPurchaseOrders = [];
      purchaseData.forEach(function (p) {
        if (p.isEnabled) {
          selectedPurchaseOrders.push(p);
        }
      });
      this.summary = {};
      var hasItem = false;
      selectedPurchaseOrders.forEach(function (purchaseOrder) {
        purchaseOrder.items.forEach(function (item) {
          var supplierId = -1;

          if (item.rawMaterial.supplier) {
            supplierId = item.rawMaterial.supplier.id;
          }

          if (!_this2.summary[supplierId]) {
            _this2.summary[supplierId] = {
              name: item.rawMaterial.supplier ? item.rawMaterial.supplier.name : 'No Name',
              items: {}
            };
          }

          if (!_this2.summary[supplierId].items[item.rawMaterial.id]) {
            _this2.summary[supplierId].items[item.rawMaterial.id] = {
              sku: item.rawMaterial.sku,
              name: item.rawMaterial.nameEng,
              unit: item.rawMaterial.unit,
              unitPrice: item.rawMaterial.defaultPrice,
              qty: 0
            };
          }

          _this2.summary[supplierId].items[item.rawMaterial.id].qty += item.qty;
          hasItem = true;
        });
      });

      var _loop = function _loop(i) {
        _this2.summary[i].items = Object.values(_this2.summary[i].items);
        _this2.summary[i].total = 0;

        _this2.summary[i].items.forEach(function (item) {
          _this2.summary[i].total += item.unitPrice.toFixed(2) * item.qty;
        });
      };

      for (var i in this.summary) {
        _loop(i);
      }

      if (hasItem) {
        this.summaryVisible = true;
      } else {
        this.$alert(_i18n.default.t('No Items'), 'Preview');
      }

      console.log(selectedPurchaseOrders); // previewPurchaseRequest(ids).then((response) => {
      //   this.$alert(response, 'Preview Purchase Request', {
      //     dangerouslyUseHTMLString: true
      //   })
      // })
    },
    fetchData: function fetchData() {
      var _this3 = this;

      this.listLoading = true;
      (0, _purchaseRequest.getList)(this.listQuery).then(function (response) {
        _this3.list = response.data.map(function (row) {
          row.isEnabled = false;
          return row;
        });
        _this3.listLoading = false;
        _this3.total = response.meta.total;
      });
    },
    editPurchaseRequest: function editPurchaseRequest(id) {
      this.$router.push("/purchase-requests/edit/".concat(id));
    },
    addPurchaseRequest: function addPurchaseRequest() {
      this.$router.push("/purchase-requests/add");
    },
    deletePurchaseRequest: function deletePurchaseRequest(id) {
      var _this4 = this;

      _elementUi.MessageBox.confirm('Confirm to delete record?', 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.Delete'),
        cancelButtonText: _i18n.default.t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(function () {
        (0, _purchaseRequest.deletePurchaseRequest)(id).then(function () {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseRequests.RecordDeleted'),
            type: 'success',
            duration: 5 * 1000
          });

          _this4.fetchData();
        });
      });
    },
    generatePO: function generatePO() {
      var _this5 = this;

      _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseRequests.ConfirmToGeneratePO'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.Generate'),
        cancelButtonText: _i18n.default.t('PurchaseRequests.ConfirmToGeneratePO'),
        type: 'warning'
      }).then(function () {
        var uuids = [];

        _this5.list.forEach(function (row) {
          if (row.isEnabled) {
            uuids.push(row.uuid);
          }
        });

        (0, _purchaseRequest.generatePurchaseOrderFromPurchaseRequest)(uuids).then(function (response) {
          var _iterator = (0, _createForOfIteratorHelper2.default)(response),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;
              (0, _elementUi.Message)({
                message: _i18n.default.t('PurchaseRequests.POGenerated') + item.displayId,
                type: 'success',
                duration: 5 * 1000
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          _this5.$router.push("/purchase-orders/confirmed");
        }).catch(function (response) {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseRequests.CantGeneratePOGenerated') + ' ' + (response.message ? response.message : ''),
            type: 'danger',
            duration: 5 * 1000
          });
        });
      });
    },
    handleTabClick: function handleTabClick(tab) {
      var targetStatus = this.listQuery.status;

      if (tab.name === 'all') {
        targetStatus = null;
      } else if (tab.name === 'drafted') {
        targetStatus = 'Drafted';
      } else if (tab.name === 'submitted') {
        targetStatus = 'Submitted';
      } else if (tab.name === 'confirmed') {
        targetStatus = 'Confirmed';
      } else if (tab.name === 'completed') {
        targetStatus = 'Completed';
      } else {
        targetStatus = null;
      }

      if (targetStatus !== this.listQuery.status) {
        this.listQuery.status = targetStatus;
        this.fetchData();
      }

      this.$router.push("/purchase-requests/" + tab.name);
    }
  }
};
exports.default = _default;