var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.attendance,
            rules: _vm.rules,
            "label-width": "auto"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.attendance.id,
                  callback: function($$v) {
                    _vm.$set(_vm.attendance, "id", $$v)
                  },
                  expression: "attendance.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "User", prop: "userId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "Users",
                    clearable: "",
                    filterable: "",
                    "filter-method": _vm.userFilter
                  },
                  on: { change: _vm.searchDuty },
                  model: {
                    value: _vm.attendance.userId,
                    callback: function($$v) {
                      _vm.$set(_vm.attendance, "userId", $$v)
                    },
                    expression: "attendance.userId"
                  }
                },
                _vm._l(_vm.userOptions, function(user) {
                  return _c("el-option", {
                    key: user.name,
                    attrs: { label: user.name, value: user.uuid }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Warehouse.Action"), prop: "action" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Select" },
                  on: { change: _vm.searchDuty },
                  model: {
                    value: _vm.attendance.action,
                    callback: function($$v) {
                      _vm.$set(_vm.attendance, "action", $$v)
                    },
                    expression: "attendance.action"
                  }
                },
                [
                  _c("el-option", {
                    attrs: { label: "Clock In", value: "clock_in" }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "Clock Out", value: "clock_out" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Clock In/Out Time", prop: "mockTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "Select date and time"
                },
                on: { change: _vm.searchDuty },
                model: {
                  value: _vm.attendance.mockTime,
                  callback: function($$v) {
                    _vm.$set(_vm.attendance, "mockTime", $$v)
                  },
                  expression: "attendance.mockTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Related to Duty", prop: "dutyId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "duty-col",
                  attrs: { placeholder: "Duty", clearable: "" },
                  model: {
                    value: _vm.attendance.dutyId,
                    callback: function($$v) {
                      _vm.$set(_vm.attendance, "dutyId", $$v)
                    },
                    expression: "attendance.dutyId"
                  }
                },
                _vm._l(_vm.duties, function(item) {
                  return _c("el-option", {
                    key: item.uuid,
                    attrs: {
                      label:
                        item.user.name +
                        " : " +
                        item.fromTime +
                        " - " +
                        item.toTime +
                        " @ " +
                        item.store.name,
                      value: item.uuid
                    }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.searchDuty } },
                [_vm._v(_vm._s(_vm.$t("Order.Search")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v(_vm._s(_vm.$t("Order.Cancel")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }