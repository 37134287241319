"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWarehouseAreas = getWarehouseAreas;
exports.getWarehouseArea = getWarehouseArea;
exports.createWarehouseArea = createWarehouseArea;
exports.editWarehouseArea = editWarehouseArea;
exports.deleteWarehouseArea = deleteWarehouseArea;

var _request = _interopRequireDefault(require("@/utils/request"));

function getWarehouseAreas(params) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas',
    method: 'get',
    params: params
  });
}

function getWarehouseArea(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas/' + id,
    method: 'get'
  });
}

function createWarehouseArea(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas',
    data: data,
    method: 'post'
  });
}

function editWarehouseArea(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteWarehouseArea(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas/' + id,
    method: 'delete'
  });
}