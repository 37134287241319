"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _errorParse = require("@/utils/error-parse");

var _duty = require("@/api/duty");

var _elementUi = require("element-ui");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _shift = require("@/api/shift");

var _vuex = require("vuex");

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DutyDialog',
  props: {
    newDuty: {
      type: Object,
      required: true
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      minTime: '00:00',
      maxTime: '23:55',
      step: '00:05',
      role: null,
      shift: {
        uuid: null,
        name: null,
        id: null
      },
      dutyForm: {
        roleId: null,
        name: null,
        fromTime: null,
        toTime: null
      },
      shifts: [],
      shiftResponse: [],
      roles: [],
      saveAsShift: false,
      showShiftCheckbox: true,
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        },
        fromTime: {
          required: true,
          message: 'Start time is required'
        },
        toTime: {
          required: true,
          message: 'End time is required'
        },
        roleId: {
          required: true,
          message: 'Role is required',
          trigger: ['blur', 'change']
        }
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'user'])), {}, {
    addTitle: {
      get: function get() {
        return (this.newDuty.uuid === undefined ? 'Create Duty on ' : 'Edit Duty on ') + this.newDuty.date;
      }
    },
    buttonText: {
      get: function get() {
        return this.newDuty.uuid === undefined ? 'Add' : 'Edit';
      }
    },
    nameDisabled: {
      get: function get() {
        return this.newDuty.userId !== undefined;
      }
    },
    userShifts: {
      get: function get() {
        var shifts = null;

        if (this.newDuty.userId === null) {
          shifts = this.shifts;
        }

        var roles = this.userRoles;
        shifts = this.shifts.filter(function (item) {
          var validShifts = roles.filter(function (role) {
            if (role.id === item.role.id) return role;
          });

          if (validShifts.length > 0) {
            return item;
          }
        });
        return shifts;
      }
    },
    userRoles: {
      get: function get() {
        var roles = null;

        if (this.newDuty.user !== null) {
          roles = this.newDuty.user.roles;
        }

        return roles;
      }
    },
    visible: {
      get: function get() {
        return this.dialogFormVisible;
      },
      set: function set(val) {
        this.$emit('update:dialogFormVisible', val);
      }
    }
  }),
  watch: {
    newDuty: function newDuty() {
      var _this = this;

      if (this.shiftResponse.length) {
        this.shifts = this.shiftResponse.filter(function (shift) {
          return _this.newDuty.user.roles.find(function (role) {
            return role.id === shift.role.id;
          });
        });
      } else {
        (0, _shift.getShifts)().then(function (res) {
          _this.shiftResponse = res.data;
          _this.shifts = _this.shiftResponse.filter(function (shift) {
            return _this.newDuty.user.roles.find(function (role) {
              return role.id === shift.role.id;
            });
          });
        });
      } // const roleResponse = await getRoles()


      this.roles = this.newDuty.user.roles;
      this.showMatchingShift();
      this.dutyForm = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.dutyForm), this.newDuty);
      this.dutyForm.shiftId = null;
      this.dutyForm.roleId = null;

      if (this.newDuty.roleId) {
        this.dutyForm.roleId = this.newDuty.roleId;
      }

      this.saveAsShift = false;
    },
    saveAsShift: function saveAsShift() {
      var _this2 = this;

      if (this.dutyForm.roleId == null) {
        return;
      }

      var roles = this.roles.filter(function (item) {
        if (item.id === _this2.dutyForm.roleId) return true;
      });

      if (roles.length === 0) {
        return;
      }

      var roleName = roles[0].name;

      if (this.dutyForm.fromTime != null && this.dutyForm.toTime != null) {
        roleName = roleName + ' (' + this.dutyForm.fromTime + ' to ' + this.dutyForm.toTime + ')';
      }

      this.shift.name = roleName;
    },
    userRoles: function userRoles() {
      if (this.userRoles && this.userRoles.length === 1 && !this.dutyForm.roleId) {
        this.dutyForm.roleId = this.userRoles[0].id;
      }
    }
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (!valid) {
          return false;
        } else {
          _this3.createUpdateDuty();
        }
      });
    },
    onShiftSelected: function onShiftSelected() {
      var _this4 = this;

      if (this.dutyForm.shiftId == null || this.dutyForm.shiftId === '') {
        return;
      }

      var shift = this.shifts.filter(function (item) {
        if (item.uuid === _this4.dutyForm.shiftId) return true;
      })[0];
      this.dutyForm.roleId = shift.role.id;
      this.dutyForm.fromTime = (shift.from_hour > 9 ? '' : '0') + parseInt(shift.from_hour) + ':' + (shift.from_min > 9 ? '' : '0') + parseInt(shift.from_min);
      this.dutyForm.toTime = (shift.to_hour > 9 ? '' : '0') + parseInt(shift.to_hour) + ':' + (shift.to_min > 9 ? '' : '0') + parseInt(shift.to_min);
      this.showShiftCheckbox = false;
      this.saveAsShift = false;

      if (shift.from_hour > shift.to_hour) {
        this.dutyForm.nextDay = true;
      }
    },
    onRoleSelected: function onRoleSelected() {
      this.showMatchingShift();
    },
    onStartTimeSelected: function onStartTimeSelected() {
      this.showMatchingShift();
    },
    onEndTimeSelected: function onEndTimeSelected() {
      this.showMatchingShift();
    },
    showMatchingShift: function showMatchingShift() {
      var _this5 = this;

      if (this.newDuty == null || this.dutyForm.fromTime == null || this.dutyForm.toTime == null) {
        this.dutyForm.shiftId = null;
        this.shift.name = null;
        this.shift.id = null;
        this.showShiftCheckbox = true;
        return;
      }

      var start = this.dutyForm.fromTime.split(':');
      var end = this.dutyForm.toTime.split(':');
      var matched = this.shifts.filter(function (item) {
        if (parseInt(item.from_hour) === parseInt(start[0]) && parseInt(item.from_min) === parseInt(start[1]) && parseInt(item.to_hour) === parseInt(end[0]) && parseInt(item.to_min) === parseInt(end[1]) && item.role.id === _this5.dutyForm.roleId) {
          return item;
        }
      });

      if (matched.length > 0) {
        this.dutyForm.shiftId = matched[0].uuid;
        this.shift.name = matched[0].name;
        this.shift.id = matched[0].id;
        this.showShiftCheckbox = false;
        this.saveAsShift = false;
      } else {
        this.dutyForm.shiftId = null;
        this.shift.name = null;
        this.shift.id = null;
        this.showShiftCheckbox = true;
      }
    },
    calculateHour: function calculateHour(fromHour, fromMin, toHour, toMin, nextDay) {
      var start = (0, _moment.default)();
      start.set({
        hour: fromHour,
        minute: fromMin
      });
      var end = (0, _moment.default)();
      end.set({
        hour: toHour,
        minute: toMin
      });

      if (nextDay) {
        end.add(15, 'days');
      }

      return _moment.default.duration(end.diff(start)).asHours();
    },
    submitUpdateDuty: function submitUpdateDuty() {
      var _this6 = this;

      this.loading = true;
      var start = this.dutyForm.fromTime.split(':');
      var end = this.dutyForm.toTime.split(':');
      this.newDuty.roleId = this.dutyForm.roleId;
      var param = {
        date: this.dutyForm.date,
        userId: this.dutyForm.userId,
        storeId: this.$route.params.id,
        fromHour: start[0],
        fromMin: start[1],
        toHour: end[0],
        toMin: end[1],
        roleId: this.dutyForm.roleId,
        nextDay: this.dutyForm.nextDay
      };

      if (this.newDuty.uuid) {
        param.id = this.newDuty.uuid;
        (0, _duty.editDuty)(param).then(function (response) {
          (0, _elementUi.Message)({
            message: 'Duty updated.',
            type: 'success',
            duration: 5 * 1000
          });
          var duty = response.data;

          _this6.$emit('complete', duty);

          _this6.$emit('update:dialogFormVisible', false);
        }).catch(function (err) {
          (0, _elementUi.Message)({
            message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          });
          console.log(err);
        }).finally(function () {
          _this6.loading = false;
        });
      } else {
        (0, _duty.createDuty)(param).then(function (response) {
          (0, _elementUi.Message)({
            message: 'Duty created.',
            type: 'success',
            duration: 5 * 1000
          });
          var duty = response.data;

          _this6.$emit('complete', duty);

          _this6.$emit('update:dialogFormVisible', false);
        }).catch(function (err) {
          (0, _elementUi.Message)({
            message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          });
        }).finally(function () {
          _this6.loading = false;
        });
      }

      if (this.saveAsShift === true) {
        this.saveShift(param);
      }
    },
    createUpdateDuty: function createUpdateDuty() {
      var _this7 = this;

      if (this.newDuty.user.workingHoursPerDay && this.newDuty.user.roles.find(function (role) {
        return ['Staff (Full time)'].includes(role.name);
      }) && this.calculateHour(this.newDuty.fromHour, this.newDuty.fromMin, this.newDuty.toHour, this.newDuty.toMin, this.newDuty.toHour, this.newDuty.nextDay) !== this.newDuty.user.workingHoursPerDay) {
        _elementUi.MessageBox.confirm('Duration is not same like preset user working hour, it may make salary calculation for half-day leave inaccurate, Are you sure want to continue ?', 'Confirm', {
          confirmButtonText: _i18n.default.t('PurchaseOrders.Confirm'),
          cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
          type: 'warning'
        }).then(function () {
          _this7.submitUpdateDuty();
        });
      } else {
        this.submitUpdateDuty();
      }
    },
    saveShift: function saveShift(duty) {
      var _this8 = this;

      var name = this.shift.name;
      var param = {
        fromHour: duty.fromHour,
        fromMin: duty.fromMin,
        toHour: duty.toHour,
        toMin: duty.toMin,
        name: name,
        roleId: duty.roleId,
        nextDay: this.dutyForm.nextDay
      };
      (0, _shift.createShift)(param).then(function (response) {
        (0, _elementUi.Message)({
          message: 'Shift created.',
          type: 'success',
          duration: 5 * 1000
        });

        _this8.shifts.push(response.data);
      }).catch(function (err) {
        console.log('a', _this8);
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
      }).finally(function () {});
    },
    toggleNextDate: function toggleNextDate() {
      this.dutyForm.toTime = '';
    }
  }
};
exports.default = _default;