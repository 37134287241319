"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getPurchaseRequest = getPurchaseRequest;
exports.createPurchaseRequest = createPurchaseRequest;
exports.editPurchaseRequest = editPurchaseRequest;
exports.deletePurchaseRequest = deletePurchaseRequest;
exports.submitPurchaseRequest = submitPurchaseRequest;
exports.unsubmitPurchaseRequest = unsubmitPurchaseRequest;
exports.confirmPurchaseRequest = confirmPurchaseRequest;
exports.rejectPurchaseRequest = rejectPurchaseRequest;
exports.completePurchaseRequest = completePurchaseRequest;
exports.downloadPurchaseRequest = downloadPurchaseRequest;
exports.generatePurchaseOrderFromPurchaseRequest = generatePurchaseOrderFromPurchaseRequest;
exports.previewPurchaseRequest = previewPurchaseRequest;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests',
    method: 'get',
    params: params
  });
}

function getPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id,
    method: 'get'
  });
}

function createPurchaseRequest(data) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests',
    data: data,
    method: 'post'
  });
}

function editPurchaseRequest(data) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deletePurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id,
    method: 'delete'
  });
}

function submitPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/submit',
    method: 'post'
  });
}

function unsubmitPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/unsubmit',
    method: 'post'
  });
}

function confirmPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/confirm',
    method: 'post'
  });
}

function rejectPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/reject',
    method: 'post'
  });
}

function completePurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/complete',
    method: 'post'
  });
}

function downloadPurchaseRequest(id) {
  return (0, _request.default)({
    url: "/api/v1/export-data/purchase-requests/".concat(id),
    method: 'GET',
    responseType: 'blob'
  });
}

function generatePurchaseOrderFromPurchaseRequest(id, suppliers) {
  suppliers = suppliers || [];
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/generate-po',
    method: 'post',
    data: {
      id: id,
      suppliers: suppliers
    }
  });
}

function previewPurchaseRequest(param) {
  return (0, _request.default)({
    url: '/api/v1/preview/purchase-request',
    method: 'post',
    data: {
      purchaseRequest: param
    }
  });
}