"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _router = require("@/router");

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * Use meta.tenant to determine if the current user has tenancy
 * @param tenant
 * @param route
 */


function hasTenancy(tenant, route) {
  if (route.meta && route.meta.tenants) {
    return route.meta.tenants.includes(tenant.name);
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */


function filterAsyncRoutes(routes, roles, tenant) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);
    var target = null;

    if (tmp.path === '/' && tmp.redirect === '/default') {
      if (roles.includes('Admin')) {
        target = '/dashboard';
      } else if (roles.includes('Operation Manager')) {
        target = '/dashboard';
      } else if (roles.includes('Floor Manager')) {
        target = '/dashboard';
      } else if (roles.includes('Warehouse Manager')) {
        target = '/dashboard';
      } else if (roles.includes('Store Manager')) {
        target = '/stores';
      } else if (roles.includes('Procurement Manager')) {
        target = '/dashboard';
      } else {
        target = '/dashboard';
      }

      if (target !== tmp.redirect) {
        tmp.redirect = target;
      }
    }

    if (hasPermission(roles, tmp) && hasTenancy(tenant, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles, tenant);
      }

      res.push(tmp);
    }
  });
  return res;
}

var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, params) {
    var commit = _ref.commit;
    var roles = params.roles,
        tenant = params.tenant;
    return new Promise(function (resolve) {
      var accessedRoutes;

      if (roles.includes('admin')) {
        accessedRoutes = _router.asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles, tenant);
      }

      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;