"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRosterConfirmations = getRosterConfirmations;

var _request = _interopRequireDefault(require("@/utils/request"));

function getRosterConfirmations(params) {
  return (0, _request.default)({
    url: "/api/v1/roster-confirmations",
    method: 'get',
    params: params
  });
}