"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _supplier = require("@/api/supplier");

var _elementUi = require("element-ui");

var _i18n = _interopRequireDefault(require("@/i18n"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true,
        search: null
      },
      total: 0
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _supplier.getSuppliers)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.meta.total;
      });
    },
    deleteSupplier: function deleteSupplier(id) {
      var _this2 = this;

      _elementUi.MessageBox.confirm(_i18n.default.t('Supplier.ConfirmDeleteMessage'), _i18n.default.t('Supplier.Confirm'), {
        confirmButtonText: _i18n.default.t('Supplier.Delete'),
        cancelButtonText: _i18n.default.t('Supplier.Cancel'),
        type: 'warning'
      }).then(function () {
        (0, _supplier.deleteSupplier)(id).then(function () {
          (0, _elementUi.Message)({
            message: _i18n.default.t('Supplier.Recorddeleted'),
            type: 'success',
            duration: 5 * 1000
          });

          _this2.fetchData();
        });
      });
    }
  }
};
exports.default = _default;