var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: { to: "/raw-materials" }
                },
                [_vm._v(" < " + _vm._s(_vm.$t("Warehouse.Back")))]
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { margin: "10px 0 10px 10px" } }, [
                _vm._v(
                  _vm._s(_vm.rawMaterial ? "SKU: " + _vm.rawMaterial.sku : "")
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "PO Number", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--text",
                        attrs: { to: "/purchase-orders/edit/" + scope.row.uuid }
                      },
                      [_vm._v(_vm._s(scope.row.displayId))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "PO Date", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.poDate) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("PurchaseOrders.Qty"),
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.items.find(function(item) {
                            return item.rawMaterialId === _vm.$route.params.id
                          }).qty
                        ) +
                        " " +
                        _vm._s(_vm.rawMaterial ? _vm.rawMaterial.unit : "") +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("PurchaseOrders.UnitPrice"),
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        $" +
                        _vm._s(
                          scope.row.items
                            .find(function(item) {
                              return item.rawMaterialId === _vm.$route.params.id
                            })
                            .unitPrice.toFixed(2)
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Status") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === "Drafted"
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v(_vm._s(scope.row.status))
                        ])
                      : scope.row.status === "Confirmed"
                      ? _c("el-tag", [_vm._v(_vm._s(scope.row.status))])
                      : scope.row.status === "Outstanding"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v(_vm._s(scope.row.status))
                        ])
                      : scope.row.status === "Completed"
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(_vm._s(scope.row.status))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == "Confirmed" ||
                    scope.row.status == "Outstanding" ||
                    scope.row.status == "Completed"
                      ? _c(
                          "span",
                          { staticStyle: { margin: "10px 0px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "default" },
                                on: {
                                  click: function($event) {
                                    return _vm.downloadPurchaseOrder(
                                      scope.row.uuid,
                                      scope.row.displayId
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PurchaseRequests.Download"))
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == "Completed"
                      ? _c(
                          "span",
                          { staticStyle: { margin: "10px 0px" } },
                          [
                            _c(
                              "el-dropdown",
                              {
                                attrs: { trigger: "click" },
                                on: { command: _vm.gotoGoodReceived }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _c(
                                      "el-button",
                                      { attrs: { type: "warning" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.goodReceivedNotes[
                                              scope.row.goodReceivedNotes
                                                .length - 1
                                            ].status == "Drafted"
                                              ? "Good Received"
                                              : scope.row.goodReceivedNotes[
                                                  scope.row.goodReceivedNotes
                                                    .length - 1
                                                ].status == "Confirmed"
                                              ? "Good Allocation"
                                              : "Allocation Record"
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    staticClass: "allocation-dropdown",
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown"
                                  },
                                  _vm._l(
                                    scope.row.goodReceivedNotes.filter(function(
                                      goodReceivedNote
                                    ) {
                                      return (
                                        goodReceivedNote.type === "received"
                                      )
                                    }),
                                    function(goodReceivedNote, i) {
                                      return _c(
                                        "el-dropdown-item",
                                        {
                                          key: "GoodReceivedNotes" + i,
                                          attrs: {
                                            command: "" + goodReceivedNote.uuid
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                goodReceivedNote.displayId
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          "hide-on-single-page": "",
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }