"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _errorParse = require("@/utils/error-parse");

var _attendance = require("@/api/attendance");

var _user = require("@/api/user");

var _store = require("@/api/store");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      users: [],
      userOptions: [],
      stores: [],
      listQuery: {
        fromTime: null,
        toTime: null,
        pendingAdjustment: null,
        userId: null,
        storeId: null,
        page: 1,
        perPage: 10
      },
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'user'])), {}, {
    page: {
      get: function get() {
        return this.listQuery.page;
      }
    },
    perPage: {
      get: function get() {
        return this.listQuery.perPage;
      }
    },
    dateRange: {
      get: function get() {
        return [this.listQuery.fromTime ? this.listQuery.fromTime : '', this.listQuery.toTime ? this.listQuery.toTime : '']; // return this.supplierQuery.fromTime //weekly
      },
      set: function set(range) {
        if (range != null) {
          this.listQuery.fromTime = range[0];
          this.listQuery.toTime = range[1];
        } else {
          this.listQuery.fromTime = null;
          this.listQuery.toTime = null;
        }
      }
    }
  }),
  watch: {
    page: function page(val) {
      this.$router.push({
        query: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.$route.query), {}, {
          page: this.listQuery.page
        })
      });
    },
    perPage: function perPage(val) {
      this.$router.push({
        query: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.$route.query), {}, {
          perPage: this.listQuery.perPage
        })
      });
    }
  },
  created: function created() {
    for (var key in this.listQuery) {
      if (this.$route.query[key]) {
        if (typeof this.listQuery[key] === 'number') {
          this.listQuery[key] = Number(this.$route.query[key]);
        } else if (key === 'fromTime' || key === 'toTime') {
          this.listQuery[key] = new Date(this.$route.query[key]);
        } else {
          this.listQuery[key] = this.$route.query[key];
        }
      }
    }

    this.fetchData();
    this.fetchUsers();
    this.fetchStores();
  },
  methods: {
    fetchUsers: function fetchUsers() {
      var _this = this;

      (0, _user.getUsers)({
        limit: 999,
        active: 'true',
        roles: ['staff.parttime', 'staff']
      }).then(function (response) {
        _this.users = response.data;
        _this.userOptions = response.data;
      });
    },
    userFilter: function userFilter(val) {
      this.value = val;

      if (val) {
        this.userOptions = this.users.filter(function (item) {
          if (!!~item.name.indexOf(val) || !!~item.name.toUpperCase().indexOf(val.toUpperCase())) {
            return true;
          }
        });
      } else {
        this.userOptions = this.users;
      }
    },
    fetchStores: function fetchStores() {
      var _this2 = this;

      (0, _store.getStores)({
        limit: 999
      }).then(function (response) {
        _this2.stores = response.data;
      });
    },
    query: function query() {
      var param = {};

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          var current_datetime = this.listQuery[key];

          if (typeof current_datetime === 'string') {
            current_datetime = new Date(current_datetime);
          }

          var formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate();
          param[key] = formatted_date;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      this.$router.push({
        query: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), param)
      });
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this3 = this;

      this.listLoading = true;
      var param = {};

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      (0, _attendance.getAttendances)(param).then(function (response) {
        _this3.list = response.data;
        _this3.listLoading = false;
        _this3.total = response.meta.total;
      });
    },
    deleteAttendance: function deleteAttendance(id) {
      var _this4 = this;

      _elementUi.MessageBox.confirm('Confirm to delete attendance?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _attendance.deleteAttendance)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Attendance deleted',
            type: 'success',
            duration: 5 * 1000
          });

          _this4.fetchData();
        });
      });
    },
    addAttendance: function addAttendance() {
      this.$router.push("/attendances/add");
    },
    approveAdjustment: function approveAdjustment(uuid) {
      var _this5 = this;

      _elementUi.MessageBox.confirm('Confirm to approve attendance?', 'Confirm', {
        confirmButtonText: 'Approve',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _attendance.approveAdjustment)(uuid).then(function (response) {
          (0, _elementUi.Message)({
            message: 'Adjustment approved',
            type: 'success',
            duration: 5 * 1000
          });

          _this5.fetchData();
        });
      });
    },
    rejectAdjustment: function rejectAdjustment(uuid) {
      var _this6 = this;

      _elementUi.MessageBox.confirm('Confirm to reject attendance?', 'Confirm', {
        confirmButtonText: 'Reject',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _attendance.rejectAdjustment)(uuid).then(function (response) {
          (0, _elementUi.Message)({
            message: 'Adjustment rejected',
            type: 'success',
            duration: 5 * 1000
          });

          _this6.fetchData();
        });
      });
    },
    applyAdjustment: function applyAdjustment(attendance, target) {
      var _this7 = this;

      _elementUi.MessageBox.prompt('Please state the reason for requesting adjustment to ' + target + ' ?', 'Request Adjustment', {
        confirmButtonText: 'Request',
        cancelButtonText: 'Cancel'
      }).then(function (value) {
        (0, _attendance.requestAdjustment)({
          attendanceId: attendance.uuid,
          adjustedTime: target,
          remark: value.value
        }).then(function (response) {
          (0, _elementUi.Message)({
            message: 'Adjustment Requested',
            type: 'success',
            duration: 5 * 1000
          });

          _this7.fetchData();
        });
        console.log(value);
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        console.log(err);
      });
    }
  }
};
exports.default = _default;