"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.getInfo = getInfo;
exports.logout = logout;
exports.forgetPassword = forgetPassword;
exports.resetPassword = resetPassword;
exports.getRoles = getRoles;
exports.getUsers = getUsers;
exports.getUser = getUser;
exports.createUser = createUser;
exports.editUser = editUser;
exports.changePassword = changePassword;
exports.deleteUser = deleteUser;
exports.activateUser = activateUser;
exports.deactivateUser = deactivateUser;
exports.removeUserAttachment = removeUserAttachment;

var _request = _interopRequireDefault(require("@/utils/request"));

function login(data) {
  return (0, _request.default)({
    url: '/api/v1/oauth/token',
    method: 'post',
    data: {
      'grant_type': 'password',
      'client_id': process.env.VUE_APP_OAUTH_CLIENT_ID,
      'client_secret': process.env.VUE_APP_OAUTH_CLIENT_SECRET,
      'username': data.username,
      'password': data.password
    }
  });
}

function getInfo() {
  return (0, _request.default)({
    url: '/api/v1/users/me',
    method: 'get' // params: { token }

  });
}

function logout() {
  return (0, _request.default)({
    url: '/api/v1/logout',
    method: 'post'
  });
}

function forgetPassword(data) {
  return (0, _request.default)({
    url: "/api/v1/user/forgetPassword",
    method: 'put',
    data: data
  });
}

function resetPassword(data) {
  return (0, _request.default)({
    url: "/api/v1/user/resetPassword",
    method: 'put',
    data: data
  });
}

function getRoles() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return (0, _request.default)({
    url: '/api/v1/roles',
    method: 'get',
    params: params
  });
}

function getUsers(params) {
  return (0, _request.default)({
    url: '/api/v1/users',
    method: 'get',
    params: params
  });
}

function getUser(uuid) {
  return (0, _request.default)({
    url: "/api/v1/users/".concat(uuid),
    method: 'get'
  });
}

function createUser(data) {
  return (0, _request.default)({
    url: "/api/v1/users",
    method: 'post',
    data: data
  });
}

function editUser(data) {
  return (0, _request.default)({
    url: "/api/v1/users/".concat(data.uuid),
    method: 'put',
    data: data
  });
}

function changePassword(data) {
  return (0, _request.default)({
    url: "/api/v1/user/password",
    method: 'put',
    data: data
  });
}

function deleteUser(uuid) {
  return (0, _request.default)({
    url: "/api/v1/users/".concat(uuid),
    method: 'delete'
  });
}

function activateUser(uuid) {
  return (0, _request.default)({
    url: "/api/v1/users/activate",
    method: 'post',
    data: {
      id: uuid
    }
  });
}

function deactivateUser(uuid) {
  return (0, _request.default)({
    url: "/api/v1/users/deactivate",
    method: 'post',
    data: {
      id: uuid
    }
  });
}

function removeUserAttachment(id) {
  return (0, _request.default)({
    url: 'api/v1/user-attachment/delete/' + id,
    method: 'post'
  });
}