"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _store = require("@/api/store");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
var _default = {
  components: {// Pagination
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1 // ,
        // perPage: 10

      },
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'user'])),
  created: function created() {
    this.fetchData();
  },
  methods: {
    displayFromTime: function displayFromTime(data) {
      data.fromTime = '' + data.from_hour + ':' + (data.from_min > 9 ? '' : '0') + data.from_min;
      return data.fromTime;
    },
    displayToTime: function displayToTime(data) {
      data.toTime = '' + data.to_hour + ':' + (data.to_min > 9 ? '' : '0') + data.to_min;
      return data.toTime;
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _store.getStores)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        userId: this.user.uuid
      })).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;

        if (response.meta !== undefined && response.meta.total !== undefined) {
          _this.total = response.meta.total;
        }
      });
    },
    editStore: function editStore(id) {
      this.$router.push("/stores/edit/".concat(id));
    },
    addStore: function addStore() {
      this.$router.push("/stores/add");
    },
    showLocationCode: function showLocationCode(code) {
      (0, _elementUi.Message)({
        message: 'Location Code: ' + code,
        type: 'success',
        duration: 5 * 1000
      });
    },
    deleteStore: function deleteStore(id) {
      var _this2 = this;

      _elementUi.MessageBox.confirm('Confirm to delete store?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _store.deleteStore)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Store deleted',
            type: 'success',
            duration: 5 * 1000
          });

          _this2.fetchData();
        });
      });
    },
    changeOnline: function changeOnline(store) {
      if (store.isOnline) {
        (0, _store.onlineStore)(store.uuid).then(function (response) {
          console.log(response);
        });
      } else {
        (0, _store.offlineStore)(store.uuid).then(function (response) {
          console.log(response);
        });
      }
    }
  }
};
exports.default = _default;