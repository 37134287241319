var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          "range-separator": " - ",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "value-format": "yyyy-MM-dd"
        },
        model: {
          value: _vm.dateFilter,
          callback: function($$v) {
            _vm.dateFilter = $$v
          },
          expression: "dateFilter"
        }
      }),
      _vm._v(" "),
      _c("label", [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Store")) + ": ")]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          attrs: { placeholder: "Store" },
          model: {
            value: _vm.listQuery.warehouse_id,
            callback: function($$v) {
              _vm.$set(_vm.listQuery, "warehouse_id", $$v)
            },
            expression: "listQuery.warehouse_id"
          }
        },
        [
          _c("el-option", {
            key: "store_default",
            attrs: { label: "All", value: "" }
          }),
          _vm._v(" "),
          _vm._l(_vm.stores, function(store) {
            return _c("el-option", {
              key: store.name,
              attrs: { label: store.name, value: store.uuid }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
        [_vm._v("Filter")]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            border: "",
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "Raw Material",
              width: "200",
              prop: "nameEng",
              sortable: ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.nameEng ? scope.row.nameEng : null) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Category ", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.categories
                            ? scope.row.categories
                                .map(function(e) {
                                  return e.name
                                })
                                .join(",")
                            : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Supplier",
              width: "150",
              prop: "supplier.name",
              sortable: ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.supplier ? scope.row.supplier.name : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Quantity",
              width: "150",
              prop: "qty",
              sortable: ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.qty) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Amount (HKD)", prop: "total", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.total ? scope.row.total : null) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "% COGS", prop: "percent_cogs", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.percent_cogs
                            ? parseFloat(
                                scope.row.percent_cogs.toFixed(5).toString()
                              )
                            : null
                        ) +
                        " %\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }