"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWarehouses = getWarehouses;
exports.downloadQRs = downloadQRs;
exports.deleteWarehouse = deleteWarehouse;

var _request = _interopRequireDefault(require("@/utils/request"));

function getWarehouses(params) {
  return (0, _request.default)({
    url: "/api/v1/warehouses",
    method: 'get',
    params: params
  });
}

function downloadQRs(id) {
  return (0, _request.default)({
    url: "/api/v1/warehouses/".concat(id, "/qr-codes/download"),
    method: 'GET',
    responseType: 'blob'
  });
}

function deleteWarehouse(id) {
  return (0, _request.default)({
    url: "/api/v1/warehouses/".concat(id),
    method: 'delete'
  });
}