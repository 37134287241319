"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper"));

require("core-js/modules/es6.array.find-index");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _pathToRegexp = _interopRequireDefault(require("path-to-regexp"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      levelList: null
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['permission_routes'])),
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0]; // matched[matched.length - 1]

      matched = matched.filter(function (item) {
        return item.meta.breadcrumb !== false;
      }); // console.log(this.permission_routes)

      var selectedBranchRoute = this.flattenRoute(this.getMatchedRoutes(matched[matched.length - 1].path, this.permission_routes)); // console.log({ selectedBranchRoute })

      if (!this.isDashboard(first) && selectedBranchRoute.length < 2) {
        matched = [{
          path: '/dashboard',
          meta: {
            title: 'Dashboard'
          }
        }].concat(matched);
      } else {
        matched = selectedBranchRoute;
      }

      this.levelList = matched;
    },
    flattenRoute: function flattenRoute(route) {
      var routes = [route];

      if (Array.isArray(route.children) && route.children.length) {
        routes = routes.concat(this.flattenRoute(route.children[0]));
      }

      return routes;
    },
    getMatchedRoutes: function getMatchedRoutes(path) {
      var routes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var parentRoute = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var nonNested = routes.findIndex(function (route) {
        return route.path === path || parentRoute && route.path[0] !== '/' && (parentRoute + '/' + route.path === path || parentRoute + '/' + route.path === '/' + path);
      });

      if (nonNested !== -1) {
        nonNested = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, routes[nonNested]), {}, {
          path: parentRoute && routes[nonNested].path[0] !== '/' ? parentRoute + (parentRoute !== '/' ? '/' : '') + routes[nonNested].path : routes[nonNested].path
        });
        delete nonNested.children;
        return nonNested;
      } // nested


      var _iterator = (0, _createForOfIteratorHelper2.default)(routes),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var route = _step.value;

          // if (route.path === '/warehouses') {
          // console.log(routes, route.path)
          // }
          if (Array.isArray(route.children)) {
            var parentPath = parentRoute && route.path[0] !== '/' ? parentRoute + (parentRoute !== '/' ? '/' : '') + route.path : route.path;
            var childrenSearch = this.getMatchedRoutes(path, route.children, parentPath);

            if (childrenSearch) {
              // console.log({ path, childrenSearch }, route.children, route.path)
              if (route.path === '/') {
                return (0, _objectSpread2.default)({}, childrenSearch);
              } else if (childrenSearch) {
                return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, route), {}, {
                  path: parentPath,
                  children: [childrenSearch]
                });
              }
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return false;
    },
    isDashboard: function isDashboard(route) {
      var name = route && route.name;

      if (!name) {
        return false;
      }

      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;

      var toPath = _pathToRegexp.default.compile(path);

      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          path = item.path;

      if (redirect) {
        this.$router.push(redirect);
        return;
      }

      this.$router.push(this.pathCompile(path));
    }
  }
};
exports.default = _default;