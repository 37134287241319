var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.user, rules: _vm.rules, "label-width": "auto" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.user.id,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "id", $$v)
                  },
                  expression: "user.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Group", prop: "group" } },
            [
              _vm.tenants && _vm.tenants.length
                ? _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: !!(_vm.currentUser && _vm.currentUser.tenant),
                        placeholder: "Group"
                      },
                      model: {
                        value: _vm.user.tenantId,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "tenantId", $$v)
                        },
                        expression: "user.tenantId"
                      }
                    },
                    _vm._l(_vm.tenants, function(tenant) {
                      return _c("el-option", {
                        key: tenant.name,
                        attrs: { label: tenant.name, value: tenant.uuid }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.user.name,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "name", $$v)
                  },
                  expression: "user.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Email", prop: "email" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.user.email,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "HKID", prop: "hkid" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.user.hkid,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "hkid", $$v)
                  },
                  expression: "user.hkid"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Tel", prop: "tel" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.user.tel,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "tel", $$v)
                  },
                  expression: "user.tel"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Joining Date", prop: "joiningDate" } },
            [
              _c("el-date-picker", {
                attrs: { placeholder: "选择日期", type: "date" },
                model: {
                  value: _vm.user.joiningDate,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "joiningDate", $$v)
                  },
                  expression: "user.joiningDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Role", prop: "roles" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.user.roles,
                    callback: function($$v) {
                      _vm.$set(_vm.user, "roles", $$v)
                    },
                    expression: "user.roles"
                  }
                },
                _vm._l(_vm.roles, function(item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.id,
                      attrs: {
                        disabled: !_vm.checkboxPermission(item, _vm.user.id),
                        label: item.id
                      },
                      on: {
                        change: function($event) {
                          return _vm.checkedRole($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name) + "\n        ")]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "role-desc" }, [
                _c("div", { staticClass: "role-function" }, [
                  _vm._v("*Role Function*")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "role-info" },
                  _vm._l(_vm.asyncRoutes, function(node) {
                    return _c("div", { key: node.name }, [
                      _c("span", [_vm._v(_vm._s(node.name))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(node.role))])
                    ])
                  }),
                  0
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.currentUser.roles.find(function(role) {
                      return [
                        "Store Manager",
                        "Operation Manager",
                        "Admin"
                      ].includes(role.name)
                    }) && _vm.checkFullTimeRole(),
                  expression:
                    "currentUser.roles.find(role => ['Store Manager', 'Operation Manager', 'Admin'].includes(role.name)) && checkFullTimeRole()"
                }
              ],
              attrs: { label: "Leave", prop: "leaves" }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.user.leaves,
                    callback: function($$v) {
                      _vm.$set(_vm.user, "leaves", $$v)
                    },
                    expression: "user.leaves"
                  }
                },
                _vm._l(_vm.leaves, function(item) {
                  return _c(
                    "el-checkbox",
                    { key: item.uuid, attrs: { label: item.uuid } },
                    [
                      _vm._v(
                        _vm._s(
                          item.name +
                            (item.quota > 0
                              ? "(" +
                                item.quota +
                                " day per " +
                                item.refill_period +
                                ")"
                              : "")
                        )
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checkFullTimeRole(),
                  expression: "checkFullTimeRole()"
                }
              ],
              attrs: { label: "Monthly Salary", prop: "monthlySalary" }
            },
            [
              _c("el-input-number", {
                attrs: { step: 1, "step-strictly": "" },
                model: {
                  value: _vm.user.monthlySalary,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "monthlySalary", $$v)
                  },
                  expression: "user.monthlySalary"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checkPartTimeRole(),
                  expression: "checkPartTimeRole()"
                }
              ],
              attrs: { label: "Hourly Wage", prop: "hourlyWage" }
            },
            [
              _c("el-input-number", {
                attrs: { step: 0.1, "step-strictly": "" },
                model: {
                  value: _vm.user.hourlyWage,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "hourlyWage", $$v)
                  },
                  expression: "user.hourlyWage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checkFullTimeRole(),
                  expression: "checkFullTimeRole()"
                }
              ],
              attrs: {
                label: "Working Hours / Day",
                prop: "workingHoursPerDay"
              }
            },
            [
              _c("el-input-number", {
                attrs: { step: 0.5, "step-strictly": "" },
                model: {
                  value: _vm.user.workingHoursPerDay,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "workingHoursPerDay", $$v)
                  },
                  expression: "user.workingHoursPerDay"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("PurchaseRequests.Store"), prop: "stores" }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.user.stores,
                    callback: function($$v) {
                      _vm.$set(_vm.user, "stores", $$v)
                    },
                    expression: "user.stores"
                  }
                },
                _vm._l(_vm.stores, function(item) {
                  return _c(
                    "el-checkbox",
                    { key: item.uuid, attrs: { label: item.uuid } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Attachmenet", prop: "attachment" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action: _vm.uploadDomain + "/api/v1/user-attachment/upload",
                    "before-remove": _vm.beforeRemove,
                    data: _vm.uploadData,
                    "file-list": _vm.fileList,
                    headers: _vm.uploadHeader,
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.handleSuccess,
                    drag: "",
                    multiple: "",
                    name: "attachment[]"
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("Drop file here or "),
                    _c("em", [_vm._v("click to upload")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("jpg/png files with a size less than 500kb")]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Password", prop: "password" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "password" },
                model: {
                  value: _vm.user.password,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "password", $$v)
                  },
                  expression: "user.password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Confirm password",
                prop: "password_confirmation"
              }
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "password" },
                model: {
                  value: _vm.user.password_confirmation,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "password_confirmation", $$v)
                  },
                  expression: "user.password_confirmation"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", [
            _vm.currentUser !== null &&
            (_vm.currentUser.roles.find(function(role) {
              return ["Admin"].includes(role.name)
            }) ||
              _vm.currentUser.id === _vm.user.id)
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        attrs: { to: "/users" }
                      },
                      [_vm._v(_vm._s(_vm.$t("Order.Cancel")))]
                    )
                  ],
                  1
                )
              : _vm.currentUser !== null &&
                _vm.currentUser.roles.find(function(role) {
                  return ["Operation Manager"].includes(role.name)
                }) &&
                  !_vm.user.userRoles.find(function(role) {
                    return ["Admin", "Operation Manager"].includes(role.name)
                  })
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        attrs: { to: "/users" }
                      },
                      [_vm._v(_vm._s(_vm.$t("Order.Cancel")))]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        attrs: { to: "/users" }
                      },
                      [_vm._v("Back")]
                    )
                  ],
                  1
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }