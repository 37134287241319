"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getItem = getItem;
exports.createItem = createItem;
exports.editItem = editItem;
exports.deleteItem = deleteItem;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/pos-categories',
    method: 'get',
    params: params
  });
}

function getItem(id) {
  return (0, _request.default)({
    url: '/api/v1/pos-categories/' + id,
    method: 'get'
  });
}

function createItem(data) {
  return (0, _request.default)({
    url: '/api/v1/pos-categories',
    data: data,
    method: 'post'
  });
}

function editItem(data) {
  return (0, _request.default)({
    url: '/api/v1/pos-categories/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteItem(id) {
  return (0, _request.default)({
    url: '/api/v1/pos-categories/' + id,
    method: 'delete'
  });
}