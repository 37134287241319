import { render, staticRenderFns } from "./PurchaseOrders.vue?vue&type=template&id=1b9da747&"
import script from "./PurchaseOrders.vue?vue&type=script&lang=js&"
export * from "./PurchaseOrders.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseOrders.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b9da747')) {
      api.createRecord('1b9da747', component.options)
    } else {
      api.reload('1b9da747', component.options)
    }
    module.hot.accept("./PurchaseOrders.vue?vue&type=template&id=1b9da747&", function () {
      api.rerender('1b9da747', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/rawMaterials/PurchaseOrders.vue"
export default component.exports