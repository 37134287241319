"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseError = parseError;

var _typeof2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/typeof"));

function parseError(error) {
  var message = null;

  if (error.message) {
    message = error.message + '\n';
  }

  if (error.data) {
    if ((0, _typeof2.default)(error.data) === 'object') {
      for (var key in error.data) {
        message += key + ' : ' + error.data[key];
      }
    }
  }

  if (!message) {
    message = 'Unknown Error';
  }

  return message;
}