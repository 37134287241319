var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "div",
        { staticClass: "summary" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 11 } }, [
                _c("div", { staticClass: "order" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v("Order Summary")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "detail" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { span: 6 }
                            },
                            [_vm._v("Order Number")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-right", attrs: { span: 18 } },
                            [_vm._v(_vm._s(_vm.order.display_id))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { span: 6 }
                            },
                            [_vm._v("Store")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-right", attrs: { span: 18 } },
                            [_vm._v(_vm._s(_vm.order.store))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { span: 6 }
                            },
                            [_vm._v("Type")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-right", attrs: { span: 18 } },
                            [_vm._v(_vm._s(_vm.order.type))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { span: 6 }
                            },
                            [_vm._v("Status")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-right", attrs: { span: 18 } },
                            [_vm._v(_vm._s(_vm.order.status))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { span: 6 }
                            },
                            [_vm._v(_vm._s(_vm.$t("Order.Tel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-right", attrs: { span: 18 } },
                            [_vm._v(_vm._s(_vm.order.contactTel))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { span: 6 }
                            },
                            [_vm._v("Email")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-right", attrs: { span: 18 } },
                            [_vm._v(_vm._s(_vm.order.contactEmail))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { span: 6 }
                            },
                            [_vm._v("Remark")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-right", attrs: { span: 18 } },
                            [_vm._v(_vm._s(_vm.order.remark))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "font-weight-bold total",
                              attrs: { span: 12 }
                            },
                            [_vm._v("Order Total : ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-right", attrs: { span: 12 } },
                            [_vm._v("$" + _vm._s(_vm.order.total))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 11 } }, [
                _c("div", { staticClass: "products" }, [
                  _c("div", { staticClass: "header" }, [
                    _c("div", [
                      _c("div", [
                        _vm._v(
                          "Products (" + _vm._s(_vm.order.items.length) + ")"
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "createdAt" }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.format_date(_vm.order.createdAt)))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "status" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.order.status) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "detail" },
                    _vm._l(_vm.order.items, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "item" },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: { span: 18 }
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.qty) +
                                      " x " +
                                      _vm._s(item.product.sku)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { span: 6 }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.price != ""
                                        ? "$" + item.price * item.qty
                                        : " - "
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "extra" },
                            _vm._l(
                              item.extras.filter(function(extra) {
                                return extra.options.length > 0
                              }),
                              function(extra, i) {
                                return _c(
                                  "div",
                                  { key: i, staticClass: "modifiers-row" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("div", [_vm._v(_vm._s(extra.name))]),
                                        _vm._v(" "),
                                        _vm._l(extra.options, function(
                                          option,
                                          o
                                        ) {
                                          return _c("div", { key: o }, [
                                            _vm._v(" - " + _vm._s(option.name))
                                          ])
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "el-button el-button--default",
              staticStyle: { "margin-left": "10px", "margin-top": "20px" },
              attrs: { to: "/orders" }
            },
            [_vm._v("Back")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }