var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("RawMaterial.Supplier") } },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.suppliers,
                      label: "name",
                      reduce: function(supplier) {
                        return supplier.uuid
                      }
                    },
                    model: {
                      value: _vm.listQuery.supplierId,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "supplierId", $$v)
                      },
                      expression: "listQuery.supplierId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("RawMaterial.Category") } },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.categories,
                      label: "name",
                      reduce: function(category) {
                        return category.uuid
                      }
                    },
                    model: {
                      value: _vm.listQuery.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "categoryId", $$v)
                      },
                      expression: "listQuery.categoryId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.query } },
                [_vm._v("Filter")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "el-button el-button--primary",
                      attrs: { to: "/raw-materials/add" }
                    },
                    [
                      _c("i", { staticClass: "el-icon-edit" }),
                      _vm._v("Add Raw Material")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.fetchData($event)
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.keyword,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "keyword", $$v)
                          },
                          expression: "listQuery.keyword"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default", icon: "el-icon-search" },
                          on: { click: _vm.query }
                        },
                        [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SKU" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.sku) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Name"), width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.nameEng) +
                        " " +
                        _vm._s(scope.row.nameChin) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Supplier" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.supplier ? scope.row.supplier.name : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Actions" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "3px" } },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "el-button el-button--warning",
                            attrs: {
                              to:
                                "/raw-materials/" +
                                scope.row.uuid +
                                "/purchase-orders"
                            }
                          },
                          [_vm._v("Purchase History")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--primary",
                        attrs: { to: "/raw-materials/edit/" + scope.row.uuid }
                      },
                      [_vm._v("Edit")]
                    ),
                    _vm._v(" "),
                    !!_vm.user.roles.find(function(role) {
                      return ["Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deleteRawMaterial(scope.row.uuid)
                              }
                            }
                          },
                          [_vm._v("Hide")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }