var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.warehouseAreaRawMaterial, "label-width": "auto" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.warehouseAreaRawMaterial.id,
                  callback: function($$v) {
                    _vm.$set(_vm.warehouseAreaRawMaterial, "id", $$v)
                  },
                  expression: "warehouseAreaRawMaterial.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseAreaRawMaterial.WarehouseArea"),
                prop: "warehouseAreaId",
                rules: [
                  {
                    required: true,
                    message: _vm.$t(
                      "WarehouseAreaRawMaterial.RequiredWarehouseMessage"
                    )
                  }
                ]
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Area", disabled: true },
                  model: {
                    value: _vm.warehouseAreaRawMaterial.warehouseAreaId,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.warehouseAreaRawMaterial,
                        "warehouseAreaId",
                        $$v
                      )
                    },
                    expression: "warehouseAreaRawMaterial.warehouseAreaId"
                  }
                },
                _vm._l(_vm.warehouseAreas, function(warehouseArea) {
                  return _c("el-option", {
                    key: warehouseArea.uuid,
                    attrs: {
                      label: warehouseArea.name,
                      value: warehouseArea.uuid
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseAreaRawMaterial.RawMaterial"),
                prop: "rawMaterialId",
                rules: [
                  {
                    required: true,
                    message: _vm.$t(
                      "WarehouseAreaRawMaterial.RequiredWarehouseMessage"
                    )
                  }
                ]
              }
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.rawMaterials,
                  label: "sku",
                  reduce: function(rawMaterial) {
                    return rawMaterial.uuid
                  }
                },
                model: {
                  value: _vm.warehouseAreaRawMaterial.rawMaterialId,
                  callback: function($$v) {
                    _vm.$set(_vm.warehouseAreaRawMaterial, "rawMaterialId", $$v)
                  },
                  expression: "warehouseAreaRawMaterial.rawMaterialId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v(_vm._s(_vm.$t("Order.Cancel")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }