"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCategories = getCategories;
exports.getCategory = getCategory;
exports.createCategory = createCategory;
exports.editCategory = editCategory;
exports.deleteCategory = deleteCategory;
exports.pushCategory = pushCategory;

var _request = _interopRequireDefault(require("@/utils/request"));

function getCategories(params) {
  return (0, _request.default)({
    url: '/api/v1/categories',
    method: 'get',
    params: params
  });
}

function getCategory(id) {
  return (0, _request.default)({
    url: '/api/v1/categories/' + id,
    method: 'get'
  });
}

function createCategory(data) {
  return (0, _request.default)({
    url: '/api/v1/categories',
    data: data,
    method: 'post'
  });
}

function editCategory(data) {
  return (0, _request.default)({
    url: '/api/v1/categories/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteCategory(id) {
  return (0, _request.default)({
    url: '/api/v1/categories/' + id,
    method: 'delete'
  });
}

function pushCategory(id) {
  return (0, _request.default)({
    url: '/api/v1/categories/' + id + '/push',
    method: 'post'
  });
}