"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRawMaterials = getRawMaterials;
exports.getRawMaterial = getRawMaterial;
exports.createRawMaterial = createRawMaterial;
exports.editRawMaterial = editRawMaterial;
exports.deleteRawMaterial = deleteRawMaterial;
exports.findBySku = findBySku;
exports.getRawMaterialScheduledPrices = getRawMaterialScheduledPrices;
exports.createRawMaterialScheduledPrice = createRawMaterialScheduledPrice;
exports.deleteRawMaterialScheduledPrice = deleteRawMaterialScheduledPrice;

var _request = _interopRequireDefault(require("@/utils/request"));

function getRawMaterials(params) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials',
    method: 'get',
    params: params
  });
}

function getRawMaterial(id) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials/' + id,
    method: 'get'
  });
}

function createRawMaterial(data) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials',
    data: data,
    method: 'post'
  });
}

function editRawMaterial(data) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials/' + data.uuid,
    data: data,
    method: 'put'
  });
}

function deleteRawMaterial(id) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials/' + id,
    method: 'delete'
  });
}

function findBySku(key) {
  return (0, _request.default)({
    url: '/api/v1/raw-material/findSku',
    method: 'post',
    key: key
  });
}

function getRawMaterialScheduledPrices(params) {
  return (0, _request.default)({
    url: '/api/v1/raw-material-scheduled-price',
    method: 'get',
    params: params
  });
}

function createRawMaterialScheduledPrice(data) {
  return (0, _request.default)({
    url: '/api/v1/raw-material-scheduled-price',
    data: data,
    method: 'post'
  });
}

function deleteRawMaterialScheduledPrice(id) {
  return (0, _request.default)({
    url: '/api/v1/raw-material-scheduled-price/' + id,
    method: 'delete'
  });
}