"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOrders = getOrders;
exports.getCustomerOrders = getCustomerOrders;
exports.getOrder = getOrder;
exports.getCustomerOrder = getCustomerOrder;
exports.getOrderSummary = getOrderSummary;
exports.getCustomerOrderSummary = getCustomerOrderSummary;
exports.editOrder = editOrder;
exports.editCustomerOrder = editCustomerOrder;
exports.createOrder = createOrder;
exports.deleteOrder = deleteOrder;
exports.rejectOrder = rejectOrder;
exports.downloadOrder = downloadOrder;
exports.downloadCustomerOrder = downloadCustomerOrder;
exports.rejectCustomerOrder = rejectCustomerOrder;
exports.deleteCustomerOrder = deleteCustomerOrder;

var _request = _interopRequireDefault(require("@/utils/request"));

function getOrders(params) {
  return (0, _request.default)({
    url: "/api/v1/orders",
    method: 'get',
    params: params
  });
}

function getCustomerOrders(params) {
  return (0, _request.default)({
    url: "/api/v1/customer-orders",
    method: 'get',
    params: params
  });
}

function getOrder(id) {
  return (0, _request.default)({
    url: "/api/v1/orders/".concat(id),
    method: 'get'
  });
}

function getCustomerOrder(id) {
  return (0, _request.default)({
    url: "/api/v1/customer-orders/".concat(id),
    method: 'get'
  });
}

function getOrderSummary(uuid) {
  return (0, _request.default)({
    url: "/api/v1/orders/summary/".concat(uuid),
    method: 'get'
  });
}

function getCustomerOrderSummary(uuid) {
  return (0, _request.default)({
    url: "/api/v1/customer-orders/summary/".concat(uuid),
    method: 'get'
  });
}

function editOrder(data) {
  return (0, _request.default)({
    url: "/api/v1/orders/".concat(data.uuid),
    data: data,
    method: 'put'
  });
}

function editCustomerOrder(data) {
  return (0, _request.default)({
    url: "/api/v1/customer-orders/".concat(data.uuid),
    data: data,
    method: 'put'
  });
}

function createOrder(data) {
  return (0, _request.default)({
    url: "/api/v1/orders",
    data: data,
    method: 'post'
  });
}

function deleteOrder(uuid) {
  return (0, _request.default)({
    url: "/api/v1/orders/".concat(uuid),
    method: 'delete'
  });
}

function rejectOrder(uuid) {
  return (0, _request.default)({
    url: "/api/v1/orders/reject/".concat(uuid),
    method: 'GET'
  });
}

function downloadOrder(uuid) {
  return (0, _request.default)({
    url: "/api/v1/orders/export/".concat(uuid),
    method: 'GET',
    responseType: 'blob'
  });
}

function downloadCustomerOrder(uuid) {
  return (0, _request.default)({
    url: "/api/v1/customer-orders/export/".concat(uuid),
    method: 'GET',
    responseType: 'blob'
  });
}

function rejectCustomerOrder(uuid) {
  return (0, _request.default)({
    url: "/api/v1/customer-orders/reject/".concat(uuid),
    method: 'GET'
  });
}

function deleteCustomerOrder(uuid) {
  return (0, _request.default)({
    url: "/api/v1/customer-orders/".concat(uuid),
    method: 'delete'
  });
}