"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _shift = require("@/api/shift");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name'])),
  created: function created() {
    this.fetchData();
  },
  methods: {
    displayFromTime: function displayFromTime(data) {
      data.fromTime = '' + data.from_hour + ':' + (data.from_min > 9 ? '' : '0') + data.from_min;
      return data.fromTime;
    },
    displayToTime: function displayToTime(data) {
      data.toTime = '' + data.to_hour + ':' + (data.to_min > 9 ? '' : '0') + data.to_min;
      return data.toTime;
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _shift.getShifts)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.meta.total;
      });
    },
    deleteShift: function deleteShift(id) {
      var _this2 = this;

      _elementUi.MessageBox.confirm('Confirm to delete shift?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _shift.deleteShift)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Shift deleted',
            type: 'success',
            duration: 5 * 1000
          });

          _this2.fetchData();
        });
      });
    }
  }
};
exports.default = _default;