var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _vm.listQuery.storeId
            ? _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: true } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-edit" },
                          on: { click: _vm.add }
                        },
                        [_vm._v("Add Category")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "el-table",
          staticStyle: { width: "100%", "border-bottom": "1px solid #DDD" }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "th", attrs: { span: 4 } }, [
                _vm._v("Name")
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "th", attrs: { span: 4 } }, [
                _vm._v("Status")
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "th", attrs: { span: 4 } }, [
                _vm._v("Sequence")
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "th", attrs: { span: 8 } }, [
                _vm._v("Description")
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "th", attrs: { span: 4 } }, [
                _vm._v("Action")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "draggable",
            { attrs: { list: _vm.list }, on: { end: _vm.dragEnd } },
            _vm._l(_vm.list, function(element) {
              return _c(
                "div",
                { key: element.uuid, staticClass: "item" },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex" } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "cell ellipsis", attrs: { span: 4 } },
                        [_vm._v(_vm._s(element.name))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "cell", attrs: { span: 4 } },
                        [
                          _vm._v(
                            _vm._s(element.status ? "Enabled" : "Disabled")
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "cell", attrs: { span: 4 } },
                        [
                          _vm._v(
                            _vm._s(element.sequence ? element.sequence : 0)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "cell ellipsis", attrs: { span: 8 } },
                        [_vm._v(_vm._s(element.description))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "cell", attrs: { span: 4 } },
                        [
                          _vm.user.roles.find(function(role) {
                            return ["Operation Manager", "Admin"].includes(
                              role.name
                            )
                          })
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.remove(element.uuid)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.user.roles.find(function(role) {
                            return ["Operation Manager", "Admin"].includes(
                              role.name
                            )
                          })
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.edit(element.uuid)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("PurchaseRequests.Edit"))
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }