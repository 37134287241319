var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.moomoomember,
            rules: _vm.rules,
            "label-width": "auto"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.moomoomember.id,
                  callback: function($$v) {
                    _vm.$set(_vm.moomoomember, "id", $$v)
                  },
                  expression: "moomoomember.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "name" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.moomoomember.name,
                  callback: function($$v) {
                    _vm.$set(_vm.moomoomember, "name", $$v)
                  },
                  expression: "moomoomember.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Email", prop: "email" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.moomoomember.email,
                  callback: function($$v) {
                    _vm.$set(_vm.moomoomember, "email", $$v)
                  },
                  expression: "moomoomember.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Mobile", prop: "mobilephone" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.moomoomember.mobilephone,
                  callback: function($$v) {
                    _vm.$set(_vm.moomoomember, "mobilephone", $$v)
                  },
                  expression: "moomoomember.mobilephone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Birthday" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "Select date",
                  disabled: "disabled"
                },
                model: {
                  value: _vm.moomoomember.birthday,
                  callback: function($$v) {
                    _vm.$set(_vm.moomoomember, "birthday", $$v)
                  },
                  expression: "moomoomember.birthday"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Referral", prop: "referral" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.moomoomember.referral,
                  callback: function($$v) {
                    _vm.$set(_vm.moomoomember, "referral", $$v)
                  },
                  expression: "moomoomember.referral"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "Passkit", prop: "passkit" } }, [
            _c("span", [
              _vm.moomoomember.passkit !== null
                ? _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://pub1.pskt.io/" + _vm.moomoomember.passkit,
                        target: "_blank"
                      }
                    },
                    [_vm._v("go to Passkit")]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: { to: "/moomoomembers" }
                },
                [_vm._v("Back")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }