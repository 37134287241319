var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "el-button el-button--primary",
                      attrs: { to: "/deliverytype/add" }
                    },
                    [
                      _c("i", { staticClass: "el-icon-edit" }),
                      _vm._v("Add Delivery Type")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.fetchData($event)
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.search,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "search", $$v)
                          },
                          expression: "listQuery.search"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default", icon: "el-icon-search" },
                          on: { click: _vm.fetchData }
                        },
                        [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "45" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Type", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.type))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Location" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.location))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "GPS" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.gps))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions"), width: "320" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--primary",
                        attrs: { to: "/deliverytype/edit/" + scope.row.id }
                      },
                      [_vm._v(_vm._s(_vm.$t("Order.Edit")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.deleteDeliveryType(scope.row.id)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showQRScan, width: "" },
          on: {
            "update:visible": function($event) {
              _vm.showQRScan = $event
            }
          }
        },
        [
          _vm.showQRScan
            ? _c("vue-qr-reader", {
                ref: "qr",
                attrs: {
                  "stop-on-scanned": true,
                  "use-back-camera": true,
                  "draw-on-found": true,
                  "line-color": "#d90000",
                  "line-width": 2,
                  "video-height": 480,
                  "video-width": 640,
                  responsive: true
                },
                on: {
                  "code-scanned": _vm.codeArrived,
                  "error-captured": _vm.errorCaptured
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }