"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.string.pad-start");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _moment = _interopRequireDefault(require("moment"));

var _report = require("@/api/report");

var _cashExpense = require("@/api/cashExpense");

var _elementUi = require("element-ui");

var _objectHash = _interopRequireDefault(require("object-hash"));

var _errorParse = require("@/utils/error-parse");

var _i18n = _interopRequireDefault(require("@/i18n"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import LineChart from '@/components/Chart/lineChart'
var _default = {
  name: 'Dashboard',
  components: {
    /* LineChart, */
    Pagination: _Pagination.default
  },
  data: function data() {
    var _this = this;

    return {
      storeStaffDutyData: [],
      suppliersPO: [],
      suppliersStoreMode: 'real',
      suppliersStores: {
        realHash: null,
        real: [],
        estimateHash: null,
        estimate: []
      },
      // suppliersStore: [],
      totalSupplierPO: 0,
      totalSupplierStore: 0,
      poQuery: {
        fromTime: '',
        toTime: '',
        page: 1,
        limit: 10
      },
      supplierSortBy: {
        'amount': 'descending'
      },
      supplierQuery: {
        fromTime: '',
        toTime: '',
        page: 1,
        limit: 10
      },
      warehouses: [],
      totalWarehouse: 0,
      warehouseSortBy: {
        'amount': 'descending'
      },
      warehouseQuery: {
        fromTime: '',
        toTime: '',
        page: 1,
        limit: 10
      },
      shouldShowStoreCost: false,
      storeCostChartOptions: null,
      staffDate: {
        fromTime: '',
        toTime: ''
      },
      cashExpenses: [],
      cashExpenseParam: {
        category: '',
        date_range: null
      },
      cashExpenseCategories: [{
        id: '',
        name: _i18n.default.t('CashExpense.All')
      }, {
        id: 'Stationary',
        name: _i18n.default.t('CashExpense.Stationary')
      }, {
        id: 'Food',
        name: _i18n.default.t('CashExpense.Food')
      }, {
        id: 'Miscellaneous',
        name: _i18n.default.t('CashExpense.Miscellaneous')
      }, {
        id: 'Laundry',
        name: _i18n.default.t('CashExpense.Laundry')
      }, // {id : "InkBox", name : i18n.t('CashExpense.InkBox')},
      {
        id: 'Logistic',
        name: _i18n.default.t('CashExpense.Logistic')
      }, {
        id: 'Cleaning',
        name: _i18n.default.t('CashExpense.Cleaning')
      }, {
        id: 'FoodIngredients',
        name: _i18n.default.t('CashExpense.FoodIngredients')
      }, {
        id: 'Bar',
        name: _i18n.default.t('CashExpense.Bar')
      }],
      cashExpenseLoading: false,
      supplierLoading: false,
      pickerMinDate: '',
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
              minDate = _ref.minDate;
          console.log({
            maxDate: maxDate,
            minDate: minDate
          });

          if (minDate) {
            _this.pickerMinDate = minDate.getTime();
          }

          if (maxDate) {
            _this.pickerMinDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          var times = new Date().setDate(new Date().getDate() + 1);

          if (_this.pickerMinDate !== '') {
            var pickedDate = new Date(_this.pickerMinDate);
            var minDate = new Date(pickedDate.getYear(), pickedDate.getMonth(), 0).getDate() - 1;
            var maxDate = new Date(pickedDate.getYear(), pickedDate.getMonth() + 1, 0).getDate() - 1;
            var min = minDate * 24 * 3600 * 1000;
            var max = maxDate * 24 * 3600 * 1000;
            var minTime = _this.pickerMinDate - min;
            var maxTime = _this.pickerMinDate + max;

            if (maxTime > times) {
              maxTime = times - 8.64e7;
            }

            return time.getTime() < minTime || time.getTime() > maxTime;
          }

          return time.getTime() > times - 8.64e7;
        }
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'roles'])), {}, {
    supplierPO: {
      get: function get() {
        return [this.poQuery.fromTime, this.poQuery.toTime];
      },
      set: function set(secondDateOfWeek) {
        if (secondDateOfWeek != null) {
          this.poQuery.fromTime = secondDateOfWeek[0];
          this.poQuery.toTime = secondDateOfWeek[1];
        } else {
          this.poQuery.fromTime = '';
          this.poQuery.toTime = '';
        }

        this.fetchSuppliersPO();
      }
    },
    supplierWeek: {
      get: function get() {
        return [this.supplierQuery.fromTime, this.supplierQuery.toTime];
      },
      set: function set(secondDateOfWeek) {
        if (secondDateOfWeek != null) {
          this.supplierQuery.fromTime = secondDateOfWeek[0];
          this.supplierQuery.toTime = secondDateOfWeek[1];

          if (this.suppliersStoreMode === 'real') {
            this.fetchSuppliersStore();
          } else {
            this.fetchSuppliersStoreByPO();
          }
        } else {
          this.supplierQuery.fromTime = '';
          this.supplierQuery.toTime = '';
        }
      }
    },
    staffWeek: {
      get: function get() {
        return [this.staffDate.fromTime, this.staffDate.toTime];
      },
      set: function set(secondDateOfWeek) {
        if (secondDateOfWeek != null) {
          this.staffDate.fromTime = secondDateOfWeek[0];
          this.staffDate.toTime = secondDateOfWeek[1];
        } else {
          this.staffDate.fromTime = '';
          this.staffDate.toTime = '';
        }

        this.fetchStoreStaffDuty();
      }
    },
    suppliersStore: {
      get: function get() {
        if ((0, _objectHash.default)(this.supplierWeek) !== this.suppliersStores.realHash) {
          return null;
        }

        return this.suppliersStores.real;
      },
      set: function set(list) {
        this.suppliersStores.real = list;
        this.suppliersStores.realHash = (0, _objectHash.default)(this.supplierWeek);
      }
    },
    suppliersStoreEstimate: {
      get: function get() {
        if ((0, _objectHash.default)(this.supplierWeek) !== this.suppliersStores.estimateHash) {
          return null;
        }

        return this.suppliersStores.estimate;
      },
      set: function set(list) {
        this.suppliersStores.estimate = list;
        this.suppliersStores.estimateHash = (0, _objectHash.default)(this.supplierWeek);
      }
    }
  }),
  watch: {
    suppliersStoreMode: function suppliersStoreMode(newMode, oldMode) {
      if (newMode === 'real' && this.suppliersStore === null) {
        this.fetchSuppliersStore();
      } else if (newMode === 'estimate' && this.suppliersStoreEstimate === null) {
        this.fetchSuppliersStoreByPO();
      }
    }
  },
  created: function created() {
    var roles = this.roles.map(function (role) {
      return role.name;
    });

    if (roles.includes('Admin') || roles.includes('Operation Manager') || roles.includes('Floor Manager')) {
      if (!this.staffDate.fromTime) {
        var now = new Date();
        now.setDate(now.getDate() - 8);
        this.staffDate.fromTime = now.toJSON().slice(0, 10);
      } // this.shouldShowStoreCost = true


      this.fetchStoreStaffDuty();
      this.getCashExpenseReport();
    }

    if (this.roles.find(function (role) {
      return ['Admin', 'Warehouse Manager', 'Procurement Manager'].includes(role.name);
    })) {
      if (!this.poQuery.toTime) {
        var toTime = new Date();
        toTime.setDate(toTime.getDate() - 1);
        this.staffDate.toTime = toTime.toJSON().slice(0, 10);
      }

      if (!this.poQuery.fromTime) {
        var _now = new Date();

        _now.setDate(_now.getDate() - 8);

        this.poQuery.fromTime = _now.toJSON().slice(0, 10);
      }

      if (!this.poQuery.toTime) {
        var _toTime = new Date();

        _toTime.setDate(_toTime.getDate() - 1);

        this.poQuery.toTime = _toTime.toJSON().slice(0, 10);
      }

      if (!this.supplierQuery.fromTime) {
        var _now2 = new Date();

        _now2.setDate(_now2.getDate() - 8);

        this.supplierQuery.fromTime = _now2.toJSON().slice(0, 10);
      }

      if (!this.supplierQuery.toTime) {
        var _toTime2 = new Date();

        _toTime2.setDate(_toTime2.getDate() - 1);

        this.supplierQuery.toTime = _toTime2.toJSON().slice(0, 10);
      } // Top 10 Suppliers


      this.fetchSuppliersPO(); // Store Purchase Report

      this.fetchSuppliersStore(); // Warehouse

      this.fetchWarehouseSummary();
    }
  },
  methods: {
    formatDate: function formatDate(date) {
      var d = new Date(date);
      var year = d.getFullYear();
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();

      if (month.length < 2) {
        month = '0' + month;
      }

      if (day.length < 2) {
        day = '0' + day;
      }

      return [year, month, day].join('-');
    },
    dateFormat: function dateFormat(row, column) {
      var date = row[column.property];

      if (date === undefined) {
        return '';
      }

      return (0, _moment.default)(date).format('YYYY-MM-DD');
    },
    supplierSummary: function supplierSummary() {
      // console.log('SupplierSummary')
      var param = {
        fromTime: this.poQuery.fromTime,
        toTime: this.poQuery.toTime
      };
      (0, _report.downloadSupplierSummary)(param).then(function (response) {
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "summary.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    oneSupplierSummary: function oneSupplierSummary(id) {
      var param = {
        id: id,
        fromTime: this.supplierWeek[0],
        toTime: this.supplierWeek[1]
      };
      (0, _report.exportSupplierPOSummary)(param).then(function (response) {
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "summary.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    supplierStoreSummary: function supplierStoreSummary() {
      if (this.suppliersStoreMode === 'real') {
        (0, _report.downloadSupplierStoreSummary)({
          fromTime: this.supplierQuery.fromTime,
          toTime: this.supplierQuery.toTime
        }).then(function (response) {
          var fileURL = window.URL.createObjectURL(response);
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', "store-purchase-report.pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(function (err) {
          (0, _elementUi.Message)({
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      } else {
        (0, _report.downloadSupplierStoreByPOSummary)({
          fromTime: this.supplierQuery.fromTime,
          toTime: this.supplierQuery.toTime
        }).then(function (response) {
          var fileURL = window.URL.createObjectURL(response);
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', "store-purchase-report.pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(function (err) {
          (0, _elementUi.Message)({
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }
    },
    sortSupplierPOChanged: function sortSupplierPOChanged(sortProps) {
      this.supplierSortBy = {};
      this.supplierSortBy[sortProps.prop] = sortProps.order;
      this.fetchSuppliersPO();
    },
    sortSupplierStoreChanged: function sortSupplierStoreChanged(sortProps) {
      this.supplierSortBy = {};
      this.supplierSortBy[sortProps.prop] = sortProps.order;
      this.fetchSuppliersStore();
    },
    warehouseSummary: function warehouseSummary(param) {
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = _i18n.default.t('Order.Total') + ':';
        } else if (index === 3) {
          var values = data.map(function (item) {
            return Number(item[column.property]);
          });

          if (!values.every(function (value) {
            return isNaN(value);
          })) {
            var total = 0;
            total = values.reduce(function (prev, curr) {
              var value = Number(curr);

              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = Math.round(total * 100) / 100;
          } else {
            sums[index] = '0';
          }
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    fetchStoreStaffDuty: function fetchStoreStaffDuty() {
      var _this2 = this;

      var currentDate = '';
      var param = {
        date: currentDate,
        fromTime: this.staffDate.fromTime,
        toTime: this.staffDate.toTime
      };
      (0, _report.getStoreStaffDuty)(param).then(function (response) {
        var res = response.data;
        var data = [];

        for (var i = 0; i < res.length; i++) {
          if (parseFloat(res[i].activeCost) > 0) {
            data.push(res[i]);
          }
        }

        _this2.storeStaffDutyData = data;
      });
    },
    fetchSuppliersPO: function fetchSuppliersPO() {
      var _this3 = this;

      (0, _report.getSuppliersPO)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.poQuery), {}, {
        orderBy: this.supplierSortBy
      })).then(function (response) {
        _this3.suppliersPO = response.data;
        _this3.totalSupplierPO = response.meta.total;
      });
    },
    fetchSuppliersStore: function fetchSuppliersStore() {
      var _this4 = this;

      this.supplierLoading = true;
      (0, _report.getSupplierPOStore)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.supplierQuery), {}, {
        orderBy: this.supplierSortBy
      })).then(function (response) {
        _this4.supplierLoading = false; // console.log(response)

        _this4.suppliersStore = response.data;
        /*
        this.totalSupplierStore = response.meta.total
        */
      });
    },
    fetchSuppliersStoreByPO: function fetchSuppliersStoreByPO() {
      var _this5 = this;

      this.supplierLoading = true;
      (0, _report.getSupplierByPOStore)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.supplierQuery), {}, {
        orderBy: this.supplierSortBy
      })).then(function (response) {
        _this5.supplierLoading = false; // console.log(response)

        _this5.suppliersStoreEstimate = response.data;
        /*
        this.totalSupplierStore = response.meta.total
        */
      });
    },
    fetchWarehouseSummary: function fetchWarehouseSummary() {
      var _this6 = this;

      (0, _report.getWarehouseReport)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.warehouseQuery), {}, {
        orderBy: this.warehouseSortBy
      })).then(function (response) {
        _this6.warehouses = response.data;
        _this6.totalWarehouse = response.meta.total;
      });
    },
    getCashExpenseReport: function getCashExpenseReport() {
      var _this7 = this;

      this.cashExpenseLoading = true;

      if (this.cashExpenseParam.date_range === null) {
        var start = new Date();
        start.setDate(start.getDate() - 7);
        var dd = String(start.getDate()).padStart(2, '0');
        var mm = String(start.getMonth() + 1).padStart(2, '0'); // January is 0!

        var yyyy = start.getFullYear();
        var format1 = yyyy + '-' + mm + '-' + dd;
        var next = new Date();
        dd = String(next.getDate()).padStart(2, '0');
        mm = String(next.getMonth() + 1).padStart(2, '0'); // January is 0!

        yyyy = next.getFullYear();
        var format2 = yyyy + '-' + mm + '-' + dd;
        this.cashExpenseParam.date_range = [format1, format2];
      }

      (0, _cashExpense.getReport)(this.cashExpenseParam).then(function (response) {
        _this7.cashExpenseLoading = false;
        _this7.cashExpenses = response;
      });
    },
    filterCategory: function filterCategory(category) {
      // this.cashExpenseParam.category = category;
      // this.getCashExpenseReport();
      this.$router.push("/cash-expense/" + category + "/" + this.cashExpenseParam.date_range);
    },
    handleSupplierRowExpand: function handleSupplierRowExpand(row, expandedRows, storeId) {
      var _this8 = this;

      if (expandedRows.length > 0) {
        var supplierId = null;
        var idx = -1;
        var ids = -1; // console.log('populate storeId ' + storeId + ' row uuid ' + row.uuid)

        for (var p = 0; p < this.suppliersStore.length; p++) {
          if (this.suppliersStore[p].uuid === storeId) {
            ids = p;

            for (var i = 0; i < this.suppliersStore[p].suppliers.length; i++) {
              if (this.suppliersStore[p].suppliers[i].uuid === row.uuid) {
                if (this.suppliersStore[p].suppliers[i].summary && this.suppliersStore[p].suppliers[i].summary.length > 1) {
                  // console.log('Row Supplier ' + i + ' uuid ' + row.uuid + ' name ' + row.name + ' store row ' + p + ' has summaries ' + this.suppliersStore[p].suppliers[i].summary.length)
                  return;
                }

                this.supplierLoading = true;
                idx = i;
                supplierId = row.uuid;
                break;
              }
            }

            break;
          }
        }

        if (supplierId) {
          // console.log({ supplierId })
          (0, _report.getSupplierPOStore)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.supplierQuery), {}, {
            storeId: storeId,
            supplierId: supplierId
          })).then(function (suppliersStore) {
            _this8.supplierLoading = false; // console.log(suppliersStore.data[0].suppliers, suppliersStore[0])

            _this8.suppliersStore[ids].suppliers[idx].summary = _this8.suppliersStore[ids].suppliers[idx].summary.concat(suppliersStore.data[0].suppliers[0].summary);
            /*
                      this.totalSupplierStore = response.meta.total
                      */
          });
        }
      }
    },
    handleSupplierRowExpandPO: function handleSupplierRowExpandPO(row, expandedRows, storeId) {
      var _this9 = this;

      if (expandedRows.length > 0) {
        var supplierId = null;
        var idx = -1;
        var ids = -1; // console.log('populate storeId ' + storeId + ' row uuid ' + row.uuid)

        for (var p = 0; p < this.suppliersStoreEstimate.length; p++) {
          if (this.suppliersStoreEstimate[p].uuid === storeId) {
            ids = p;

            for (var i = 0; i < this.suppliersStoreEstimate[p].suppliers.length; i++) {
              if (this.suppliersStoreEstimate[p].suppliers[i].uuid === row.uuid) {
                if (this.suppliersStoreEstimate[p].suppliers[i].summary && this.suppliersStoreEstimate[p].suppliers[i].summary.length > 1) {
                  // console.log('Row Supplier ' + i + ' uuid ' + row.uuid + ' name ' + row.name + ' store row ' + p + ' has summaries ' + this.suppliersStore[p].suppliers[i].summary.length)
                  return;
                }

                this.supplierLoading = true;
                idx = i;
                supplierId = row.uuid;
                break;
              }
            }

            break;
          }
        }

        if (supplierId) {
          // console.log({ supplierId })
          (0, _report.getSupplierByPOStore)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.supplierQuery), {}, {
            storeId: storeId,
            supplierId: supplierId
          })).then(function (suppliersStore) {
            _this9.supplierLoading = false; // console.log(suppliersStore.data[0].suppliers, suppliersStore[0])

            _this9.suppliersStoreEstimate[ids].suppliers[idx].summary = _this9.suppliersStoreEstimate[ids].suppliers[idx].summary.concat(suppliersStore.data[0].suppliers[0].summary);
            /*
                      this.totalSupplierStore = response.meta.total
                      */
          });
        }
      }
    },
    handleStoreRowExpand: function handleStoreRowExpand(row, expandedRows) {
      var _this10 = this;

      if (expandedRows.length > 0) {
        var storeId = null;
        var idx = -1;

        for (var p = 0; p < this.suppliersStore.length; p++) {
          if (this.suppliersStore[p].uuid === row.uuid) {
            if (this.suppliersStore[p].suppliers && this.suppliersStore[p].suppliers.length > 1) {
              return;
            }

            this.supplierLoading = true;
            idx = p;
            storeId = row.uuid;
            break;
          }
        }

        if (storeId) {
          (0, _report.getSupplierPOStore)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.supplierQuery), {}, {
            storeId: storeId
          })).then(function (suppliersStore) {
            _this10.supplierLoading = false; // console.log(suppliersStore.data[0].suppliers, suppliersStore[0])

            _this10.suppliersStore[idx].suppliers = _this10.suppliersStore[idx].suppliers.concat(suppliersStore.data[0].suppliers);
            /*
                      this.totalSupplierStore = response.meta.total
                      */
          });
        }
      }
    },
    handleStoreRowExpandPO: function handleStoreRowExpandPO(row, expandedRows) {
      var _this11 = this;

      if (expandedRows.length > 0) {
        var storeId = null;
        var idx = -1;

        for (var p = 0; p < this.suppliersStoreEstimate.length; p++) {
          if (this.suppliersStoreEstimate[p].uuid === row.uuid) {
            if (this.suppliersStoreEstimate[p].suppliers && this.suppliersStoreEstimate[p].suppliers.length > 1) {
              return;
            }

            this.supplierLoading = true;
            idx = p;
            storeId = row.uuid;
            break;
          }
        }

        if (storeId) {
          (0, _report.getSupplierByPOStore)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.supplierQuery), {}, {
            storeId: storeId
          })).then(function (suppliersStore) {
            _this11.supplierLoading = false; // console.log(suppliersStore.data[0].suppliers, suppliersStore[0])

            _this11.suppliersStoreEstimate[idx].suppliers = _this11.suppliersStoreEstimate[idx].suppliers.concat(suppliersStore.data[0].suppliers);
            /*
                      this.totalSupplierStore = response.meta.total
                      */
          });
        }
      }
    },
    handleRowExpand: function handleRowExpand(row, expandedRows) {
      var _this12 = this;

      if (expandedRows.length > 0) {
        var idx = -1;

        for (var p = 0; p < this.suppliersPO.length; p++) {
          if (this.suppliersPO[p].supplier_id === row.supplier_id) {
            if (this.suppliersPO[p].summary.length > 1) {
              return;
            }

            idx = p;
            break;
          }
        }

        if (idx > -1) {
          (0, _report.getSupplierPOSummary)({
            id: row.supplier_id,
            fromTime: this.supplierWeek[0],
            toTime: this.supplierWeek[1]
          }).then(function (summary) {
            var supplier = _this12.suppliersPO[idx];
            supplier.summary = [];

            for (var i = 0; i < summary.length; i++) {
              supplier.summary.push({
                sku: summary[i].sku,
                qty: summary[i].qty,
                unit: summary[i].unit,
                amount: summary[i].amount
              });
            }
          });
        }
      }
    },
    translateCategory: function translateCategory(key) {
      var category_name = '';

      for (var i = 0; i < this.cashExpenseCategories.length; i++) {
        var category = this.cashExpenseCategories[i];

        if (category.id === key) {
          category_name = category.name;
          break;
        }
      }

      return category_name;
    }
  }
};
exports.default = _default;