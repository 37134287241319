"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _humanizeDuration2 = _interopRequireDefault(require("humanize-duration"));

var _vuex = require("vuex");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _user = require("@/api/user");

var _store = require("@/api/store");

var _report = require("@/api/report");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
// import { MessageBox, Message } from 'element-ui'
var _default = {
  components: {// Pagination
  },
  data: function data() {
    return {
      uuid: this.$route.params.id,
      list: null,
      listLoading: true,
      users: [],
      stores: [],
      listQuery: {
        fromTime: null,
        toTime: null,
        userId: null,
        storeId: null,
        groupBy: 'month',
        page: 1,
        limit: 9999
      },
      groupByOptions: [{
        key: 'date',
        name: 'Day'
      }, {
        key: 'week',
        name: 'Week'
      }, {
        key: 'month',
        name: 'Month'
      }, {
        key: 'year',
        name: 'Year'
      }],
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'user'])),
  created: function created() {
    var today = new Date();
    this.listQuery['fromTime'] = new Date(today.getFullYear(), today.getMonth(), 1);
    this.listQuery['toTime'] = new Date(today.getFullYear(), today.getMonth() + 1, -1);
    this.listQuery['toTime'].setDate(this.listQuery.toTime.getDate() + 1);
    this.fetchData();
    this.fetchUsers();
    this.fetchStores();
  },
  methods: {
    humanizeDuration: function humanizeDuration(time) {
      return (0, _humanizeDuration2.default)(time * 1000);
    },
    fetchUsers: function fetchUsers() {
      var _this = this;

      (0, _user.getUsers)({
        limit: 999,
        roles: ['staff.parttime', 'staff']
      }).then(function (response) {
        _this.users = response.data;
      });
    },
    fetchStores: function fetchStores() {
      var _this2 = this;

      (0, _store.getStores)({
        limit: 999
      }).then(function (response) {
        _this2.stores = response.data;
      });
    },
    fetchData: function fetchData() {
      var _this3 = this;

      this.listLoading = true;
      var param = {};

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          var current_datetime = this.listQuery[key];
          var formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + ' ' + current_datetime.getHours() + ':' + current_datetime.getMinutes() + ':' + current_datetime.getSeconds();
          param[key] = formatted_date;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      (0, _report.getUserLate)((0, _objectSpread2.default)({}, param)).then(function (response) {
        _this3.list = response.data;
        _this3.listLoading = false;
        _this3.total = response.data ? response.data.length : 0;
      });
    },
    getSummaries: function getSummaries() {
      var summary = {};
      var totalLateTime = 0;
      var totalLateCount = 0;
      var totalOTTime = 0;
      var totalOTCount = 0;
      var lateTimeCount = 0;
      var lateCountCount = 0;
      var OTTimeCount = 0;
      var OTCountCount = 0;

      if (this.list) {
        this.list.forEach(function (row) {
          if (row.lateTime) {
            totalLateTime += row.lateTime;
            lateTimeCount++;
          }

          if (row.lateCount) {
            totalLateCount += row.lateCount;
            lateCountCount++;
          }

          if (row.oTTime) {
            totalOTTime += row.oTTime;
            OTTimeCount++;
          }

          if (row.oTCount) {
            totalOTCount += row.oTCount;
            OTCountCount++;
          }
        });
      }

      summary[3] = _i18n.default.t('Order.Total') + ': ' + this.humanizeDuration(totalLateTime) + ' | Avg: ' + this.humanizeDuration((lateTimeCount ? totalLateTime / lateTimeCount : 0).toFixed(0));
      summary[4] = _i18n.default.t('Order.Total') + ': ' + totalLateCount + ' | Avg: ' + (lateCountCount ? totalLateCount / lateCountCount : 0).toFixed(0);
      summary[5] = _i18n.default.t('Order.Total') + ': ' + this.humanizeDuration(totalOTTime) + ' | Avg: ' + this.humanizeDuration((OTTimeCount ? totalOTTime / OTTimeCount : 0).toFixed(0));
      summary[6] = _i18n.default.t('Order.Total') + ': ' + totalOTCount + ' | Avg: ' + (OTCountCount ? totalOTCount / OTCountCount : 0).toFixed(0);
      return summary;
    }
  }
};
exports.default = _default;