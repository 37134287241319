var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-row",
        [
          _c("el-col", [
            _c("h2", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$route.params.id ? "Edit Item" : "Add Item") +
                  "\n      "
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "space-around" } },
        [
          _c(
            "el-col",
            { staticClass: "grid-content", attrs: { span: 17 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.item,
                    "label-width": "auto",
                    "label-position": "left"
                  },
                  on: { submit: _vm.onSubmit }
                },
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "General", name: "general" } },
                        [
                          _c(
                            "el-row",
                            {
                              attrs: { type: "flex", justify: "space-around" }
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24, lg: { span: 18 } } },
                                [
                                  _c(
                                    "el-card",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "clearfix header",
                                          attrs: { slot: "header" },
                                          slot: "header"
                                        },
                                        [_c("span", [_vm._v("Item Image")])]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Image Upload",
                                            prop: "preview_url"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              attrs: {
                                                "list-type": "picture-card",
                                                "auto-upload": true,
                                                limit: 1,
                                                "file-list": _vm.fileList,
                                                accept:
                                                  "image/png,image/jpg,image/jpeg,image/svg+xml",
                                                action: _vm.formUpload.action,
                                                data: _vm.formUpload.data,
                                                "on-change": _vm.onFileChange,
                                                "on-remove": _vm.onFileRemove,
                                                "before-upload":
                                                  _vm.beforeUpload,
                                                "on-success": _vm.onUploaded
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "file",
                                                  fn: function(ref) {
                                                    var file = ref.file
                                                    return _c("div", {}, [
                                                      _c("img", {
                                                        staticClass:
                                                          "el-upload-list__item-thumbnail",
                                                        attrs: {
                                                          src:
                                                            _vm.fileList[0].url,
                                                          alt: ""
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "el-upload-list__item-actions"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "el-upload-list__item-preview",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handlePictureCardPreview(
                                                                    file,
                                                                    _vm.fileList
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-zoom-in"
                                                              })
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "el-upload-list__item-delete",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.onFileRemove(
                                                                    file,
                                                                    _vm.fileList
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-delete"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-plus",
                                                attrs: { slot: "default" },
                                                slot: "default"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-card",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "clearfix header",
                                          attrs: { slot: "header" },
                                          slot: "header"
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Basic Information")
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Type" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              model: {
                                                value: _vm.item.type,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.type"
                                              }
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "Single",
                                                  value: "single"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "Combo",
                                                  value: "combo"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("Order.Name"),
                                            prop: "name"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.item.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.item, "name", $$v)
                                              },
                                              expression: "item.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Description",
                                            prop: "description"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { type: "textarea" },
                                            model: {
                                              value: _vm.item.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "item.description"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Simplified Name",
                                            prop: "simplified_name"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.item.simplified_name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "simplified_name",
                                                  $$v
                                                )
                                              },
                                              expression: "item.simplified_name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Product Type",
                                            prop: "posProductTypeId"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              on: {
                                                change:
                                                  _vm.refreshSubProductType
                                              },
                                              model: {
                                                value:
                                                  _vm.item.posProductTypeId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "posProductTypeId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.posProductTypeId"
                                              }
                                            },
                                            _vm._l(_vm.productTypes, function(
                                              pt
                                            ) {
                                              return _c("el-option", {
                                                key: pt.uuid,
                                                attrs: {
                                                  label: pt.name,
                                                  value: pt.uuid
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "Sub Product Type" }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              model: {
                                                value:
                                                  _vm.item.posSubProductTypeId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "posSubProductTypeId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.posSubProductTypeId"
                                              }
                                            },
                                            _vm._l(
                                              _vm.subProductTypes,
                                              function(spt) {
                                                return _c("el-option", {
                                                  key: spt.uuid,
                                                  attrs: {
                                                    label: spt.name,
                                                    value: spt.uuid
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Category",
                                            prop: "posCategoryId"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              model: {
                                                value: _vm.item.posCategoryId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "posCategoryId",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.posCategoryId"
                                              }
                                            },
                                            _vm._l(_vm.categories, function(
                                              cat
                                            ) {
                                              return _c("el-option", {
                                                key: cat.uuid,
                                                attrs: {
                                                  label: cat.name,
                                                  value: cat.uuid
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "SKU", prop: "sku" }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder:
                                                "Will use Item name on empty"
                                            },
                                            model: {
                                              value: _vm.item.sku,
                                              callback: function($$v) {
                                                _vm.$set(_vm.item, "sku", $$v)
                                              },
                                              expression: "item.sku"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Take Away Price",
                                            prop: "price"
                                          }
                                        },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              precision: 2,
                                              step: 0.1,
                                              min: 0
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.priceChanged()
                                              }
                                            },
                                            model: {
                                              value: _vm.item.price,
                                              callback: function($$v) {
                                                _vm.$set(_vm.item, "price", $$v)
                                              },
                                              expression: "item.price"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Dine in Price",
                                            prop: "dinein_price"
                                          }
                                        },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              precision: 2,
                                              step: 0.1,
                                              min: 0
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.dineinPriceChanged()
                                              }
                                            },
                                            model: {
                                              value: _vm.item.dinein_price,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "dinein_price",
                                                  $$v
                                                )
                                              },
                                              expression: "item.dinein_price"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-card",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "clearfix header",
                                          attrs: { slot: "header" },
                                          slot: "header"
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Additional Details")
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Print",
                                            prop: "print"
                                          }
                                        },
                                        [
                                          _c("el-switch", {
                                            model: {
                                              value: _vm.item.print_to_station,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "print_to_station",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.print_to_station"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.item.print_to_station
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Print to kitchen",
                                                prop: "posKitchenStationId"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder:
                                                      "Choose Station",
                                                    clearable: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.item
                                                        .posKitchenStationId,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "posKitchenStationId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.posKitchenStationId"
                                                  }
                                                },
                                                _vm._l(_vm.kitchens, function(
                                                  kitchen
                                                ) {
                                                  return _c("el-option", {
                                                    key: kitchen.uuid,
                                                    attrs: {
                                                      label:
                                                        kitchen.type +
                                                        " " +
                                                        kitchen.name +
                                                        " @ " +
                                                        kitchen.store.name,
                                                      value: kitchen.uuid
                                                    }
                                                  })
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.item.type === "single"
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "Modifier", name: "modifier" } },
                            [
                              _c(
                                "el-row",
                                {
                                  attrs: {
                                    type: "flex",
                                    justify: "space-around"
                                  }
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24, lg: { span: 18 } } },
                                    [
                                      _c(
                                        "el-card",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix header",
                                              attrs: { slot: "header" },
                                              slot: "header"
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "5px"
                                                  },
                                                  attrs: {
                                                    type: "flex",
                                                    align: "middle"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center"
                                                      },
                                                      attrs: { span: 12 }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Import from Another Item\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "flex-end"
                                                      },
                                                      attrs: { span: 12 }
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "Choose Item",
                                                            disabled:
                                                              _vm.copyModifier
                                                                .disabled
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.selectModifier(
                                                                "copy"
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.copyModifier
                                                                .uuid,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.copyModifier,
                                                                "uuid",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "copyModifier.uuid"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-option",
                                                            {
                                                              attrs: {
                                                                value: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Choose Item"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            _vm.items.filter(
                                                              function(i) {
                                                                return (
                                                                  i.modifiers &&
                                                                  i.modifiers
                                                                    .length
                                                                )
                                                              }
                                                            ),
                                                            function(itm) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: itm.name,
                                                                  attrs: {
                                                                    label:
                                                                      itm.name,
                                                                    value:
                                                                      itm.uuid
                                                                  }
                                                                }
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "20px"
                                                  },
                                                  attrs: {
                                                    type: "flex",
                                                    align: "middle"
                                                  }
                                                },
                                                [
                                                  _c("el-col", {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center"
                                                    },
                                                    attrs: { span: 12 }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "flex-end"
                                                      },
                                                      attrs: { span: 12 }
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "Import Modifier Preset",
                                                            disabled:
                                                              _vm.copyModifier
                                                                .disabled
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.selectModifier(
                                                                "preset"
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.preset.uuid,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.preset,
                                                                "uuid",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "preset.uuid"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-option",
                                                            {
                                                              attrs: {
                                                                value: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Import Modifier Preset"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            _vm.preset.presets,
                                                            function(p_item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key:
                                                                    p_item.name,
                                                                  attrs: {
                                                                    label:
                                                                      p_item.name,
                                                                    value:
                                                                      p_item.uuid
                                                                  }
                                                                }
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                {
                                                  attrs: {
                                                    type: "flex",
                                                    align: "middle"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center"
                                                      },
                                                      attrs: { span: 12 }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.item.name
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "flex-end"
                                                      },
                                                      attrs: { span: 12 }
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "success"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.addModifier
                                                          }
                                                        },
                                                        [_vm._v("Add Modifier")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm._l(_vm.item.modifiers, function(
                                            section,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "modifier"
                                              },
                                              [
                                                _c("el-input", {
                                                  model: {
                                                    value: section.name,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        section,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "section.name"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticClass: "opt_head",
                                                    attrs: {
                                                      label: "Multi Select",
                                                      prop: "allow_multiple",
                                                      "label-width": "100px"
                                                    }
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      staticStyle: {
                                                        "padding-left": "10px"
                                                      },
                                                      model: {
                                                        value:
                                                          section.allow_multiple,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            section,
                                                            "allow_multiple",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "section.allow_multiple"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-link",
                                                      {
                                                        staticClass: "btn",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeModifier(
                                                              _vm.item
                                                                .modifiers,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          attrs: {
                                                            "icon-class":
                                                              "remove"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                section.allow_multiple
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass: "opt_head",
                                                        attrs: {
                                                          label: "Selection",
                                                          "label-width": "70px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-row",
                                                          {
                                                            attrs: {
                                                              gutter: 20
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  md: {
                                                                    span: 8
                                                                  },
                                                                  xl: {
                                                                    span: 5
                                                                  },
                                                                  span: 11
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input-number",
                                                                  {
                                                                    attrs: {
                                                                      placeholder:
                                                                        "MIN",
                                                                      precision: 0,
                                                                      step: 1,
                                                                      min: 0
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        section.min_qty,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          section,
                                                                          "min_qty",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "section.min_qty"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  md: {
                                                                    span: 8
                                                                  },
                                                                  xl: {
                                                                    span: 5
                                                                  },
                                                                  span: 11
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input-number",
                                                                  {
                                                                    attrs: {
                                                                      placeholder:
                                                                        "MAX",
                                                                      precision: 0,
                                                                      step: 1,
                                                                      min: 0
                                                                    },
                                                                    on: {
                                                                      change: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.changedMaxModifier(
                                                                          section
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        section.max_qty,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          section,
                                                                          "max_qty",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "section.max_qty"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "no-margin",
                                                        attrs: {
                                                          label: "Mandatory",
                                                          prop:
                                                            "allow_multiple",
                                                          "label-width": "100px"
                                                        }
                                                      },
                                                      [
                                                        _c("el-switch", {
                                                          staticStyle: {
                                                            "padding-left":
                                                              "10px"
                                                          },
                                                          attrs: {
                                                            value: !!section.min_qty
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeMandatory(
                                                                section
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "overflow-x": "auto"
                                                    },
                                                    attrs: {
                                                      "label-width": "1px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-table",
                                                      {
                                                        staticClass:
                                                          "modifier opt_head",
                                                        style: {
                                                          minWidth: section.allow_multiple
                                                            ? "533px"
                                                            : "auto",
                                                          maxWidth: section.allow_multiple
                                                            ? "auto"
                                                            : "455px"
                                                        },
                                                        attrs: {
                                                          data: section.options
                                                        }
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "Order.Name"
                                                            ),
                                                            width: "130px"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          placeholder:
                                                                            "name"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row
                                                                              .name,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              scope.row,
                                                                              "name",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "scope.row.name"
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label:
                                                              "Add on Price",
                                                            "min-width": "150"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "el-input-number",
                                                                      {
                                                                        attrs: {
                                                                          precision: 2,
                                                                          step: 0.1,
                                                                          min: 0
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row
                                                                              .add_on_price,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              scope.row,
                                                                              "add_on_price",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "scope.row.add_on_price"
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }),
                                                        _vm._v(" "),
                                                        section.allow_multiple
                                                          ? _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Max Allowed",
                                                                  "min-width":
                                                                    "150"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        scope
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "el-input-number",
                                                                            {
                                                                              attrs: {
                                                                                precision: 0,
                                                                                step: 1,
                                                                                min: 1,
                                                                                max: 1
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  scope
                                                                                    .row
                                                                                    .max_allowed,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    scope.row,
                                                                                    "max_allowed",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "scope.row.max_allowed"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        !section.allow_multiple
                                                          ? _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Default",
                                                                  "min-width":
                                                                    "68"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        scope
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "el-checkbox",
                                                                            {
                                                                              attrs: {
                                                                                disabled: !section.min_qty
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.resetOtherModifierOption(
                                                                                    section.options,
                                                                                    scope.$index
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  scope
                                                                                    .row
                                                                                    .is_default,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    scope.row,
                                                                                    "is_default",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "scope.row.is_default"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "Order.Actions"
                                                            ),
                                                            width: "100px"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm.user.roles.find(
                                                                      function(
                                                                        role
                                                                      ) {
                                                                        return [
                                                                          "Operation Manager",
                                                                          "Admin"
                                                                        ].includes(
                                                                          role.name
                                                                        )
                                                                      }
                                                                    )
                                                                      ? _c(
                                                                          "el-button",
                                                                          {
                                                                            attrs: {
                                                                              type:
                                                                                "danger"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeModifierOption(
                                                                                  section,
                                                                                  scope.$index
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "Order.Delete"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-link",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addModifierOption(
                                                          section
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("+ Add Option")]
                                                ),
                                                _vm._v(" "),
                                                section.allow_multiple == 0 &&
                                                _vm.user.roles.find(function(
                                                  role
                                                ) {
                                                  return ["Admin"].includes(
                                                    role.name
                                                  )
                                                }) &&
                                                section.lock != true
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "20px"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.savePreset(
                                                              section
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Save as Preset")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item.type === "combo"
                        ? _c(
                            "el-tab-pane",
                            {
                              attrs: { label: "Combo Option", name: "combos" }
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  attrs: {
                                    type: "flex",
                                    justify: "space-around"
                                  }
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-card",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix header",
                                              attrs: { slot: "header" },
                                              slot: "header"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(_vm.item.name))
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-tabs",
                                            {
                                              attrs: {
                                                "tab-position": "left",
                                                closable: "",
                                                addable: ""
                                              },
                                              on: {
                                                "tab-remove": _vm.removeCombo,
                                                "tab-add": _vm.addCombo
                                              }
                                            },
                                            _vm._l(_vm.item.combos, function(
                                              section,
                                              index
                                            ) {
                                              return _c(
                                                "el-tab-pane",
                                                {
                                                  key: index,
                                                  staticStyle: {
                                                    "padding-top": "0",
                                                    "padding-left": "30px",
                                                    "padding-right": "0"
                                                  },
                                                  attrs: {
                                                    name: index.toString()
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "el-tabs_label",
                                                      attrs: { slot: "label" },
                                                      slot: "label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            section.name &&
                                                              section.name
                                                                .length
                                                              ? section.name
                                                              : "Combo Option Name"
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "el-form-item__label",
                                                      staticStyle: {
                                                        "font-weight": "normal",
                                                        "margin-top": "0"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Combo Option Details"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "combos." +
                                                          index +
                                                          ".name",
                                                        "label-width": "0",
                                                        rules: _vm.rules.name
                                                      }
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            "Combo Option Name",
                                                          maxlength: 85
                                                        },
                                                        model: {
                                                          value: section.name,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              section,
                                                              "name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "section.name"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass: "opt_head",
                                                      staticStyle: {
                                                        "margin-bottom": "20px"
                                                      },
                                                      attrs: {
                                                        label: "Selection",
                                                        "label-width": "100px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-row",
                                                        {
                                                          attrs: { gutter: 20 }
                                                        },
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-input-number",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "MIN",
                                                                    precision: 0,
                                                                    step: 1,
                                                                    min: 0
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      section.min_qty,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        section,
                                                                        "min_qty",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "section.min_qty"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: { span: 8 }
                                                            },
                                                            [
                                                              _c(
                                                                "el-input-number",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "MAX",
                                                                    precision: 0,
                                                                    step: 1,
                                                                    min: 0
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.changedMaxModifier(
                                                                        section
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      section.max_qty,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        section,
                                                                        "max_qty",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "section.max_qty"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "combo-item-list"
                                                    },
                                                    [
                                                      _c("el-row", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "el-form-item__label combo-included-item",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "normal"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Included Items\n                          "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        section.products,
                                                        function(
                                                          product,
                                                          itemkey
                                                        ) {
                                                          return _c(
                                                            "el-row",
                                                            { key: itemkey },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "combo-item-header"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        product.category
                                                                          ? "Category"
                                                                          : "Single"
                                                                      ) +
                                                                      ": " +
                                                                      _vm._s(
                                                                        product.category
                                                                          ? product
                                                                              .category
                                                                              .name
                                                                          : product
                                                                              .product
                                                                              .name
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                  _c(
                                                                    "el-link",
                                                                    {
                                                                      staticStyle: {
                                                                        float:
                                                                          "right"
                                                                      },
                                                                      attrs: {
                                                                        type:
                                                                          "danger"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeComboItem(
                                                                            section.products,
                                                                            itemkey
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "remove"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              product.product &&
                                                              product.product
                                                                .price
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Price"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            $" +
                                                                          _vm._s(
                                                                            product
                                                                              .product
                                                                              .price
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              product.product
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Add On Price"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input-number",
                                                                        {
                                                                          attrs: {
                                                                            placeholder:
                                                                              "0",
                                                                            precision: 2,
                                                                            step: 0.01,
                                                                            min: 0
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              product.add_on_price,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                product,
                                                                                "add_on_price",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "product.add_on_price"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              product.product
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Max Allowed"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input-number",
                                                                        {
                                                                          attrs: {
                                                                            placeholder:
                                                                              "0",
                                                                            precision: 0,
                                                                            step: 1,
                                                                            min: 1
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              product.max_allowed,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                product,
                                                                                "max_allowed",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "product.max_allowed"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              product.category
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Discount"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input-number",
                                                                        {
                                                                          attrs: {
                                                                            placeholder:
                                                                              "0",
                                                                            precision: 2,
                                                                            step: 0.01,
                                                                            min: 0
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              product.discount,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                product,
                                                                                "discount",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "product.discount"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              product.category
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Default Max Allowed"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input-number",
                                                                        {
                                                                          attrs: {
                                                                            placeholder:
                                                                              "0",
                                                                            precision: 0,
                                                                            step: 1,
                                                                            min: 1
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              product.max_allowed,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                product,
                                                                                "max_allowed",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "product.max_allowed"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticClass: "opt_head",
                                                      attrs: { gutter: 10 }
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 12 } },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                value: null,
                                                                placeholder:
                                                                  "+ Import a Category"
                                                              },
                                                              on: {
                                                                change: function(
                                                                  category
                                                                ) {
                                                                  return _vm.addCategory(
                                                                    section,
                                                                    category
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.categories,
                                                              function(pt) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key:
                                                                      pt.uuid,
                                                                    attrs: {
                                                                      label:
                                                                        pt.name,
                                                                      value: pt
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 12 } },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                value: null,
                                                                placeholder:
                                                                  "+ Import a Single Item"
                                                              },
                                                              on: {
                                                                change: function(
                                                                  product
                                                                ) {
                                                                  return _vm.addProduct(
                                                                    section,
                                                                    product
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.items,
                                                              function(pt) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key:
                                                                      pt.uuid,
                                                                    attrs: {
                                                                      label:
                                                                        pt.name,
                                                                      value: pt
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "30px" },
                      attrs: { type: "flex", justify: "space-around" }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.onSubmit }
                            },
                            [_vm._v(_vm._s(_vm.buttonText))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.fileList[0]
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogVisible },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("img", {
                attrs: { width: "100%", src: _vm.fileList[0].url, alt: "" }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }