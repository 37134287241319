"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _validate = require("@/utils/validate");

var _user = require("@/api/user");

var _elementUi = require("element-ui");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _errorParse = require("@/utils/error-parse");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!(0, _validate.notEmptyString)(value)) {
        callback(new Error('Please enter email'));
      } else {
        callback();
      }
    };

    return {
      forgetForm: {
        email: ''
      },
      forgetRules: {
        email: [{
          required: true,
          trigger: 'blur',
          validator: validateEmail
        }]
      },
      loading: false,
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;

      this.$refs.forgetForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          (0, _user.forgetPassword)(_this.forgetForm).then(function (response) {
            if (response.status === 'Y') {
              (0, _elementUi.Message)({
                message: 'Reset password link sent on your email id',
                type: 'success',
                duration: 5 * 1000
              });

              _this.$router.push({
                path: '/login'
              });
            } else {
              (0, _elementUi.Message)({
                message: _i18n.default.t(response.msg),
                type: 'error',
                duration: 5 * 1000
              });
            }
          }).catch(function (err) {
            if (err.headers['retry-after']) {
              (0, _elementUi.Message)({
                message: 'Too Many Attempt, Please Retry After ' + err.headers['retry-after'] + 's',
                type: 'error',
                duration: 5 * 1000
              });
            } else {
              (0, _elementUi.Message)({
                message: (0, _errorParse.parseError)(err) || _i18n.default.t(err.message),
                type: 'error',
                duration: 5 * 1000
              });
            }
          }).finally(function () {
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;