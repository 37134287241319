"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// import defaultSettings from '@/settings'
// const { showSettings, fixedHeader, sidebarLogo } = defaultSettings
var state = {
  lessonToEdit: null
};
var mutations = {};
var actions = {};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;