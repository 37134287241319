var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _vm.roles.find(function(role) {
        return ["Admin", "Operation Manager", "Floor Manager"].includes(
          role.name
        )
      })
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, span: 12 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card mb-1" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [_c("span", [_vm._v("Staff")])]
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": " - ",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd",
                          clearable: false
                        },
                        model: {
                          value: _vm.staffWeek,
                          callback: function($$v) {
                            _vm.staffWeek = $$v
                          },
                          expression: "staffWeek"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        { attrs: { data: _vm.storeStaffDutyData } },
                        [
                          _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return _vm._l(props.row.duties, function(
                                      duty
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: duty,
                                          staticStyle: { margin: "10px" }
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: duty.status,
                                                placement: "bottom"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type:
                                                      duty.status === "pending"
                                                        ? "info"
                                                        : duty.status ===
                                                          "missed"
                                                        ? "danger"
                                                        : duty.status ===
                                                          "active"
                                                        ? ""
                                                        : duty.status ===
                                                          "completed"
                                                        ? "success"
                                                        : ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(duty.userName) +
                                                      " | " +
                                                      _vm._s(duty.fromTime) +
                                                      " - " +
                                                      _vm._s(duty.toTime) +
                                                      " | " +
                                                      _vm._s(
                                                        duty.scheduledDuration.toFixed(
                                                          2
                                                        )
                                                      ) +
                                                      "hr | $" +
                                                      _vm._s(
                                                        duty.scheduledCost.toFixed(
                                                          2
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              1436653822
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: _vm.$t("Order.Name"), prop: "name" }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "Staff" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "Actual",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(props.row.activeCount)
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" /\n              "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "Schedule",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(props.row.scheduledCount)
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1311483055
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "Cost" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "Actual",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "$" +
                                                _vm._s(
                                                  props.row.activeCost.toFixed(
                                                    2
                                                  )
                                                )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" /\n              "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "Schedule",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "$" +
                                                _vm._s(
                                                  props.row.scheduledCost.toFixed(
                                                    2
                                                  )
                                                )
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2782536431
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12, xs: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card mb-1" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [_c("span", [_vm._v("Cash Expense")])]
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { "margin-top": "10px" },
                        attrs: {
                          type: "daterange",
                          "range-separator": "to",
                          "start-placeholder": "Start",
                          "end-placeholder": "End",
                          "value-format": "yyyy-MM-dd"
                        },
                        on: { change: _vm.getCashExpenseReport },
                        model: {
                          value: _vm.cashExpenseParam.date_range,
                          callback: function($$v) {
                            _vm.$set(_vm.cashExpenseParam, "date_range", $$v)
                          },
                          expression: "cashExpenseParam.date_range"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { placeholder: "Category" },
                          on: { change: _vm.getCashExpenseReport },
                          model: {
                            value: _vm.cashExpenseParam.category,
                            callback: function($$v) {
                              _vm.$set(_vm.cashExpenseParam, "category", $$v)
                            },
                            expression: "cashExpenseParam.category"
                          }
                        },
                        _vm._l(_vm.cashExpenseCategories, function(
                          cashExpenseCategory
                        ) {
                          return _c("el-option", {
                            key: cashExpenseCategory.name,
                            attrs: {
                              label: cashExpenseCategory.name,
                              value: cashExpenseCategory.id
                            }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.cashExpenseLoading,
                              expression: "cashExpenseLoading"
                            }
                          ],
                          attrs: {
                            data: _vm.cashExpenses,
                            "element-loading-text": "Loading"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "#EEE",
                                            padding: "5px"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [_c("b", [_vm._v("Detail")])]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: props.row.cashExpenses
                                              }
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "Category",
                                                  prop: "category",
                                                  width: "120"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cash-expense-category",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.filterCategory(
                                                                    scope.row
                                                                      .category
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.translateCategory(
                                                                    scope.row
                                                                      .category
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "Description",
                                                  prop: "description"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "Date",
                                                  prop: "apply_date",
                                                  formatter: _vm.dateFormat,
                                                  width: "100"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "Amount",
                                                  prop: "price",
                                                  align: "right",
                                                  width: "120"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                Math.round(
                                                                  scope.row
                                                                    .price * 10
                                                                ) / 10
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "Reason",
                                                  prop: "remark"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3192067779
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: _vm.$t("Order.Name"), prop: "name" }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "Amount",
                              prop: "price",
                              align: "right"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            Math.round(scope.row.price * 10) /
                                              10
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              978426280
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "Count",
                              prop: "counter",
                              align: "right"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.roles.find(function(role) {
        return ["Admin", "Warehouse Manager", "Procurement Manager"].includes(
          role.name
        )
      })
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, xs: 24 } },
                [
                  _vm.suppliersPO
                    ? _c(
                        "el-card",
                        { staticClass: "box-card mb-1 with-pdf" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [_c("span", [_vm._v("Top 10 Suppliers")])]
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              type: "daterange",
                              "range-separator": " - ",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd"
                            },
                            model: {
                              value: _vm.supplierPO,
                              callback: function($$v) {
                                _vm.supplierPO = $$v
                              },
                              expression: "supplierPO"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { title: "Download PDF Summary" },
                              on: { click: _vm.supplierSummary }
                            },
                            [
                              _c("svg-icon", { attrs: { "icon-class": "pdf" } })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.suppliersPO,
                                "default-sort": {
                                  prop: "amount",
                                  order: "descending"
                                }
                              },
                              on: {
                                "sort-change": _vm.sortSupplierPOChanged,
                                "expand-change": _vm.handleRowExpand
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "expand" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(props) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "background-color": "#EEE",
                                                padding: "5px"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c("b", [
                                                        _vm._v("TOP 5 SKU")
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                props.row.summary,
                                                function(summary) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: summary.sku,
                                                      staticStyle: {
                                                        margin: "10px 0px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 10
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  summary.sku
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: { span: 8 }
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    parseFloat(
                                                                      summary.qty
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                      " " +
                                                                      summary.unit
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: { span: 6 }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "text-align":
                                                                      "right"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "$" +
                                                                      _vm._s(
                                                                        parseFloat(
                                                                          summary.amount
                                                                        ).toFixed(
                                                                          2
                                                                        )
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2124559208
                                )
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("Order.Name"),
                                  prop: "name"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Number of Unique Item",
                                  prop: "items",
                                  sortable: ""
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Amount",
                                  sortable: "",
                                  prop: "amount",
                                  align: "right"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            "\n              $" +
                                              _vm._s(
                                                parseFloat(
                                                  scope.row.amount
                                                ).toFixed(2)
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3821393985
                                )
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            attrs: {
                              "page-sizes": [10],
                              limit: 10,
                              total: _vm.totalSupplierPO,
                              layout: " ->, total, pager",
                              page: _vm.poQuery.page
                            },
                            on: {
                              "update:page": function($event) {
                                return _vm.$set(_vm.poQuery, "page", $event)
                              },
                              pagination: _vm.fetchSuppliersPO
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12, xs: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card mb-1 with-pdf" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [_c("span", [_vm._v("Store Purchase Report")])]
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: {
                          clearable: false,
                          "picker-options": _vm.pickerOptions,
                          type: "daterange",
                          "range-separator": " - ",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.supplierWeek,
                          callback: function($$v) {
                            _vm.supplierWeek = $$v
                          },
                          expression: "supplierWeek"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { title: "Download PDF Summary" },
                          on: { click: _vm.supplierStoreSummary }
                        },
                        [_c("svg-icon", { attrs: { "icon-class": "pdf" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tabs",
                        {
                          model: {
                            value: _vm.suppliersStoreMode,
                            callback: function($$v) {
                              _vm.suppliersStoreMode = $$v
                            },
                            expression: "suppliersStoreMode"
                          }
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "Real", name: "real" } },
                            [
                              _vm.suppliersStore !== null
                                ? _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.supplierLoading,
                                          expression: "supplierLoading"
                                        }
                                      ],
                                      attrs: {
                                        id: "supplierPO",
                                        data: _vm.suppliersStore,
                                        "default-sort": {
                                          prop: "amount",
                                          order: "descending"
                                        }
                                      },
                                      on: {
                                        "expand-change":
                                          _vm.handleStoreRowExpand
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: { type: "expand", width: "15" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(props) {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            padding: "0 25px"
                                                          }
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v("Suppliers")
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-table",
                                                        {
                                                          attrs: {
                                                            data:
                                                              props.row
                                                                .suppliers
                                                          },
                                                          on: {
                                                            "expand-change": function(
                                                              row,
                                                              expandedRows
                                                            ) {
                                                              return _vm.handleSupplierRowExpand(
                                                                row,
                                                                expandedRows,
                                                                props.row.uuid
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                type: "expand",
                                                                width: "15"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      props2
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle: {
                                                                                  padding:
                                                                                    "0 7px"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "TOP 5 SKU"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-table",
                                                                              {
                                                                                attrs: {
                                                                                  data:
                                                                                    props2
                                                                                      .row
                                                                                      .summary
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-table-column",
                                                                                  {
                                                                                    attrs: {
                                                                                      label:
                                                                                        "SKU",
                                                                                      prop:
                                                                                        "sku"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-table-column",
                                                                                  {
                                                                                    attrs: {
                                                                                      label:
                                                                                        "Amount",
                                                                                      prop:
                                                                                        "amount",
                                                                                      align:
                                                                                        "right",
                                                                                      width:
                                                                                        "100"
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "default",
                                                                                          fn: function(
                                                                                            scope3
                                                                                          ) {
                                                                                            return [
                                                                                              _vm._v(
                                                                                                "\n                                  $" +
                                                                                                  _vm._s(
                                                                                                    parseFloat(
                                                                                                      scope3
                                                                                                        .row
                                                                                                        .amount
                                                                                                    )
                                                                                                      .toFixed(
                                                                                                        2
                                                                                                      )
                                                                                                      .replace(
                                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                                        ","
                                                                                                      )
                                                                                                  ) +
                                                                                                  "\n                                "
                                                                                              )
                                                                                            ]
                                                                                          }
                                                                                        }
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    )
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  "Order.Name"
                                                                ),
                                                                prop: "name"
                                                              }
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label: "Amount",
                                                                prop: "amount",
                                                                align: "right",
                                                                width: "100"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      scope2
                                                                    ) {
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          $" +
                                                                            _vm._s(
                                                                              parseFloat(
                                                                                scope2
                                                                                  .row
                                                                                  .amount
                                                                              )
                                                                                .toFixed(
                                                                                  2
                                                                                )
                                                                                .replace(
                                                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                                                  ","
                                                                                )
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2341099636
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t("Order.Name"),
                                          prop: "name"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "%COGS",
                                          align: "right",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.sales
                                                          ? (
                                                              (parseFloat(
                                                                scope.row.amount
                                                              ) /
                                                                parseFloat(
                                                                  scope.row
                                                                    .sales
                                                                )) *
                                                              100
                                                            )
                                                              .toFixed(2)
                                                              .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                              )
                                                          : 0
                                                      ) +
                                                      "%\n                "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          4223857693
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Sales",
                                          prop: "sales",
                                          align: "right",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                  $" +
                                                      _vm._s(
                                                        parseFloat(
                                                          scope.row.sales
                                                        )
                                                          .toFixed(0)
                                                          .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                          )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2203069054
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Amount",
                                          prop: "amount",
                                          align: "right",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                  $" +
                                                      _vm._s(
                                                        parseFloat(
                                                          scope.row.amount
                                                        )
                                                          .toFixed(2)
                                                          .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                          )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2285786488
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "Estimate", name: "estimate" } },
                            [
                              _vm.suppliersStoreEstimate !== null
                                ? _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.supplierLoading,
                                          expression: "supplierLoading"
                                        }
                                      ],
                                      attrs: {
                                        id: "supplierPO",
                                        data: _vm.suppliersStoreEstimate,
                                        "default-sort": {
                                          prop: "amount",
                                          order: "descending"
                                        }
                                      },
                                      on: {
                                        "expand-change":
                                          _vm.handleStoreRowExpandPO
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: { type: "expand", width: "15" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(props) {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            padding: "0 25px"
                                                          }
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v("Suppliers")
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-table",
                                                        {
                                                          attrs: {
                                                            data:
                                                              props.row
                                                                .suppliers
                                                          },
                                                          on: {
                                                            "expand-change": function(
                                                              row,
                                                              expandedRows
                                                            ) {
                                                              return _vm.handleSupplierRowExpandPO(
                                                                row,
                                                                expandedRows,
                                                                props.row.uuid
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                type: "expand",
                                                                width: "15"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      props2
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle: {
                                                                                  padding:
                                                                                    "0 7px"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "TOP 5 SKU"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-table",
                                                                              {
                                                                                attrs: {
                                                                                  data:
                                                                                    props2
                                                                                      .row
                                                                                      .summary
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-table-column",
                                                                                  {
                                                                                    attrs: {
                                                                                      label:
                                                                                        "SKU",
                                                                                      prop:
                                                                                        "sku"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-table-column",
                                                                                  {
                                                                                    attrs: {
                                                                                      label:
                                                                                        "Amount",
                                                                                      prop:
                                                                                        "amount",
                                                                                      align:
                                                                                        "right",
                                                                                      width:
                                                                                        "100"
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "default",
                                                                                          fn: function(
                                                                                            scope3
                                                                                          ) {
                                                                                            return [
                                                                                              _vm._v(
                                                                                                "\n                                  $" +
                                                                                                  _vm._s(
                                                                                                    parseFloat(
                                                                                                      scope3
                                                                                                        .row
                                                                                                        .amount
                                                                                                    )
                                                                                                      .toFixed(
                                                                                                        2
                                                                                                      )
                                                                                                      .replace(
                                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                                        ","
                                                                                                      )
                                                                                                  ) +
                                                                                                  "\n                                "
                                                                                              )
                                                                                            ]
                                                                                          }
                                                                                        }
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    )
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  "Order.Name"
                                                                ),
                                                                prop: "name"
                                                              }
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label: "Amount",
                                                                prop: "amount",
                                                                align: "right",
                                                                width: "100"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      scope2
                                                                    ) {
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          $" +
                                                                            _vm._s(
                                                                              parseFloat(
                                                                                scope2
                                                                                  .row
                                                                                  .amount
                                                                              )
                                                                                .toFixed(
                                                                                  2
                                                                                )
                                                                                .replace(
                                                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                                                  ","
                                                                                )
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          747149515
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t("Order.Name"),
                                          prop: "name"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "%COGS",
                                          align: "right",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.sales
                                                          ? (
                                                              (parseFloat(
                                                                scope.row.amount
                                                              ) /
                                                                parseFloat(
                                                                  scope.row
                                                                    .sales
                                                                )) *
                                                              100
                                                            )
                                                              .toFixed(2)
                                                              .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                              )
                                                          : 0
                                                      ) +
                                                      "%\n                "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          4223857693
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Sales",
                                          prop: "sales",
                                          align: "right",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                  $" +
                                                      _vm._s(
                                                        parseFloat(
                                                          scope.row.sales
                                                        )
                                                          .toFixed(0)
                                                          .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                          )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2203069054
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Amount",
                                          prop: "amount",
                                          align: "right",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                  $" +
                                                      _vm._s(
                                                        parseFloat(
                                                          scope.row.amount
                                                        )
                                                          .toFixed(2)
                                                          .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                          )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2285786488
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.roles.find(function(role) {
        return ["Admin", "Warehouse Manager", "Procurement Manager"].includes(
          role.name
        )
      })
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm.warehouses
                    ? _c(
                        "el-card",
                        { staticClass: "box-card mb-1" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [_c("span", [_vm._v("Warehouses Summary")])]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.warehouses,
                                "default-sort": {
                                  prop: "amount",
                                  order: "descending"
                                },
                                "summary-method": _vm.warehouseSummary,
                                "show-summary": ""
                              },
                              on: {
                                "sort-change": _vm.sortSupplierStoreChanged
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("Order.Name"),
                                  prop: "name"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Number of Unique Item",
                                  prop: "items",
                                  sortable: ""
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Amount",
                                  sortable: "",
                                  prop: "amount"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Value",
                                  sortable: "",
                                  prop: "price"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("Order.UpdatedAt"),
                                  sortable: "",
                                  prop: "updated_at"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }