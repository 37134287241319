var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: { label: _vm.$t("GoodReceivedNotes.All"), name: "all" }
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("GoodReceivedNotes.Drafted"),
              name: "drafted"
            }
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("GoodReceivedNotes.Processing"),
              name: "confirmed"
            }
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("GoodReceivedNotes.Completed"),
              name: "completed"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("label", [
        _vm._v(_vm._s(_vm.$t("GoodReceivedNotes.Supplier")) + ": ")
      ]),
      _vm._v(" "),
      _c("v-select", {
        attrs: {
          options: _vm.suppliers,
          label: "name",
          reduce: function(supplier) {
            return supplier.uuid
          }
        },
        model: {
          value: _vm.listQuery.suppliers,
          callback: function($$v) {
            _vm.$set(_vm.listQuery, "suppliers", $$v)
          },
          expression: "listQuery.suppliers"
        }
      }),
      _vm._v(" "),
      _c("label", [_vm._v("Good Received Date: ")]),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: { type: "date", placeholder: "Pick Date", clearable: true },
        model: {
          value: _vm.listQuery.goodReceivedDate,
          callback: function($$v) {
            _vm.$set(_vm.listQuery, "goodReceivedDate", $$v)
          },
          expression: "listQuery.goodReceivedDate"
        }
      }),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
        [_vm._v(_vm._s(_vm.$t("GoodReceivedNotes.Filter")))]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _vm.activeName === "drafted" || _vm.activeName === "all"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-edit" },
                          on: { click: _vm.addGoodReceivedNote }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("GoodReceivedNotes.AddGoodReceivedNotes")
                            )
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.fetchData($event)
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.search,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "search", $$v)
                          },
                          expression: "listQuery.search"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default", icon: "el-icon-search" },
                          on: { click: _vm.fetchData }
                        },
                        [_vm._v(_vm._s(_vm.$t("GoodReceivedNotes.Search")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.displayId) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("GoodReceivedNotes.Supplier") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.supplier.name) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("GoodReceivedNotes.Date") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.goodReceivedDate) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("GoodReceivedNotes.Status") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.status) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("GoodReceivedNotes.Type") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.type) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("GoodReceivedNotes.UpdatedAt") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.updatedAt) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("GoodReceivedNotes.Action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticStyle: { margin: "10px 0px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.editGoodReceivedNote(scope.row.uuid)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.status === "drafted" ? "Edit" : "Show"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deleteGoodReceivedNote(
                                  scope.row.uuid
                                )
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === "Confirmed"
                      ? _c(
                          "div",
                          { staticStyle: { margin: "10px 0px" } },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.downloadGoodReceivedNote(
                                      scope.row.uuid,
                                      scope.row.displayId
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("GoodReceivedNotes.DownloadGRN")
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === "Completed"
                      ? _c(
                          "div",
                          { staticStyle: { margin: "10px 0px" } },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.downloadGoodReceivedNoteTransitNote(
                                      scope.row.uuid,
                                      scope.row.displayId
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "GoodReceivedNotes.DownloadTransitNote"
                                    )
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }