"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.leaveApprove = leaveApprove;
exports.leaveReject = leaveReject;
exports.getLeaveUsers = getLeaveUsers;
exports.editLeaveUsers = editLeaveUsers;
exports.getLeaves = getLeaves;
exports.getLeave = getLeave;
exports.createLeave = createLeave;
exports.editLeave = editLeave;
exports.deleteLeave = deleteLeave;
exports.leaveForStaff = leaveForStaff;
exports.setUserLeave = setUserLeave;
exports.getUserLeave = getUserLeave;
exports.createApply = createApply;
exports.editApply = editApply;
exports.getHistory = getHistory;
exports.deleteApplyHistory = deleteApplyHistory;
exports.getUploadUrl = getUploadUrl;
exports.removeLeaveHistoryAttachment = removeLeaveHistoryAttachment;

var _request = _interopRequireDefault(require("@/utils/request"));

function leaveApprove(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-history/approve/' + data.uuid,
    data: data,
    method: 'patch'
  });
}

function leaveReject(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-history/reject/' + data.uuid,
    data: data,
    method: 'patch'
  });
}

function getLeaveUsers(params) {
  return (0, _request.default)({
    url: '/api/v1/leave-users/',
    method: 'get',
    params: params
  });
}

function editLeaveUsers(leaveData, selectedUser) {
  return (0, _request.default)({
    url: '/api/v1/leave-users/' + leaveData.uuid,
    data: {
      'selectedUser': selectedUser
    },
    method: 'post'
  });
}

function getLeaves(params) {
  return (0, _request.default)({
    url: '/api/v1/leave-types',
    method: 'get',
    params: params
  });
}

function getLeave(id) {
  return (0, _request.default)({
    url: '/api/v1/leave-types/' + id,
    method: 'get'
  });
}

function createLeave(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-types',
    data: data,
    method: 'post'
  });
}

function editLeave(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-types/' + data.uuid,
    data: data,
    method: 'patch'
  });
}

function deleteLeave(id) {
  return (0, _request.default)({
    url: '/api/v1/leave-types/' + id,
    method: 'delete'
  });
}

function leaveForStaff(data) {
  return (0, _request.default)({
    url: '/api/v1/leaves/staff/' + data.id,
    data: data,
    method: 'patch'
  });
}

function setUserLeave(data) {
  return (0, _request.default)({
    url: '/api/v1/user-leaves',
    data: data,
    method: 'post'
  });
}

function getUserLeave(uuid) {
  return (0, _request.default)({
    url: '/api/v1/user-leaves/' + uuid,
    method: 'get'
  });
}

function createApply(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-history',
    data: data,
    method: 'post'
  });
}

function editApply(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-history/' + data.uuid,
    data: data,
    method: 'patch'
  });
}

function getHistory(params) {
  return (0, _request.default)({
    url: '/api/v1/leave-history',
    method: 'get',
    params: params
  });
}

function deleteApplyHistory(id) {
  return (0, _request.default)({
    url: '/api/v1/leave-history/' + id,
    method: 'delete'
  });
}

function getUploadUrl(fileName) {
  return (0, _request.default)({
    url: "/api/v1/leave-history/get-upload-url",
    data: {
      fileName: fileName
    },
    method: 'post'
  });
}

function removeLeaveHistoryAttachment(id) {
  return (0, _request.default)({
    url: 'api/v1/leave-history-attachment/delete/' + id,
    method: 'post'
  });
}