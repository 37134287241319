var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.item, "label-width": "auto" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.item.name,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "name", $$v)
                  },
                  expression: "item.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Type", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "please select type of printing" },
                  model: {
                    value: _vm.item.type,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "type", $$v)
                    },
                    expression: "item.type"
                  }
                },
                [
                  _c("el-option", {
                    attrs: { label: "Receipt", value: "receipt" }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "Kitchen", value: "kitchen" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.$route.params.id
            ? _c("el-form-item", { attrs: { label: "Printer Name" } }, [
                _vm._v("\n      " + _vm._s(_vm.printerName) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$route.params.id && _vm.item.products && _vm.item.products.length
            ? _c(
                "el-form-item",
                { attrs: { label: "Item to Print" } },
                _vm._l(_vm.item.products, function(product) {
                  return _c("div", { key: product.uuid }, [
                    _vm._v(_vm._s(product.name))
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              [
                _c(
                  "el-row",
                  {
                    staticClass: "row-bg",
                    attrs: { type: "flex", justify: "space-between" }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onSubmit }
                          },
                          [_vm._v(_vm._s(_vm.buttonText))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "default" },
                            on: { click: _vm.back }
                          },
                          [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Back")))]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }