var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("PurchaseOrders.PurchaseOrdersText"),
        visible: _vm.visible
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "100px" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 5, placeholder: "Please input" },
            model: {
              value: _vm.textarea,
              callback: function($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { type: "success" },
              on: { click: _vm.shareOnWhatsapp }
            },
            [_vm._v(_vm._s(_vm.$t("PurchaseOrders.ShareWhatsApp")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }