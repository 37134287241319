var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.deliveryType,
            rules: _vm.rules,
            "label-width": "auto"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.deliveryType.id,
                  callback: function($$v) {
                    _vm.$set(_vm.deliveryType, "id", $$v)
                  },
                  expression: "deliveryType.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.deliveryType.name,
                  callback: function($$v) {
                    _vm.$set(_vm.deliveryType, "name", $$v)
                  },
                  expression: "deliveryType.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Type", prop: "type" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.deliveryType.type,
                  callback: function($$v) {
                    _vm.$set(_vm.deliveryType, "type", $$v)
                  },
                  expression: "deliveryType.type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Location", prop: "location" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.deliveryType.location,
                  callback: function($$v) {
                    _vm.$set(_vm.deliveryType, "location", $$v)
                  },
                  expression: "deliveryType.location"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "GPS", prop: "gps" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.deliveryType.gps,
                  callback: function($$v) {
                    _vm.$set(_vm.deliveryType, "gps", $$v)
                  },
                  expression: "deliveryType.gps"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v(_vm._s(_vm.$t("Order.Cancel")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }