var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.moomoomember,
            rules: _vm.rules,
            "label-width": "auto"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.moomoomember.id,
                  callback: function($$v) {
                    _vm.$set(_vm.moomoomember, "id", $$v)
                  },
                  expression: "moomoomember.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "name" } },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.moomoomember.name,
                  callback: function($$v) {
                    _vm.$set(_vm.moomoomember, "name", $$v)
                  },
                  expression: "moomoomember.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Add Point", prop: "point" } },
            [
              _c("el-input-number", {
                attrs: { precision: 0, step: 1 },
                model: {
                  value: _vm.moomoomember.point,
                  callback: function($$v) {
                    _vm.$set(_vm.moomoomember, "point", $$v)
                  },
                  expression: "moomoomember.point"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("Back")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "activity",
          staticStyle: { "border-top": "1px solid grey" },
          attrs: {
            model: _vm.activity,
            rules: _vm.rules,
            "label-width": "auto"
          }
        },
        [
          _c("h5", [_vm._v("Redeem")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Activity", prop: "activity" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "please select activity" },
                  model: {
                    value: _vm.activity.activity,
                    callback: function($$v) {
                      _vm.$set(_vm.activity, "activity", $$v)
                    },
                    expression: "activity.activity"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "Drink", value: "Drink" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addActivity } },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("Back")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            data: _vm.moomoomember.activities,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "activity" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.activity) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.created_at) + "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }