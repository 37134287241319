var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.leave, rules: _vm.rules, "label-width": "auto" }
        },
        [
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              attrs: { label: "ID" }
            },
            [
              _c("el-input", {
                attrs: { disabled: "disabled" },
                model: {
                  value: _vm.leave.id,
                  callback: function($$v) {
                    _vm.$set(_vm.leave, "id", $$v)
                  },
                  expression: "leave.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "名稱", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.leave.name,
                  callback: function($$v) {
                    _vm.$set(_vm.leave, "name", $$v)
                  },
                  expression: "leave.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "描述", prop: "description" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.leave.description,
                  callback: function($$v) {
                    _vm.$set(_vm.leave, "description", $$v)
                  },
                  expression: "leave.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "有無薪", prop: "has_pay" } }, [
            _c(
              "div",
              { staticClass: "d-flex font" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "請選擇..." },
                    model: {
                      value: _vm.leave.has_pay,
                      callback: function($$v) {
                        _vm.$set(_vm.leave, "has_pay", $$v)
                      },
                      expression: "leave.has_pay"
                    }
                  },
                  _vm._l(_vm.has_wages, function(wage) {
                    return _c("el-option", {
                      key: wage.name,
                      attrs: { label: wage.name, value: wage.value }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "新員工請假", prop: "employment_condition" } },
            [
              _c(
                "div",
                { staticClass: "d-flex font" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "請選擇..." },
                      model: {
                        value: _vm.leave.employment_condition,
                        callback: function($$v) {
                          _vm.$set(_vm.leave, "employment_condition", $$v)
                        },
                        expression: "leave.employment_condition"
                      }
                    },
                    _vm._l(_vm.conditions, function(condition) {
                      return _c("el-option", {
                        key: condition.name,
                        attrs: { label: condition.name, value: condition.value }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.leave.employment_condition == "custom"
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          staticStyle: { "margin-left": "10px" }
                        },
                        [
                          _c("span", { staticClass: "nowrap" }, [
                            _vm._v("入職")
                          ]),
                          _c("el-input", {
                            staticStyle: { margin: "0px 10px" },
                            model: {
                              value: _vm.leave.employment_months,
                              callback: function($$v) {
                                _vm.$set(_vm.leave, "employment_months", $$v)
                              },
                              expression: "leave.employment_months"
                            }
                          }),
                          _c("span", [_vm._v("月")])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "請假單位", prop: "unit" } }, [
            _c(
              "div",
              { staticClass: "d-flex font" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "請選擇..." },
                    model: {
                      value: _vm.leave.unit,
                      callback: function($$v) {
                        _vm.$set(_vm.leave, "unit", $$v)
                      },
                      expression: "leave.unit"
                    }
                  },
                  _vm._l(_vm.units, function(unit) {
                    return _c("el-option", {
                      key: unit.name,
                      attrs: { label: unit.name, value: unit.value }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "nowrap",
                    staticStyle: { "margin-left": "10px" }
                  },
                  [_vm._v("每人每個周期發放")]
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "100px", margin: "0px 10px" },
                  attrs: { type: "number" },
                  model: {
                    value: _vm.leave.quota,
                    callback: function($$v) {
                      _vm.$set(_vm.leave, "quota", $$v)
                    },
                    expression: "leave.quota"
                  }
                }),
                _vm._v(" "),
                _vm.leave.unit == "full-day" || _vm.leave.unit == "half-day"
                  ? _c("span", [_vm._v("天")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.leave.unit == "hour"
                  ? _c("span", [_vm._v("小時")])
                  : _vm._e(),
                _vm._v(" "),
                _c("span", [_vm._v("(0等如無限制)")])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "請假需提前", prop: "before_days" } },
            [
              _c(
                "div",
                { staticClass: "d-flex font" },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.leave.before_days,
                      callback: function($$v) {
                        _vm.$set(_vm.leave, "before_days", $$v)
                      },
                      expression: "leave.before_days"
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "nowrap",
                      staticStyle: { "margin-left": "10px" }
                    },
                    [_vm._v("天遞交申請")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex gary-text" }, [
                _vm._v("輸入0為不限制，即無須提前申請")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "額度配置規則", prop: "refill_period" } },
            [
              _c(
                "div",
                { staticClass: "d-flex font" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "請選擇..." },
                      model: {
                        value: _vm.leave.refill_period,
                        callback: function($$v) {
                          _vm.$set(_vm.leave, "refill_period", $$v)
                        },
                        expression: "leave.refill_period"
                      }
                    },
                    _vm._l(_vm.refill_periods, function(refill_period) {
                      return _c("el-option", {
                        key: refill_period.name,
                        attrs: {
                          label: refill_period.name,
                          value: refill_period.value
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "有效時間", prop: "expire" } }, [
            _c(
              "div",
              { staticClass: "d-flex font" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "請選擇..." },
                    model: {
                      value: _vm.leave.expire,
                      callback: function($$v) {
                        _vm.$set(_vm.leave, "expire", $$v)
                      },
                      expression: "leave.expire"
                    }
                  },
                  _vm._l(_vm.expires, function(expire) {
                    return _c("el-option", {
                      key: expire.name,
                      attrs: { label: expire.name, value: expire.value }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Default Role", prop: "roles" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.leave.roles,
                    callback: function($$v) {
                      _vm.$set(_vm.leave, "roles", $$v)
                    },
                    expression: "leave.roles"
                  }
                },
                _vm._l(_vm.roles, function(item) {
                  return _c(
                    "el-checkbox",
                    { key: item.id, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name) + "\n        ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v(_vm._s(_vm.$t("Order.Cancel")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }