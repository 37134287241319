"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _user = require("@/api/user");

var _leave = require("@/api/leave");

var _elementUi = require("element-ui");

var _errorParse = require("@/utils/error-parse");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd'
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  filters: {},
  data: function data() {
    return {
      list: null,
      listLoading: true,
      validApprovalDate: null,
      userOptions: [],
      leaveOptions: [],
      leaves: [],
      listQuery: {
        fromTime: null,
        toTime: null,
        user_id: null,
        leave_id: null,
        status: null,
        listAll: true,
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)([// 'name',
  'user'])), {}, {
    dateRange: {
      get: function get() {
        return [this.listQuery.fromTime ? this.listQuery.fromTime : '', this.listQuery.toTime ? this.listQuery.toTime : '']; // return this.supplierQuery.fromTime //weekly
      },
      set: function set(range) {
        if (range != null) {
          this.listQuery.fromTime = range[0];
          this.listQuery.toTime = range[1];
        } else {
          this.listQuery.fromTime = null;
          this.listQuery.toTime = null;
        }
      }
    }
  }),
  created: function created() {
    this.fetchData();
    this.fetchUsers();
    this.fetchLeaves();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _leave.getHistory)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.meta.total; // console.log(response)
      });
      var currentTime = new Date();
      console.log(currentTime); // this.validApprovalDate
    },
    fetchUsers: function fetchUsers() {
      var _this2 = this;

      (0, _user.getUsers)({
        limit: 999,
        active: 'true'
      }).then(function (response) {
        _this2.users = response.data;
        _this2.userOptions = response.data;
      });
    },
    fetchLeaves: function fetchLeaves() {
      var _this3 = this;

      (0, _leave.getLeaves)({
        limit: 999
      }).then(function (response) {
        _this3.leaves = response.data;
        _this3.leaveOptions = response.data;
      });
    },
    userFilter: function userFilter(val) {
      this.value = val;

      if (val) {
        this.userOptions = this.users.filter(function (item) {
          if (!!~item.name.indexOf(val) || !!~item.name.toUpperCase().indexOf(val.toUpperCase())) {
            return true;
          }
        });
      } else {
        this.userOptions = this.users;
      }
    },
    getDatePeriod: function getDatePeriod(valObj) {
      if (valObj.date_from === valObj.date_to && valObj.periodfrom === 'AM' && valObj.periodto === 'PM') {
        return valObj.date_from;
      }

      return valObj.date_from + ' ' + valObj.periodfrom + ' - ' + valObj.date_to + ' ' + valObj.periodto;
    },
    approveLeave: function approveLeave(history) {
      var _this4 = this;

      this.listLoading = true;
      (0, _leave.leaveApprove)(history).then(function (response) {
        (0, _elementUi.Message)({
          message: 'Leave Approve submitted.',
          type: 'success',
          duration: 5 * 1000
        });

        _this4.fetchData();
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        console.log(err);
      }).finally(function () {
        _this4.listLoading = false;
      }); // this.$router.push(`/leave-history/approve/${id}`)
    },
    rejectLeave: function rejectLeave(history) {
      var _this5 = this;

      this.listLoading = true;
      (0, _leave.leaveReject)(history).then(function (response) {
        (0, _elementUi.Message)({
          message: 'Leave Approve submitted.',
          type: 'success',
          duration: 5 * 1000
        });

        _this5.fetchData();
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        console.log(err);
      }).finally(function () {
        _this5.listLoading = false;
      }); // this.$router.push(`/leave-history/reject/${id}`)
    },
    deleteleave: function deleteleave(id) {
      var _this6 = this;

      _elementUi.MessageBox.confirm('Confirm to delete Leave?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _leave.deleteLeave)(id).then(function () {
          (0, _elementUi.Message)({
            message: 'Leave deleted',
            type: 'success',
            duration: 5 * 1000
          });

          _this6.fetchData();
        });
      });
    },
    getAttachmentURL: function getAttachmentURL(attachment, key) {
      return '<a href="' + attachment.url + '" target="_blank"><i class="el-icon-document"></i> Attachment ' + (key + 1) + '</a>';
    },
    query: function query() {
      var param = {};

      for (var key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          var current_datetime = this.listQuery[key];

          if (typeof current_datetime === 'string') {
            current_datetime = new Date(current_datetime);
          }

          var formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate();
          param[key] = formatted_date;
        } else {
          param[key] = this.listQuery[key];
        }
      }

      this.$router.push({
        query: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), param)
      });
      this.fetchData();
    }
  }
};
exports.default = _default;