var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("label", [_vm._v("Area: ")]),
      _vm._v(" "),
      _c("v-select", {
        attrs: {
          options: _vm.warehouseAreas,
          label: "name",
          reduce: function(warehouseArea) {
            return warehouseArea.uuid
          }
        },
        model: {
          value: _vm.listQuery.warehouseAreaId,
          callback: function($$v) {
            _vm.$set(_vm.listQuery, "warehouseAreaId", $$v)
          },
          expression: "listQuery.warehouseAreaId"
        }
      }),
      _vm._v(" "),
      _c("label", [_vm._v("Raw Material: ")]),
      _vm._v(" "),
      _c("v-select", {
        attrs: {
          options: _vm.rawMaterials,
          label: "sku",
          reduce: function(rawMaterial) {
            return rawMaterial.uuid
          }
        },
        model: {
          value: _vm.listQuery.rawMaterialId,
          callback: function($$v) {
            _vm.$set(_vm.listQuery, "rawMaterialId", $$v)
          },
          expression: "listQuery.rawMaterialId"
        }
      }),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
        [_vm._v("Filter")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "router-link",
            {
              staticClass: "el-button el-button--default",
              attrs: { to: "/warehouses" }
            },
            [_vm._v(_vm._s(_vm.$t("WarehouseRecord.Back")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("WarehouseRecord.WarehouseArea") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.warehouseArea.name) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("WarehouseRecord.RawMaterial") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.rawMaterial.sku) +
                        " " +
                        _vm._s(
                          scope.row.rawMaterial.sku !=
                            scope.row.rawMaterial.nameEng
                            ? scope.row.rawMaterial.nameEng
                            : null
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("WarehouseRecord.QTY") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.qty) +
                        " " +
                        _vm._s(scope.row.rawMaterial.unit) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }