"use strict";

var _interopRequireDefault = require("/home/node/app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/node/app/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _warehouseArea = require("@/api/warehouseArea");

var _warehouse = require("@/api/warehouse");

var _elementUi = require("element-ui");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true
      },
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])),
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      this.listQuery.warehouseId = this.$route.params.id;
      (0, _warehouseArea.getWarehouseAreas)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
        _this.total = response.meta.total;
      });
    },
    editWarehouseArea: function editWarehouseArea(id) {
      this.$router.push("/warehouses-areas/edit/".concat(id));
    },
    addWarehouseArea: function addWarehouseArea() {
      this.$router.push("/warehouses-areas/add?warehouseId=".concat(this.$route.params.id));
    },
    goWarehouseAreaRawMaterial: function goWarehouseAreaRawMaterial(id) {
      this.$router.push("/warehouse-areas/".concat(id, "/raw-materials"));
    },
    goHistory: function goHistory(row) {
      this.$router.push("/warehouses/".concat(row.warehouse.uuid, "/records?warehouseAreaId=").concat(row.uuid));
    },
    back: function back() {
      this.$router.push("/warehouses");
    },
    deleteWarehouseArea: function deleteWarehouseArea(id) {
      var _this2 = this;

      _elementUi.MessageBox.confirm(_i18n.default.t('WarehouseArea.ConfirmDeleteMessage'), _i18n.default.t('WarehouseArea.Confirm'), {
        confirmButtonText: _i18n.default.t('WarehouseArea.Delete'),
        cancelButtonText: _i18n.default.t('WarehouseArea.Cancel'),
        type: 'warning'
      }).then(function () {
        (0, _warehouseArea.deleteWarehouseArea)(id).then(function () {
          (0, _elementUi.Message)({
            message: _i18n.default.t('WarehouseArea.Recorddeleted'),
            type: 'success',
            duration: 5 * 1000
          });

          _this2.fetchData();
        });
      });
    },
    downloadQRs: function downloadQRs() {
      var _this3 = this;

      (0, _warehouse.downloadQRs)(this.$route.params.id).then(function (response) {
        var fileURL = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "".concat(_this3.$route.params.id, ".pdf"));
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }
  }
};
exports.default = _default;