var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Filter Name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" }
        },
        [
          _c(
            "div",
            { staticClass: "action-container" },
            [
              _vm._v("\n      " + _vm._s(_vm.dateTitle) + "\n      "),
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.getCurrentWeek()
                        }
                      }
                    },
                    [_vm._v("Today")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button-group",
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-arrow-left", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.getPrevWeek()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { icon: "el-icon-date", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.selectWeek()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { icon: "el-icon-arrow-right", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.getNextWeek()
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-date-picker", {
                ref: "weekPicker",
                staticStyle: {
                  position: "absolute",
                  opacity: "0",
                  "margin-left": "-120px",
                  "z-index": "-1"
                },
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  format: "Week WW",
                  placeholder: "Pick a week",
                  type: "week"
                },
                model: {
                  value: _vm.week,
                  callback: function($$v) {
                    _vm.week = $$v
                  },
                  expression: "week"
                }
              }),
              _vm._v(
                "\n      " + _vm._s(_vm.store && _vm.store.name) + "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action-container" },
            [
              _c(
                "el-button-group",
                [
                  !_vm.rosterConfirmations || !_vm.rosterConfirmations.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.clearAll()
                            }
                          }
                        },
                        [_vm._v("Clear\n          All\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.rosterConfirmations || !_vm.rosterConfirmations.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.showCopyFromDatePicker()
                            }
                          }
                        },
                        [_vm._v("\n          Copy From\n        ")]
                      )
                    : _vm.rosterConfirmations &&
                      _vm.rosterConfirmations.find(function(
                        rosterConfirmation
                      ) {
                        return rosterConfirmation.status === "pending_approval"
                      }) &&
                      _vm.user.roles.find(function(role) {
                        return ["Operation Manager", "Admin"].includes(
                          role.name
                        )
                      })
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.publishAll()
                            }
                          }
                        },
                        [_vm._v("\n          Publish\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPast &&
                  _vm.rosterConfirmations &&
                    _vm.rosterConfirmations.find(function(rosterConfirmation) {
                      return rosterConfirmation.status === "confirmed"
                    }) &&
                  _vm.user.roles.find(function(role) {
                    return ["Operation Manager", "Admin"].includes(role.name)
                  }) &&
                  (!_vm.duties.length ||
                    _vm.duties.findIndex(function(duty) {
                      return !duty.clockOutAttendanceRecord
                    }) !== -1)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.unPublishAll()
                            }
                          }
                        },
                        [_vm._v("\n          Unpublish\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (!_vm.rosterConfirmations ||
                    !_vm.rosterConfirmations.length) &&
                  _vm.user.roles.find(function(role) {
                    return [
                      "Store Manager",
                      "Operation Manager",
                      "Admin"
                    ].includes(role.name)
                  })
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.submitAll()
                            }
                          }
                        },
                        [_vm._v("\n          Submit\n\n        ")]
                      )
                    : !_vm.isPast &&
                      _vm.rosterConfirmations &&
                        _vm.rosterConfirmations.find(function(
                          rosterConfirmation
                        ) {
                          return (
                            rosterConfirmation.status === "pending_approval"
                          )
                        }) &&
                      _vm.user.roles.find(function(role) {
                        return [
                          "Store Manager",
                          "Operation Manager",
                          "Admin"
                        ].includes(role.name)
                      }) &&
                      (!_vm.duties.length ||
                        _vm.duties.findIndex(function(duty) {
                          return !duty.clockOutAttendanceRecord
                        }) !== -1)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.unSubmitAll()
                            }
                          }
                        },
                        [_vm._v("\n          Unsubmit\n        ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("el-date-picker", {
                ref: "duplicatePicker",
                staticStyle: {
                  position: "absolute",
                  opacity: "0",
                  "margin-left": "-240px",
                  "z-index": "-1"
                },
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  format: "Week WW",
                  placeholder: "Pick a week",
                  type: "week",
                  "default-value": _vm.defaultDuplicateDatePicker
                },
                on: { change: _vm.onDuplicatePicked },
                model: {
                  value: _vm.duplicateWeek,
                  callback: function($$v) {
                    _vm.duplicateWeek = $$v
                  },
                  expression: "duplicateWeek"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.leaves.length &&
      _vm.rosterConfirmations &&
      _vm.rosterConfirmations.find(function(rosterConfirmation) {
        return (
          ["pending_approval", "confirmed"].indexOf(
            rosterConfirmation.status
          ) !== -1
        )
      }) &&
      _vm.user.roles.find(function(role) {
        return ["Operation Manager", "Admin"].includes(role.name)
      })
        ? _c(
            "div",
            {
              staticStyle: {
                "font-size": "13px",
                color: "#909399",
                "margin-bottom": "10px"
              }
            },
            [
              _vm._v(
                "\n    Publishing only auto approve 例假, because only 例假 can make sure daily, you can selective approve for other leave type on " +
                  _vm._s(_vm.leaveApplication) +
                  " menu\n  "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("duty-dialog", {
        attrs: {
          "dialog-form-visible": _vm.dialogFormVisible,
          "new-duty": _vm.newDuty,
          users: _vm.users
        },
        on: {
          "update:dialogFormVisible": function($event) {
            _vm.dialogFormVisible = $event
          },
          "update:dialog-form-visible": function($event) {
            _vm.dialogFormVisible = $event
          },
          complete: _vm.onComplete
        }
      }),
      _vm._v(" "),
      _c("leave-dialog", {
        attrs: {
          users: _vm.users,
          "new-leavehistory": _vm.newLeavehistory,
          "dialog-form-visible": _vm.dialogLeaveFormVisible
        },
        on: {
          "update:dialogFormVisible": function($event) {
            _vm.dialogLeaveFormVisible = $event
          },
          "update:dialog-form-visible": function($event) {
            _vm.dialogLeaveFormVisible = $event
          },
          complete: _vm.onLeaveComplete
        }
      }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "rosterTable",
          staticClass: "dutyTable",
          attrs: {
            "cell-class-name": _vm.getCellClassName,
            "cell-style": { padding: "0" },
            data: _vm.filteredUsers,
            "header-cell-style": { textAlign: "center" },
            height: _vm.tableHeight,
            indent: 0,
            "span-method": _vm.arraySpanMethod,
            "summary-method": _vm.getSummaries,
            border: "",
            "default-expand-all": "",
            "row-key": "uuid",
            "show-summary": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              label: _vm.$t("Order.Name"),
              prop: "name",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { class: { leftHeader: true, inline: scope.row.group } },
                      [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(scope.row.name))
                        ]),
                        _vm._v(" "),
                        !scope.row.group
                          ? _c("div", { staticClass: "total" }, [
                              _c("i", { staticClass: "el-icon-s-unfold" }),
                              _vm._v(
                                " $" +
                                  _vm._s(_vm.getTotalCost(scope.row)) +
                                  "/" +
                                  _vm._s(_vm.getTotalManHour(scope.row)) +
                                  " Hr\n          "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.days, function(day) {
            return _c("el-table-column", {
              key: day,
              attrs: {
                label: _vm.getHeader(day),
                "label-class-name": _vm.today == day ? "today-cell" : "",
                prop: "",
                width: "160"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cell-container",
                            staticStyle: {
                              width: "100%",
                              "min-height": "55px",
                              padding: "15px 0 10px",
                              display: "flex",
                              "-webkit-box-align": "center!important",
                              "-ms-flex-align": "center!important",
                              "align-items": "center!important",
                              "flex-direction": "column"
                            },
                            on: {
                              drop: function($event) {
                                return _vm.onDrop($event, day, scope.row.uuid)
                              },
                              dragenter: function($event) {
                                $event.preventDefault()
                              },
                              dragover: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _vm._l(_vm.getDuties(day, scope.row.uuid), function(
                              duty
                            ) {
                              return _c(
                                "div",
                                {
                                  key: duty.id,
                                  class: {
                                    "locked-cell":
                                      !!duty.clockOutAttendanceRecord &&
                                      !_vm.user.roles.find(function(role) {
                                        return [
                                          "Operation Manager",
                                          "Admin"
                                        ].includes(role.name)
                                      })
                                  },
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { draggable: "" },
                                  on: {
                                    dragend: function($event) {
                                      return _vm.endDrag($event, duty)
                                    },
                                    dragstart: function($event) {
                                      return _vm.startDrag($event, duty)
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "date" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getDuration(duty)) +
                                        "\n              "
                                    ),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.getIsPublic(duty),
                                          expression: "getIsPublic(duty)"
                                        }
                                      ],
                                      staticClass: "el-icon-view"
                                    }),
                                    _vm._v(" "),
                                    (!duty.clockInTime && !duty.clockOutTime) ||
                                    !_vm.user.roles.find(function(role) {
                                      return [
                                        "Operation Manager",
                                        "Admin"
                                      ].includes(role.name)
                                    })
                                      ? _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                (_vm.rosterConfirmations &&
                                                  _vm.rosterConfirmations
                                                    .length) ||
                                                !!duty.clockOutAttendanceRecord,
                                              expression:
                                                "(rosterConfirmations && rosterConfirmations.length) || !!duty.clockOutAttendanceRecord"
                                            }
                                          ],
                                          staticClass: "el-icon-lock"
                                        })
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  !duty.clockInTime && !duty.clockOutTime
                                    ? _c(
                                        "el-tag",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0",
                                            top: "1px",
                                            "z-index": "2"
                                          },
                                          attrs: {
                                            icon: "el-icon-more",
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showOptions($event)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-more"
                                          })
                                        ]
                                      )
                                    : _vm.user.roles.find(function(role) {
                                        return [
                                          "Operation Manager",
                                          "Admin"
                                        ].includes(role.name)
                                      })
                                    ? _c(
                                        "a",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0",
                                            top: "1px",
                                            "z-index": "2",
                                            color: "blue"
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showEditDutyDialog(
                                                duty
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-edit"
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !duty.clockInTime && !duty.clockOutTime
                                    ? _c(
                                        "el-button-group",
                                        {
                                          staticClass: "button-group",
                                          staticStyle: { position: "absolute" },
                                          attrs: { id: "buttons" }
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-edit",
                                              size: "mini",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.showEditDutyDialog(
                                                  duty
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteDuty(duty)
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _vm._l(
                              _vm.getLeaveHistory(day, scope.row.uuid),
                              function(leaveHistory) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.checkFullTimeRole(
                                          scope.row.roles
                                        ),
                                        expression:
                                          "checkFullTimeRole(scope.row.roles)"
                                      }
                                    ],
                                    key: leaveHistory.uuid,
                                    staticStyle: {
                                      position: "relative",
                                      width: "100%",
                                      "padding-top": "6px"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "leave" }, [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLeaveHistoryName(
                                              leaveHistory
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLeaveHistoryStatus(
                                              day,
                                              leaveHistory
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-tag",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          right: "0",
                                          top: "1px",
                                          "z-index": "2"
                                        },
                                        attrs: {
                                          size: "mini",
                                          icon: "el-icon-more"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showOptions($event)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "el-icon-more" })]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button-group",
                                      {
                                        staticClass: "button-group",
                                        attrs: { id: "buttons" }
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            icon: "el-icon-edit"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showEditLeaveDialog(
                                                leaveHistory,
                                                scope.row
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-button", {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            icon: "el-icon-delete"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteLeaveApply(
                                                leaveHistory
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            !(
                              _vm.rosterConfirmations &&
                              _vm.rosterConfirmations.length
                            ) ||
                            (_vm.isPast &&
                              _vm.user.roles.find(function(role) {
                                return ["Admin"].includes(role.name)
                              }) &&
                              !_vm.hasDuties(day, scope.row.uuid))
                              ? _c(
                                  "el-button-group",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "absolute",
                                      top: "0",
                                      margin: "0",
                                      left: "calc(50% - 12px)",
                                      padding: "0"
                                    },
                                    attrs: { id: "addButton" }
                                  },
                                  [
                                    _c("el-button", {
                                      staticStyle: {
                                        margin: "0",
                                        padding: "2px 5px",
                                        "border-top": "none",
                                        "border-top-left-radius": "0",
                                        "border-top-right-radius": "0"
                                      },
                                      attrs: {
                                        icon: "el-icon-plus",
                                        size: "mini"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showAddDutyDialog(
                                            day,
                                            scope.row
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.checkFullTimeRole(scope.row.roles) &&
                            !(
                              _vm.rosterConfirmations &&
                              _vm.rosterConfirmations.length
                            )
                              ? _c(
                                  "el-button-group",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "absolute",
                                      bottom: "0",
                                      margin: "0",
                                      left: "calc(50% - 12px)",
                                      padding: "0"
                                    },
                                    attrs: { id: "addButton" }
                                  },
                                  [
                                    _c("el-button", {
                                      staticStyle: {
                                        margin: "0",
                                        padding: "2px 5px",
                                        "border-top": "none",
                                        "border-top-left-radius": "0",
                                        "border-top-right-radius": "0"
                                      },
                                      attrs: {
                                        icon: "el-icon-star-off",
                                        size: "mini"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showAddLeaveDialog(
                                            day,
                                            scope.row
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }