var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _vm.listQuery.storeId
            ? _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: true } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-edit" },
                          on: { click: _vm.add }
                        },
                        [_vm._v("Add Modifier")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("Order.Name"),
              width: "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.user.roles.find(function(role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.remove(scope.row.uuid)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function(role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row.uuid)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Edit")))]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }